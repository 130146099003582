import React, { useState, useContext } from "react";
import ConfirmKyc from "./ConfirmKyc";
import { KycContext } from "../../../context/KycContext";
import { api_srv } from "../../../services";
import { useHistory } from "react-router-dom";

const ConfirmKycContainer = () => {
  const {
    directors,
    jointOwners,
    soleKyc,
    provider,
    individualUploads,
    partnersUploads,
    companyUploads,
    companyKyc,
  } = useContext(KycContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const addDirectors = async () => {
    switch (provider) {
      case "sole":
        try {
          let resp = await (await api_srv).addKycJointOwners(jointOwners);
          console.log("response from joint owner", resp);

          history.push("/dashboard");
        } catch (err) {
          let error = await err;
          console.log("error from joint owner", error);
        }

        break;
      case "partnership":
        try {
          let resp = await (await api_srv).addKycJointOwners(directors);
          console.log("response from partner owner", resp);
          history.push("/dashboard");
        } catch (err) {
          let error = await err;
          console.log("error from partner owner", error);
        }
        break;
      case "company":
        try {
          let resp = await (await api_srv).addKycJointOwners(directors);
          console.log("response from director owner", resp);
          history.push("/dashboard");
        } catch (err) {
          let error = await err;
          console.log("error from director owner", error);
        }
        break;

      default:
        break;
    }
  };

  const uploadDocs = async () => {
    switch (provider) {
      case "sole":
        Object.entries(individualUploads).map(async ([key, val]) => {
          console.log("the files to be uploaded", key, val);
          try {
            let resp = await (await api_srv).upload_api(key, val);
            console.log("response from individual uploads", resp);
            addDirectors();
          } catch (err) {
            let error = await err;
            console.log("error uploading individual files", error);
          }
        });

        break;
      case "partnership":
        Object.entries(partnersUploads).map(async ([key, val]) => {
          console.log("the files to be uploaded", key, val);
          try {
            let resp = await (await api_srv).upload_api(key, val);
            console.log("response from individual uploads", resp);
            addDirectors();
          } catch (err) {
            let error = await err;
            console.log("error uploading individual files", error);
          }
        });
        break;
      case "company":
        Object.entries(companyUploads).map(async ([key, val]) => {
          console.log("the files to be uploaded", key, val);
          try {
            let resp = await (await api_srv).upload_api(key, val);
            console.log("response from individual uploads", resp);
            addDirectors();
          } catch (err) {
            let error = await err;
            console.log("error uploading individual files", error);
          }
        });
        break;

      default:
        break;
    }
  };

  const addKycInfo = async () => {
    switch (provider) {
      case "sole":
        try {
          let resp = await (await api_srv).addIndividualKycInfo(soleKyc);
          console.log("response from individual kyc", resp);
          // uploadDocs()
          addDirectors();
        } catch (err) {
          let error = await err;
          console.log("error in individual kyc", error);
        }

        break;
      case "partnership":
        try {
          let resp = await (await api_srv).addCompanyKycInfo(companyKyc);
          console.log("response from partner kyc", resp);
          // uploadDocs()
          addDirectors();
        } catch (err) {
          let error = await err;
          console.log("error in partner kyc", error);
        }
        break;
      case "company":
        try {
          let resp = await (await api_srv).addCompanyKycInfo(companyKyc);
          console.log("response from company kyc", resp);
          // uploadDocs()
          addDirectors();
        } catch (err) {
          let error = await err;
          console.log("error in comapny kyc", error);
        }
        break;

      default:
        break;
    }
  };

  const submit_kyc = async () => {
    setLoading(true);
    try {
      await addKycInfo();
      setLoading(false);
    } catch (err) {
      let error = await err;
      console.log("failed to submit kyc", error);
      setLoading(false);
    }
  };
  return <ConfirmKyc loading={loading} submit_kyc={submit_kyc} />;
};

export default ConfirmKycContainer;

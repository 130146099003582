export default {
	// Root

	home: '/',
	dashboard: '/dashboard',
	login: '/login',
	signup: '/register',
	recover: '/reset',
	verify: '/reset-password',
	newPassword: '/reset2',
	verifyToken: '/verify',
	credentials: '/credentials',

	// Nested Dashboard Routes

	account: '/dashboard/account',
	kyc: '/kyc',
	profile: '/dashboard/profile',
	workorders: '/dashboard/work-orders',
	viewworkorder: '/dashboard/work-orders/view/:id',
	inProgressRequests: '/dashboard/requests/in-progress',
	pendingRequests: '/dashboard/requests/pending',
	invoice: '/dashboard/invoice',
	taxes: '/dashboard/taxes',
	createInvoice: '/dashboard/create-invoice/view/:id',
	departments: '/dashboard/user-management/departments',
	viewDepartment: '/dashboard/user-management/departments/view/:id',
	roles: '/dashboard/user-management/roles',
	viewRole: '/dashboard/user-management/roles/view/:id',
	users: '/dashboard/user-management/users',
	viewUser: '/dashboard/user-management/users/view/:id',
	userGroups: '/dashboard/user-management/user-groups',
	viewUserGroup: '/dashboard/user-management/user-groups/view/:id',
	securityContext: '/dashboard/user-management/security-context',
	viewSecurityContext: '/dashboard/user-management/security-context/view/:id',
	fees: '/dashboard/fees',
	feeDetails: '/dashboard/fees/details/:id',
	taxDetails: '/dashboard/tax/details/:id',
	serviceCenter: '/dashboard/service-center',
	settlement: '/dashboard/settlement',
	settlementDetails: '/dashboard/settlement/:id',
	settlementConfigDetails: '/dashboard/settlement/config/:id',
	settlementConfig: '/dashboard/settlement/config',
	settlementChannel: '/dashboard/settlement/channel',
	settlementChannelDetails: '/dashboard/settlement/channel/:id',
	chat: '/dashboard/chat',
};

import React from 'react';
import Avatar from 'assets/dist/img/avatar2.jpg';
import { FiChevronLeft, FiVideo, FiPhone, FiPaperclip, FiAtSign } from 'react-icons/fi';

const Messages = () => {
	return (
		<div className="chatapp-right">
			<header>
				<a id="back_user_list" href="javascript:void(0)" className="back-user-list">
					<FiChevronLeft />
				</a>
				<div className="media">
					<div className="media-img-wrap">
						<div className="avatar">
							<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
						</div>
						<span className="badge badge-success badge-indicator" />
					</div>
					<div className="media-body">
						<div className="user-name">Evie Ono</div>
						<div className="user-status">online</div>
					</div>
				</div>
				<div className="chat-options-wrap">
					<a href="javascript:void(0)" className>
						<FiVideo />
					</a>
					<a href="javascript:void(0)" className>
						<FiPhone />
					</a>
				</div>
			</header>
			<div className="chat-body">
				<div className="nicescroll-bar">
					<ul className="list-unstyled">
						<li className="media received">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<div className="media-body">
								<div className="msg-box">
									<div>
										<p>Cool, lets talk about it tomorrow</p>
										<span className="chat-time">10:55 AM</span>
										<div className="arrow-triangle-wrap">
											<div className="arrow-triangle right" />
										</div>
									</div>
								</div>
								<div className="msg-box">
									<div>
										<p>Images for new marketing pages have been sent</p>
										<span className="chat-time">10:56 AM</span>
										<div className="arrow-triangle-wrap">
											<div className="arrow-triangle right" />
										</div>
									</div>
								</div>
							</div>
						</li>
						<li className="media received">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<div className="media-body">
								<div className="msg-box">
									<div>
										<p>
											Hey Ben, just a reminder that you are coming for the meeting today in the
											conference. We are proposing a change in the client briefing.
										</p>
										<span className="chat-time">2:35 PM</span>
										<div className="arrow-triangle-wrap">
											<div className="arrow-triangle right" />
										</div>
									</div>
								</div>
							</div>
						</li>
						<li className="media sent">
							<div className="media-body">
								<div className="msg-box">
									<div>
										<p>Sure, on my way to office. Please give me quick update.</p>
										<span className="chat-time">11:45 AM</span>
										<div className="arrow-triangle-wrap">
											<div className="arrow-triangle left" />
										</div>
									</div>
								</div>
							</div>
						</li>
						<li className="day-sep">today</li>
						<li className="media received">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<div className="media-body">
								<div className="msg-box">
									<div>
										<p>
											Me: Can you please grow?
											<br /> Hair: Nah..!
											<br /> Muscle: Nope..!!
											<br /> Salary: Don't even dream..!!!
											<br /> Stomach: Anything for you dear..
										</p>
										<span className="chat-time">7:24 PM</span>
										<div className="arrow-triangle-wrap">
											<div className="arrow-triangle right" />
										</div>
									</div>
								</div>
							</div>
						</li>
						<li className="media sent">
							<div className="media-body">
								<div className="msg-box">
									<div>
										<p>
											Haha, this joke is hilarious. Is it what your heart feel about the salary?
										</p>
										<span className="chat-time">7:57 PM</span>
										<div className="arrow-triangle-wrap">
											<div className="arrow-triangle left" />
										</div>
									</div>
								</div>
								<div className="msg-box">
									<div>
										<p>
											Anyways, I am working on something that you would like to know. This project
											is based on angular js and you are the keeda in it. I need you help in it.
										</p>
										<span className="chat-time">7:59 PM</span>
										<div className="arrow-triangle-wrap">
											<div className="arrow-triangle left" />
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<footer>
				<div className="input-group">
					<div className="input-group-prepend">
						<div className="btn-file  btn btn-light">
							<div className="btn-icon-wrap">
								<FiPaperclip />
								<input type="file" />
							</div>
						</div>
					</div>
					<input
						type="text"
						id="input_msg_send_chatapp"
						name="send-msg"
						className="input-msg-send form-control"
						placeholder="Type something"
					/>
					<div className="input-group-append">
						<button
							type="button"
							className="btn  btn-light  dropdown-toggle no-caret"
							data-toggle="dropdown"
						>
							<FiAtSign />
						</button>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Messages;

import React from 'react';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Loader from '../../components/loader';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { department_details } from '../../services/apiSrv';
import { Tabs, Descriptions } from 'antd';
import { BsBuilding } from 'react-icons/bs';
import Moment from 'react-moment';

export default function DepartmentDetails() {
	const { TabPane } = Tabs;
	const { id } = useParams();
	const _departmentDetails = async () => {
		let resp = await department_details(id);
		console.log('THE DEPARTMENT DETAILS', resp);
		return resp.department_details;
	};

	const { data, isLoading, isSuccess, isError } = useQuery('department details', _departmentDetails);

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div className="hk-wrapper hk-vertical-nav">
					<div className="hk-pg-wrpper">
						{/* Container */}
						<div className="container-fluid">
							{/* Row */}
							<div className="row">
								<div className="col-xl-12 pa-0">
									<div className="profile-cover-wrap overlay-wrap">
										<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
										<div className="bg-overlay bg-trans-dark-60" />
										<div className="container profile-cover-content py-30"></div>
									</div>
									<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
										<div className="container">
											<div className="card">
												<div className="card-body">
													<div className="d-flex justify-content-between mb-4">
														<div>
															<h5>
																<span className="mr-2">
																	<BsBuilding />
																</span>
																{data.department_name}
															</h5>
														</div>
														<div>
															<button className="btn btn-sm btn-warning mr-3">
																Edit{' '}
															</button>
															<button className="btn btn-sm btn-success">Approve </button>
														</div>
													</div>
													<Tabs defaultActiveKey="1" type="card">
														<TabPane tab="Department Details" key="1">
															<Descriptions title="Department Info" column={2}>
																<Descriptions.Item label="Department Name">
																	{data.department_name}
																</Descriptions.Item>

																<Descriptions.Item label="Department Code">
																	{data.department_code}
																</Descriptions.Item>
																<Descriptions.Item label="Entity Type">
																	{data.entity_type}
																</Descriptions.Item>
																<Descriptions.Item label="Created Date">
																	<Moment format="DD-MMM-YYYY HH:MM:a">
																		{data.created_date}
																	</Moment>
																</Descriptions.Item>
																<Descriptions.Item label="Description">
																	{data.department_description}
																</Descriptions.Item>
															</Descriptions>
														</TabPane>
														<TabPane tab="Users" key="2">
															Users will be listed here
														</TabPane>
													</Tabs>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* /Row */}
						</div>
						{/* /Container */}
						{/* Footer */}

						{/* /Footer */}
					</div>
					{/* /Main Content */}
				</div>
			)}
		</>
	);
}

import React, { useContext, useRef } from 'react';
import { Input, Button, Checkbox, Select } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import * as Icon from 'react-feather';
import { KycContext } from '../../../context/KycContext';
import { ModalContext } from '../../../context/ModalContext';
import LocationModal from '../../modals/Location';
import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import Search from '../../map/Search';

export default function IndividualInfo({ state, onChange }) {
	const { prev, next, mapLocation } = useContext(KycContext);

	// MODAL CONTEXT
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	const { name, email, id_number, kra_pin, contact, license, address, postal_code, description } = state;

	console.log('CHECKING STATE ADDRESS', address);

	return (
		<Formik
			enableReinitialize
			initialValues={state}
			validationSchema={Yup.object().shape({
				name: Yup.string().required('Name is required'),
				email: Yup.string().email('Please enter a valid email').required('Email is required'),
				id_number: Yup.string().required('ID Number is required'),
				kra_pin: Yup.string().required('KRA Number is required'),
				contact: Yup.string().required('Contact is required'),
				license: Yup.string().required('License Number is required'),
				address: Yup.string().required('An address is required'),
				postal_code: Yup.string().required('Select a postal code'),
				// location: Yup.string().required("Location is required"),
				description: Yup.string().required('Please write a brief description of yourself'),
			})}
			onSubmit={() => next()}
		>
			{({ errors, touched }) => (
				<Form>
					<LocationModal show={modalShow} onHide={modalClose} />
					<p>Step 2</p>
					<h4 className="title-background">Tell us more about yourself</h4>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas dolor expedita optio unde
						explicabo nemo? Eius, non.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas
						dolor expedita optio unde explicabo nemo? Eius, non.
					</p>
					<div className="form-row">
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="name">Name</label>
								<input
									className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
									placeholder="Enter your name"
									type="text"
									value={name}
									name="name"
									onChange={onChange}
								/>
								<ErrorMessage name="name" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="name">Email</label>
								<input
									className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
									placeholder="example@email.com"
									type="email"
									value={email}
									name="email"
									onChange={onChange}
								/>
								<ErrorMessage name="email" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="name">ID Number</label>
								<input
									className={
										'form-control' + (errors.id_number && touched.id_number ? ' is-invalid' : '')
									}
									placeholder="1234567"
									type="text"
									name="id_number"
									value={id_number}
									onChange={onChange}
								/>
								<ErrorMessage name="id_number" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="name">KRA Pin</label>
								<input
									className={
										'form-control' + (errors.kra_pin && touched.kra_pin ? ' is-invalid' : '')
									}
									placeholder="A1234567T"
									type="text"
									name="kra_pin"
									value={kra_pin}
									onChange={onChange}
								/>
								<ErrorMessage name="kra_pin" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="name">Phone Number</label>
								<input
									className={
										'form-control' + (errors.contact && touched.contact ? ' is-invalid' : '')
									}
									placeholder="0712345678"
									type="text"
									name="contact"
									value={contact}
									onChange={onChange}
								/>
								<ErrorMessage name="contact" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="name">License Number</label>
								<input
									className={
										'form-control' + (errors.license && touched.license ? ' is-invalid' : '')
									}
									placeholder=""
									type="text"
									name="license"
									value={license}
									onChange={onChange}
								/>
								<ErrorMessage name="license" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="name">Postal Address</label>
								<input
									className={
										'form-control' + (errors.address && touched.address ? ' is-invalid' : '')
									}
									placeholder="1234"
									type="text"
									name="address"
									value={address}
									onChange={onChange}
								/>
								<ErrorMessage name="address" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label htmlFor="postal_code">Postal Code</label>
								<select
									className={
										'form-control' +
										(errors.postal_code && touched.postal_code ? ' is-invalid' : '')
									}
									placeholder="Select Code"
									name="postal_code"
									value={postal_code}
									onChange={onChange}
								>
									<option value="">Select Code</option>
									<option value="00100">00100</option>
									<option value="80100">80100</option>
									<option value="40100">40100</option>
								</select>

								<ErrorMessage name="postal_code" component="div" className="invalid-feedback" />
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label htmlFor="location">Enter location</label>
								<Search />
								{/* <input
                  className={
                    "form-control" +
                    (errors.location && touched.location ? " is-invalid" : "")
                  }
                  placeholder="Nairobi CBD, Kenyatta Avenue"
                  type="text"
                  name="location"
                  value={mapLocation}
                  onChange={onChange}
                /> */}
								<ErrorMessage name="location" component="div" className="invalid-feedback" />
								<small className="float-right text-primary">
									<a
										type="button"
										style={{ textDecoration: 'underline' }}
										href
										onClick={() => setModalShow(true)}
									>
										Select from Map
									</a>
								</small>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label htmlFor="name">Brief Description of Yourself</label>
								<textarea
									className={
										'form-control' +
										(errors.description && touched.description ? ' is-invalid' : '')
									}
									id="exampleFormControlTextarea1"
									rows="3"
									name="description"
									value={description}
									onChange={onChange}
								></textarea>
								<ErrorMessage name="description" component="div" className="invalid-feedback" />
							</div>
						</div>
					</div>

					<button className=" float-right py-1 mb-35 mt-30 btn btn-primary" type="submit">
						Next
						<Icon.ArrowRight size={22} className="pl-1" />
					</button>
					<button className=" float-right py-1 mb-35 mt-30 btn btn-secondary mr-3" onClick={prev}>
						<Icon.ArrowLeft size={22} className="pr-1" />
						Previous
					</button>
				</Form>
			)}
		</Formik>
	);
}

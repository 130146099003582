import React, { useState } from 'react';
import { Select, Button, Form } from 'antd';
import Loader from '../../loader';

const AddUserToGroupForm = ({ _addUserToGroup, isLoading, isSuccess, users, group_id }) => {
	const { Option } = Select;
	const [form] = Form.useForm();

	const [selectedUsers, setSelectedUsers] = useState([]);

	function onFinish(values) {
		console.log('selected,', values.user);
		let data = {};
		Array.isArray(values.user)
			? (data = {
					group_id: group_id,
					users: values.user,
			  })
			: (data = {
					group_id: group_id,
					users: [values.user],
			  });

		_addUserToGroup.mutate(data);
	}
	function onFinishFailed(value) {
		console.log(`selected ${value}`);
		Array.isArray(value) ? setSelectedUsers(value) : setSelectedUsers([...selectedUsers, value]);
	}

	return (
		<Form onFinishFailed={onFinishFailed} onFinish={onFinish} layout="vertical" form={form}>
			{isLoading && <Loader />}
			{isSuccess && (
				<>
					<Form.Item
						label="Select User"
						name="user"
						rules={[{ required: true, message: 'Please select user!' }]}
					>
						<Select
							showSearch
							mode="multiple"
							style={{ width: '100%' }}
							placeholder="Select or search user"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{users.map((user) => (
								<Option value={user.user_id} key={user.user_id}>
									{user.username}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							{_addUserToGroup.isLoading ? (
								<span>
									<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
									Please wait...
								</span>
							) : (
								'Add'
							)}
						</Button>
					</Form.Item>
				</>
			)}
		</Form>
	);
};

export default AddUserToGroupForm;

import React from 'react';
import Config from './Config';
import { listConfigs } from 'services/apiSrv';
import { useQuery } from 'react-query';
import Loader from 'components/loader';

const ConfigContainer = () => {
	const listConfig = async () => {
		let resp = await listConfigs();
		console.log('CONFIGS LIST', resp);
		return resp.settlement_configs;
	};

	const { isLoading, isError, isSuccess, data, refetch } = useQuery('list configurations', listConfig);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Config configs={data} refetch={refetch} />}
		</div>
	);
};

export default ConfigContainer;

import React from "react";
import { Modal } from "react-bootstrap";
import { LoadingOutlined, UserAddOutlined } from "@ant-design/icons";

import Invoice from "../Invoice/viewInvoice";

const ViewInvoice = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Invoice />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewInvoice;

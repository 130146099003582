import React, { useContext } from "react";
import { KycContext } from "../../../context/KycContext";
import * as Icon from "react-feather";
import {Checkbox,Button} from 'antd'
import {Form} from "formik";

export default function JointOwners({state,onChange, addNewOwner, goNext}) {
  const {prev} = useContext(KycContext);



  return (
    <form >
      <p>Step 4</p>
      <h4 className="title-background">Add Joint Owners</h4>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas
        dolor expedita optio unde explicabo nemo? Eius, non.Lorem ipsum dolor
        sit, amet consectetur adipisicing elit. Aut voluptas dolor expedita
        optio unde explicabo nemo? Eius, non.
      </p>
      {/* <Button className="float-right">Add Another Partner </Button> */}
      <div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Name of Joint Owner</label>
              <input
                className="form-control"
                placeholder="Enter your name"
                type="text"
                name="name"
                value={state.name}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Email</label>
              <input
                className="form-control"
                placeholder="example@email.com"
                type="email"
                name="email"
                value={state.email}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">ID Number/Alien ID </label>
              <input
                className="form-control"
                placeholder="1234567"
                type="text"
                name="id_number"
                value={state.id_number}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Phone Number</label>
              <input
                className="form-control"
                placeholder="0702123456"
                type="text"
                name="contact"
                value={state.contact}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">KRA Pin</label>
              <input
                className="form-control"
                placeholder="A1234567T"
                type="text"
                name="kra_pin"
                value={state.kra_pin}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="address">Postal Address</label>
              <input
                className="form-control"
                placeholder="1234"
                type="text"
                name="address"
                value={state.address}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="postal_code">Postal Code</label>
              <select
                  className="form-control" 
                  // (errors.postal_code && touched.postal_code ? " is-invalid" : "")} 
                  placeholder="Select Code"
                  name="postal_code"
                  value={state.postal_code}
                  onChange={onChange}
                >
                  <option value="">Choose...</option>
                  <option value="00100">00100</option>
                  <option value="80100">80100</option>
                </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Residency</label>
              <select
                  className="form-control" 
                  // (errors.postal_code && touched.postal_code ? " is-invalid" : "")} 
                  placeholder="Select Code"
                  name="residency"
                  value={state.residency}
                  onChange={onChange}
                >
                  <option value="">Choose...</option>
                  <option value="resident">Local Resident</option>
                  <option value="foreigner">Foreign</option>
                </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">
                Passport Number{" "}
                <small className="text-danger">
                  {" "}
                  *compulsory for non-resident joint owners
                </small>
              </label>
              <input
                className="form-control"
                placeholder="A1234567T"
                type="text"
                name="passport"
                value={state.passport}
                onChange={onChange}
              />
            </div>
          </div>
          </div>
          </div>
         
          <div className="form-group">
          <Checkbox>I confirm that i have been authorized by the joint owners to register on Nexus and agree to the <a href className="text-primary"><u>terms and conditions</u></a></Checkbox>

      </div>
            {/* <div className="form-group">
              <label htmlFor="name">Description</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div> */}
       
    <p>Have more than one joint owner, <a className="text-primary" href onClick={()=>addNewOwner()}><u>click here</u></a> to add another</p>

      <button
        className=" float-right mb-35 mt-30 py-1 btn btn-primary"
        onClick={goNext}
      >
        Next
        <Icon.ArrowRight size={22} className="pl-1" />
      </button>
      <button
        className=" float-right btn py-1  mb-35 mt-30 btn-secondary mr-3"
        onClick={prev}
      >
        <Icon.ArrowLeft size={22} className="pr-1" />
        Previous
      </button>
    </form>
  );
}

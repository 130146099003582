import React from 'react';
import SecurityContext from './SecurityContext';
import { list_security_context } from '../../services/apiSrv';
import { useQuery } from 'react-query';
import Loader from '../../components/loader';

const SecurityContextContainer = () => {
	const _listContexts = async () => {
		let resp = await list_security_context();
		console.log('context', resp);
		return resp.security_contexts;
	};

	const { data, isLoading, isSuccess, isError, refetch } = useQuery('listUserGroup', _listContexts);

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <SecurityContext contexts={data} refetch={refetch} />}
		</div>
	);
};

export default SecurityContextContainer;

import React, { useContext, useState } from 'react';
import TaxForm from './TaxForm';
// import { openNotificationWithIcon } from 'components/widgets/notificaion';
import { fetchAccounts, add_taxes, list_tax_category } from 'services/apiSrv';
import { ModalContext } from 'context/ModalContext';
import { useQuery, useMutation } from 'react-query';

const TaxFormLogic = ({ refetch }) => {
	const { modalClose } = useContext(ModalContext);

	const [taxType, setTaxType] = useState('flat');
	const [management, setManagement] = useState('');
	const [slab, setSlab] = useState([
		{
			min: '',
			max: '',
			value: '',
		},
	]);

	const handleFormValuesChange = (changedValues) => {
		const fieldName = Object.keys(changedValues)[0];

		if (fieldName === 'tax_type') {
			const value = changedValues[fieldName];
			setTaxType(value);
		} else if (fieldName === 'management') {
			const value = changedValues[fieldName];
			setManagement(value);
		}
	};
	const onInputChange = (e, index) => {
		const { name, value } = e.target;
		const tempSlab = [...slab];
		tempSlab[index][name] = value;
		setSlab(tempSlab);
	};
	// handle click event of the Remove button
	const handleRemoveClick = (index) => {
		const tempSlab = [...slab];
		tempSlab.splice(index, 1);
		setSlab(tempSlab);
	};

	// handle click event of the Add button
	const handleAddClick = () => {
		setSlab([
			...slab,
			{
				min: '',
				max: '',
				value: '',
			},
		]);
	};
	const _addtaxes = useMutation(add_taxes, {
		onSuccess: () => {
			modalClose();
			refetch();
			// openNotificationWithIcon('success', 'Success', 'Tax added successfully');
		},
		onError: async (error) => {
			let err = await error;
			modalClose();
			// openNotificationWithIcon('error', `Error`, `${err.message}`);
		},
	});

	async function listTaxCategory() {
		let resp = await await list_tax_category();
		console.log('THE TAX CATEGORIES', resp.tax_categories);
		return resp.tax_categories;
	}
	async function _listAccounts() {
		let resp = await await fetchAccounts();
		return resp.entity_accounts;
	}
	const { data, isLoading, isSuccess } = useQuery('list accounts', _listAccounts);
	const {
		data: taxData,
		isLoading: loadingTaxes,
		isSuccess: isSuccessTaxes,
	} = useQuery('list tax categories', listTaxCategory);
	return (
		<div>
			{isLoading && loadingTaxes && 'fetching required fields'}
			{isSuccess && isSuccessTaxes && (
				<TaxForm
					_addtaxes={_addtaxes}
					accounts={data}
					handleFormValuesChange={handleFormValuesChange}
					taxType={taxType}
					management={management}
					onInputChange={onInputChange}
					slab={slab}
					handleRemoveClick={handleRemoveClick}
					handleAddClick={handleAddClick}
					taxCategories={taxData}
				/>
			)}
		</div>
	);
};

export default TaxFormLogic;

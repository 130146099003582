import React from 'react';
import { Form, Input, Button, Radio, TimePicker, Row, Col, Select } from 'antd';

const SecurityContextForm = ({ _create }) => {
	const [form] = Form.useForm();
	const onFinish = (values) => {
		console.log('Received values of form: ', values);
		_create.mutate(values);
	};
	const onFinishFailed = (values) => {
		console.log('FAILED Received values of form: ', values);
	};

	const { Option } = Select;
	const { TextArea } = Input;

	const config = {
		rules: [
			{
				type: 'object',
				required: true,
				message: 'Please select time!',
			},
		],
	};

	return (
		<div>
			<Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item
					label="Context Name"
					name="name"
					rules={[
						{
							required: true,
							message: 'Context Name is required',
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Password Policy Change"
					name="password_policy"
					rules={[
						{
							required: true,
							message: 'Please Select an Option',
						},
					]}
				>
					<Radio.Group>
						<Radio value="weekly">Weekly</Radio>
						<Radio value="monthly">Monthly</Radio>
						<Radio value="quarterly">Quarterly</Radio>
						<Radio value="yearly">Yearly</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item name="login_period" label="Login Period">
					<Row>
						<Col span={12}>
							<Form.Item name="login_period_start" label="High" {...config}>
								<TimePicker use12Hours format="h:mm A" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="login_period_end" label="Low" {...config}>
								<TimePicker use12Hours format="h:mm A" />
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item
					label="Security Context Code"
					name="security_context_code"
					rules={[
						{
							required: true,
							message: 'Context Code is required',
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Idle Maximum Timeout(Minutes)"
					name="max_timeout"
					rules={[
						{
							required: true,
							message: 'Idle Maximum Timeout is required',
						},
					]}
				>
					<Select
						showSearch
						placeholder="Select timeout"
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option value="10">10 Minutes</Option>
						<Option value="20">20 Minutes</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Token Time To Live(Hours)"
					name="token_ttl"
					rules={[
						{
							required: true,
							message: 'Token Time To Live is required',
						},
					]}
				>
					<Select
						showSearch
						placeholder="Select time"
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option value="1">1 Hour</Option>
						<Option value="2">2 Hours</Option>
					</Select>
				</Form.Item>

				<Form.Item
					label="Allowed Channels"
					name="allowed_channels"
					rules={[
						{
							required: true,
							message: 'Allowed Channels is required',
						},
					]}
				>
					<Select mode="multiple" placeholder="Select channels">
						<Option value="web">Web</Option>
						<Option value="ios">IOS</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Allowed Browsers"
					name="browser_version"
					rules={[
						{
							required: true,
							message: 'Allowed Browser is required',
						},
					]}
				>
					<Select mode="multiple" placeholder="Select browser">
						<Option value="chrome">Chrome</Option>
						<Option value="mozilla">Mozilla</Option>
						<Option value="safari">Safari</Option>
						<Option value="edge">Edge</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Device Version"
					name="device_version"
					rules={[
						{
							required: true,
							message: 'Device Version is required',
						},
					]}
				>
					<Select mode="multiple" placeholder="Select device version">
						<Option value="1.5">1.5</Option>
						<Option value="1.4">1.4</Option>
						<Option value="1.3">1.3</Option>
						<Option value="1.28">1.28</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Two Factor Authentication"
					name="two_factor_auth"
					rules={[
						{
							required: true,
							message: 'Please Select an Option',
						},
					]}
				>
					<Radio.Group>
						<Radio value={true}>True</Radio>
						<Radio value={false}>False</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label="Holiday Login"
					name="holiday_login"
					rules={[
						{
							required: true,
							message: 'Please Select an Option',
						},
					]}
				>
					<Radio.Group>
						<Radio value={true}>True</Radio>
						<Radio value={false}>False</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{
							required: true,
							message: 'Description is required',
						},
					]}
				>
					<TextArea />
				</Form.Item>
				<Form.Item>
					<Button htmlType="submit">Submit</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default SecurityContextForm;

import React, { useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { DatePicker } from 'antd';
import { DashboardContext } from '../../context/DashboardContext';
import { ModalContext } from '../../context/ModalContext';
import Message from '../../components/modals/SendMessage';
import LineItems from '../Invoice/LineItems';

const CompletionTime = (props) => {
	const { acceptWorkorder, SetAcceptWorkorder, invoiceSettings, setInvoiceSettings } = useContext(DashboardContext);
	const { modalShow, modalClose, setModalShow } = useContext(ModalContext);

	// Locale
	let locale = 'en-US';
	let selectedcCurrency = `${invoiceSettings.currency}`;
	// Methods

	const handleLineItemChange = (elementIndex) => (event) => {
		let lineItems = invoiceSettings.lineItems.map((item, i) => {
			if (elementIndex !== i) return item;
			return { ...item, [event.target.name]: event.target.value };
		});
		setInvoiceSettings({ ...invoiceSettings, lineItems: lineItems });
	};

	const handleAddLineItem = (event, index) => {
		setInvoiceSettings({
			...invoiceSettings,
			// use optimistic uuid for drag drop; in a production app this could be a database id
			lineItems: invoiceSettings.lineItems.concat([
				{ id: index, name: '', description: '', quantity: 0, price: 0.0 },
			]),
		});
	};

	const handleRemoveLineItem = (elementIndex) => (event) => {
		setInvoiceSettings({
			...invoiceSettings,
			lineItems: invoiceSettings.lineItems.filter((item, i) => {
				return elementIndex !== i;
			}),
		});
	};

	const handleReorderLineItems = (newLineItems) => {
		setInvoiceSettings({ ...invoiceSettings, lineItems: newLineItems });
	};

	const handleFocusSelect = (event) => {
		event.target.select();
	};

	const formatCurrency = (amount) => {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: selectedcCurrency,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(amount);
	};
	function onChange(date, dateString) {
		SetAcceptWorkorder({ ...acceptWorkorder, completionDate: dateString });
	}

	const calcItemsTotal = () => {
		return invoiceSettings.lineItems.reduce((prev, cur) => prev + cur.quantity * cur.price, 0);
	};

	useEffect(() => {
		const itemsTotal = calcItemsTotal();

		SetAcceptWorkorder({
			...acceptWorkorder,
			expectedCost: itemsTotal,
		});
	}, [invoiceSettings.lineItems]);

	return (
		<div>
			<Message show={modalShow === 'message' ? true : false} onHide={modalClose} />
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5>Accept Workorder</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							props.accept(acceptWorkorder.completionDate, acceptWorkorder.expectedCost);
						}}
					>
						<div className="form-group">
							<label className="d-block" htmlFor="date">
								Enter expected completion date
							</label>
							<DatePicker required onChange={onChange} />
						</div>
						<label className="d-block" htmlFor="cost">
							Breakdown of expected cost of workorder
						</label>

						<LineItems
							items={invoiceSettings.lineItems}
							currencyFormatter={formatCurrency}
							addHandler={handleAddLineItem}
							changeHandler={handleLineItemChange}
							focusHandler={handleFocusSelect}
							deleteHandler={handleRemoveLineItem}
							reorderHandler={handleReorderLineItems}
						/>

						<p className="mt-20">
							<button type="submit" className=" btn btn-sm btn-success btn-block">
								Send
							</button>
						</p>
					</form>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default CompletionTime;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';

import AddUserToGroupForm from '../forms/addUserToGroup/AddUserToGroupFormContainer';

const AddUserGroup = (props) => {
	return (
		<div>
			<Modal
				{...props}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h6 className="">
							<AiOutlineUsergroupAdd size="1.4em" />
							<span className="pl-2 pt-1">Add User to Groups</span>
						</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddUserToGroupForm refetch={props.refetch} group_id={props.group_id} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddUserGroup;

import React, { useEffect } from 'react';
import routes from '../../routes';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/DashboardNavbarContainer';
// import Header from '../../components/header/Header'

const AuthLayout = (props) => {
	const history = useHistory();
	const { Content } = Layout;

	useEffect(() => {
		if (!sessionStorage.getItem('token')) {
			// User is not logged in. Redirect back to login
			history.push(routes.login);
			return;
		}
		// Fetch data for logged in user using token
	});
	return (
		<Layout>
			{/* <Sidebar  /> */}
			<Layout>
				<Content className="">
					<div className="hk-wrapper hk-alt-nav">
						{/* Top Navbar */}

						<Navbar />

						{/* Main Content */}
						<div className="hk-pg-wrapper">{props.children}</div>
					</div>
				</Content>
			</Layout>
		</Layout>
	);
};

export default AuthLayout;

import React, { useContext, useEffect, useState } from 'react';
import ViewWorkorder from './ViewWorkorder';
import { DashboardContext } from '../../context/DashboardContext';
import { ModalContext } from '../../context/ModalContext';
import { api_srv } from '../../services';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import routes from '../../routes';

const ViewWorkorderContainer = (props) => {
  const { id } = props.match.params;
  const {
    workOrderDetails,
    setWorkOrderDetails,
    showTemplate,
    setShowTemplate,
    rejectionRemarks,
    setRejectionRemarks,
    invoiceSettings,
  } = useContext(DashboardContext);
  const history = useHistory();

  const { modalClose } = useContext(ModalContext);

  const get_workorder_details = async () => {
    setWorkOrderDetails((workOrderDetails) => ({ data: null, loading: true }));
    try {
      let resp = await (await api_srv).viewWorkorder(id);
      console.log('the response of view workorder is', resp);

      setWorkOrderDetails((workOrderDetails) => ({
        data: resp.work_order_details,
        loading: true,
      }));
    } catch (err) {
      let error = await err;
      console.log('error fetching details', error);
      setWorkOrderDetails({ ...workOrderDetails, loading: false });
    }
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
      placement: 'bottomRight',
    });
  };

  const accept = async (date, cost) => {
    try {
      let resp = await (await api_srv).acceptWorkorder(id, date, cost);
      addCostBreakdown(invoiceSettings.lineItems);
      console.log('accept workorder', resp);
      openNotificationWithIcon(
        'success',
        'Success',
        'Workorder sent successfully'
      );
      modalClose();
      history.push(routes.workorders);
    } catch (err) {
      let error = await err;
      console.log('error in accept workorder', error);
      openNotificationWithIcon('error', 'Error', `${error.message}`);
      modalClose();
    }
  };
  const addCostBreakdown = async (details) => {
    try {
      let resp = await (await api_srv).addCostEstimate(id, details);
      console.log('add breakdown success', resp);
    } catch (err) {
      let error = await err;
      console.log('error in add breakdown', error);
    }
  };

  const reject = async () => {
    setRejectionRemarks({ ...rejectionRemarks, loading: true });
    try {
      let resp = await (await api_srv).rejectWorkorder(id, rejectionRemarks);
      console.log('reject workorder', resp);
      setRejectionRemarks({ ...rejectionRemarks, loading: false });
      modalClose();
    } catch (err) {
      let error = await err;
      console.log('error in reject workorder', error);
      setRejectionRemarks({ ...rejectionRemarks, loading: false });
      modalClose();
    }
  };

  // function RenderItems({items, fallback}){
  //   if (!items || items.length===0){
  //     return fallback
  //   } else{
  //     return items.map(item=>(
  //     <div key={item.id}>{item.name}</div>
  //     )
  //   }

  // }

  useEffect(() => {
    get_workorder_details();
    return () => {};
  }, []);

  return (
    <div>
      <ViewWorkorder
        state={workOrderDetails}
        showTemplate={showTemplate}
        setShowTemplate={setShowTemplate}
        accept={accept}
        reject={reject}
        sp_workOrder_id={id}
        get_workorder_details={get_workorder_details}
      />
    </div>
  );
};

export default ViewWorkorderContainer;

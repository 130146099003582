import React from 'react';
import Search from './Search';
import { FiMoreVertical, FiEdit } from 'react-icons/fi';
import Avatar from 'assets/dist/img/avatar1.jpg';
const Conversations = () => {
	return (
		<div className="chatapp-left">
			<header>
				<a href="javascript:void(0)" className="chat-more">
					<span>
						<FiMoreVertical />
					</span>
				</a>
				<span className>Chats</span>
				<a href="javascript:void(0)" className="chat-compose">
					<span>
						<FiEdit />
					</span>
				</a>
			</header>
			<Search />
			<div className="chatapp-users-list">
				<div className="nicescroll-bar">
					<a href="javascript:void(0);" className="media">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-warning badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Clay Masse</div>
								<div className="user-last-chat">No one saves us but ourselves.</div>
							</div>
							<div>
								<div className="last-chat-time block">5 min</div>
								<div className="badge badge-success badge-pill">15</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat active-user">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-success badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Evie Ono</div>
								<div className="user-last-chat">Unity is strength 😀</div>
							</div>
							<div>
								<div className="last-chat-time block">4:56 PM</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-warning badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Madalyn Rascon</div>
								<div className="user-last-chat">
									Respect yourself if you would have others respect you.
								</div>
							</div>
							<div>
								<div className="last-chat-time block">5:00 PM</div>
								<div className="badge badge-success badge-pill">3</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-danger badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Mitsuko Heid</div>
								<div className="user-last-chat">OK that sounds perfect 🤞</div>
							</div>
							<div>
								<div className="last-chat-time block">10:14 AM</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-success badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Ezequiel Merideth</div>
								<div className="user-last-chat">staff was calling me to make sure I was there</div>
							</div>
							<div>
								<div className="last-chat-time block">9:15 AM</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-success badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Jonnie Metoyer</div>
								<div className="user-last-chat">There is so much caos happening</div>
							</div>
							<div>
								<div className="last-chat-time block">10:24 AM</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-warning badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Angelic Lauver</div>
								<div className="user-last-chat">Concentrate on studdies will be the text</div>
							</div>
							<div>
								<div className="last-chat-time block">12.56 PM</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-danger badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Prischila Shy</div>
								<div className="user-last-chat">
									Good morning! I am here to talk to you about the meeting
								</div>
							</div>
							<div>
								<div className="last-chat-time block">Yesterday</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-danger badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Lynda Stack</div>
								<div className="user-last-chat">How much should we contribute?</div>
							</div>
							<div>
								<div className="last-chat-time block">Friday</div>
							</div>
						</div>
					</a>
					<div className="chat-hr-wrap">
						<hr />
					</div>
					<a href="javascript:void(0);" className="media read-chat">
						<div className="media-img-wrap">
							<div className="avatar">
								<img src={Avatar} alt="user" className="avatar-img rounded-circle" />
							</div>
							<span className="badge badge-success badge-indicator" />
						</div>
						<div className="media-body">
							<div>
								<div className="user-name">Aash Bill</div>
								<div className="user-last-chat">I have to revise the task</div>
							</div>
							<div>
								<div className="last-chat-time block">Thursday</div>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Conversations;

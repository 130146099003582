import React from "react";

const TransactionalTable = () => {
  return (
    <div className="fmapp-table-view">
      <table id="fmapp_table_view" className="table table-hover w-100">
        <thead>
          <tr>
            <th>Date</th>
            <th>Transaction Type and Details</th>
            <th>Value Date </th>
            <th>Credit</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01/01/2021</td>

            <td>
              RTGS Transfer
              <p>REF: 1243545</p>
            </td>
            <td>01/01/2021</td>

            <td>-</td>
            <td>450000</td>
            <td>450000</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TransactionalTable;

import React from 'react';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Loader from '../../components/loader';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { view_role_details } from '../../services/apiSrv';
import { Tabs, Descriptions, Menu, Dropdown, Empty, Select } from 'antd';
import { FcLock } from 'react-icons/fc';
import Moment from 'react-moment';

export default function RoleDetails() {
	const { TabPane } = Tabs;
	const { id } = useParams();
	const _roleDetails = async () => {
		let resp = await view_role_details(id);
		console.log('THE Role DETAILS', resp);
		return resp.role_details;
	};

	function handleMenuClick(e) {
		console.log('click', e);
	}

	const { Option } = Select;

	const children = [];
	let options = ['profile', 'product', 'clients', 'workflow', 'quotation'];
	for (let i = options; i < options.length; i++) {
		children.push(<Option key={i.toString(36) + i}>{i}</Option>);
	}

	function handleChange(value) {
		console.log(`selected ${value}`);
	}

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="1">Approve</Menu.Item>
			<Menu.Item key="2">Edit</Menu.Item>
			<Menu.Item key="3">Activate</Menu.Item>
			<Menu.Item key="4">Delete</Menu.Item>
		</Menu>
	);

	const { data, isLoading, isSuccess, isError } = useQuery('role details', _roleDetails);

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div className="hk-wrapper hk-vertical-nav">
					<div className="hk-pg-wrpper">
						{/* Container */}
						<div className="container-fluid">
							{/* Row */}
							<div className="row">
								<div className="col-xl-12 pa-0">
									<div className="profile-cover-wrap overlay-wrap">
										<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
										<div className="bg-overlay bg-trans-dark-60" />
										<div className="container profile-cover-content py-30"></div>
									</div>
									<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
										<div className="container">
											<div className="card">
												<div className="card-body">
													<div className="d-flex justify-content-between mb-4">
														<div>
															<h5>
																<span className="mr-2">
																	<FcLock />
																</span>
																{data.name}
															</h5>
														</div>
														<div>
															<Dropdown.Button overlay={menu}>Actions</Dropdown.Button>
														</div>
													</div>
													<Tabs defaultActiveKey="1" type="card">
														<TabPane tab="Role Details" key="1">
															<Descriptions title="Role Info" column={2}>
																<Descriptions.Item label="Role Name">
																	{data.name}
																</Descriptions.Item>

																<Descriptions.Item label="Department Code">
																	{data.role_code}
																</Descriptions.Item>

																<Descriptions.Item label="Created Date">
																	<Moment format="DD-MMM-YYYY HH:MM:a">
																		{data.created_date}
																	</Moment>
																</Descriptions.Item>
																<Descriptions.Item label="Description">
																	{data.description}
																</Descriptions.Item>
															</Descriptions>
														</TabPane>
														<TabPane tab="Permissions" key="2" className="clearfix">
															<div className="d-flex float-right ">
																<Select
																	mode="tags"
																	style={{ width: '250px' }}
																	placeholder="Grant Resource"
																	onChange={handleChange}
																>
																	{children}
																</Select>
																<button className="ml-2 btn btn-sm btn-success">
																	add
																</button>
															</div>

															{data.grants.length === 0 ? (
																<Empty />
															) : (
																<div className="fmapp-table-view">
																	<table
																		id="fmapp_table_view"
																		className="table table-hover w-100"
																	>
																		<thead>
																			<tr>
																				<th>#</th>
																				<th>Name</th>
																				<th>Permissions </th>
																				<th>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{data.grants.map((grant, index) => (
																				<tr key={grant.role_code}>
																					<td>{index + 1}</td>
																					<td>{grant.name}</td>

																					<td>{grant.description}</td>
																					<td>
																						<Dropdown.Button
																							overlay={menu}
																						></Dropdown.Button>
																					</td>
																				</tr>
																			))}
																		</tbody>
																	</table>
																</div>
															)}
														</TabPane>
														<TabPane tab="Audit Trail" key="3">
															Audit Trail here
														</TabPane>
													</Tabs>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* /Row */}
						</div>
						{/* /Container */}
						{/* Footer */}

						{/* /Footer */}
					</div>
					{/* /Main Content */}
				</div>
			)}
		</>
	);
}

import React, { useContext } from 'react';
import AddUserGroup from './AddUserGroup';
import { ModalContext } from '../../../context/ModalContext';
import { openNotificationWithIcon } from '../../notification';
import { create_user_group } from '../../../services/apiSrv';
import { useMutation } from 'react-query';

const AddUserGroupFormContainer = ({ refetch }) => {
	const { modalClose } = useContext(ModalContext);

	const mutation = useMutation(create_user_group, {
		onSuccess: () => {
			refetch();
			modalClose();
			openNotificationWithIcon('success', `Success`, 'User Group added successfully');
		},
		onError: async (err) => {
			let error = await err;
			modalClose();
			openNotificationWithIcon('error', `Error`, `${error.message}`);
		},
	});

	return <AddUserGroup _addUserGroup={mutation} />;
};

export default AddUserGroupFormContainer;

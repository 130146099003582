import React, { useContext } from 'react';
import CredentialForm from './CredentialForm';
import { AuthContext } from '../../../context/AuthContext';
import { AlertsContext } from '../../../context/AlertsContext';
import { setupCredentials } from '../../../services/apiSrv';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import routes from '../../../routes';

const CredentialFormContainer = () => {
	const { setAlert } = useContext(AuthContext);
	const { setShowAlert } = useContext(AlertsContext);

	const history = useHistory();

	const mutation = useMutation(setupCredentials, {
		onSuccess: () => history.push(routes.kyc),
		onError: async (error) => {
			let err = await error;
			setAlert({ ...alert, message: err.message, variant: 'danger' });

			setShowAlert(true);
		},
	});

	return <CredentialForm submitCredentials={mutation} />;
};

export default CredentialFormContainer;

import React, { useState, useEffect } from "react";
import MapComponent from "./MapComponent";

const MapView = (props) => {
  const [location, setLocation] = useState({
    currentLatLng: {
      lat: 0,
      lng: 0,
    },
    isMarkerShown: false,
  });

  useEffect(() => {
    getGeoLocation();
  }, []);
  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation((prevState) => ({
          currentLatLng: {
            ...prevState.currentLatLng,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        }));
        localStorage.setItem("lat", position.coords.latitude);
        localStorage.setItem("lng", position.coords.longitude);
      });
    } else {
      return (error) => console.log(error);
    }
  };

  return <MapComponent />;
};

export default MapView;

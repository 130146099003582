import React from 'react';
import { Tabs, Descriptions } from 'antd';
import bg from '../../../assets/dist/img/tickets-bg.jpg';
import { AiOutlineSetting } from 'react-icons/ai';

const ConfigDetails = () => {
	const { TabPane } = Tabs;
	return (
		<>
			<div className="hk-wrapper hk-vertical-nav">
				<div className="hk-pg-wrpper">
					{/* Container */}
					<div className="container-fluid">
						{/* Row */}
						<div className="row">
							<div className="col-xl-12 pa-0">
								<div className="profile-cover-wrap overlay-wrap">
									<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
									<div className="bg-overlay bg-trans-dark-60" />
									<div className="container profile-cover-content py-30"></div>
								</div>
								<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
									<div className="container">
										<div className="card">
											<div className="card-body">
												<div className="d-flex justify-content-between mb-4">
													<div>
														<h5>
															<span className="mr-2">
																<AiOutlineSetting />
															</span>
															Config Name
														</h5>
													</div>
													<div>
														<button className="btn btn-sm btn-warning mr-3">Verify </button>
														<button className="btn btn-sm btn-success mr-3">Update </button>
														<button className="btn btn-sm btn-danger">Delete </button>
													</div>
												</div>
												<Tabs defaultActiveKey="1" type="card">
													<TabPane tab="Configuration Details" key="1">
														<Descriptions title="Department Info" size="default">
															<Descriptions.Item label="Configuration Name">
																Name
															</Descriptions.Item>

															<Descriptions.Item label="Your referenece">
																ref456
															</Descriptions.Item>
															<Descriptions.Item label="Status">Active</Descriptions.Item>
															<Descriptions.Item label="System Reference">
																454545
															</Descriptions.Item>
															<Descriptions.Item label="Channel">
																<a href>Bank</a>
															</Descriptions.Item>
															<Descriptions.Item label="Account">
																<a href>qwe345</a>
															</Descriptions.Item>
															<Descriptions.Item label="Type">ereefg</Descriptions.Item>
															<Descriptions.Item label="Settlement Bank">
																ereefg
															</Descriptions.Item>
															<Descriptions.Item label="Settlement Ref">
																ereefg
															</Descriptions.Item>
															<Descriptions.Item label="Details">
																details
															</Descriptions.Item>
															<Descriptions.Item label="Description">
																description
															</Descriptions.Item>
														</Descriptions>
													</TabPane>
												</Tabs>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* /Row */}
					</div>
					{/* /Container */}
					{/* Footer */}

					{/* /Footer */}
				</div>
				{/* /Main Content */}
			</div>
		</>
	);
};

export default ConfigDetails;

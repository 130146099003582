import React, { useState } from 'react';
import { Input, Empty, Drawer, Steps } from 'antd';
import { Link } from 'react-router-dom';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Moment from 'react-moment';
import { FcAddRow } from 'react-icons/fc';
import AdminServicesContainer from '../../components/forms/adminServices/AdminServicesContainer';
import AddServices from '../../components/forms/adminServices/AddService';

import * as Icon from 'react-feather';

export default function ServiceCenter({ services, view_workorder }) {
	const { Search } = Input;
	const { Step } = Steps;

	const [visible, setVisible] = useState(false);
	const [current, setCurrent] = useState(0);
	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	function next() {
		const current_step = current + 1;
		setCurrent(current_step);
	}

	function prev() {
		const current_step = current - 1;
		setCurrent(current_step);
	}

	const steps = [
		{
			title: 'Services List',
			description: 'Select a service to configure.',
			content: <AdminServicesContainer next={next} />,
		},

		{
			title: 'Add a Service',
			description: 'Fill out details required.',
			content: <AddServices prev={prev} />,
		},
	];

	return (
		<div className="hk-wrapper hk-vertical-nav">
			<div className="hk-pg-wrpper">
				{/* Container */}
				<div className="container-fluid">
					{/* Row */}
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="profile-cover-wrap overlay-wrap">
								<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
								<div className="bg-overlay bg-trans-dark-60" />
								<div className="container profile-cover-content py-30">
									<div className="text-white text-capitalize display-6 mb-5 font-weight-400">
										<Icon.Layers className="mr-2" />
										My Services
									</div>
								</div>
							</div>
							<div className="container">
								{
									<div className="fmapp-wrap">
										<div className="fm-box">
											<div className="fmapp-main fmapp-view-switch">
												<header>
													<div className="fm-options-wrap">
														<a href id="fmapp_sidebar_move" className="fmapp-sidebar-move">
															<span className="feather-icon">
																<i data-feather="menu" />
															</span>
														</a>
														<a href>
															<span className="feather-icon">
																<Icon.Filter size={18} />
															</span>
														</a>
													</div>

													<div className="fm-options-wrap">
														<button
															id="fm_view_switcher"
															className="btn btn-primary btn-sm"
															onClick={showDrawer}
														>
															<FcAddRow /> Add Service
														</button>
													</div>

													<Drawer
														title="Service Setup"
														placement="right"
														width="80%"
														onClose={onClose}
														visible={visible}
													>
														<div className="row">
															<div className="col-md-3 ">
																<div className="card">
																	<div className="card-body">
																		<Steps direction="vertical" current={current}>
																			{steps.map((item) => (
																				<Step
																					key={item.title}
																					title={item.title}
																					description={item.description}
																				/>
																			))}
																		</Steps>
																	</div>
																</div>
															</div>
															<div className="col-md-8 offset-md-1">
																<div className="steps-content ">
																	{steps[current].content}
																</div>
															</div>
														</div>
													</Drawer>
												</header>

												<div className="fm-body mt-75">
													<div className="nicescroll-bar">
														<div className="fmapp-view-wrap">
															<div className="fmapp-grid-view">
																<div className="row">
																	<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																		<span className="file-name mt-10"></span>
																	</div>
																</div>
															</div>
															{services.length === 0 ? (
																<Empty description="You currently dont have any services " />
															) : (
																<div className="fmapp-table-view">
																	<table
																		id="fmapp_table_view"
																		className="table table-hover w-100"
																	>
																		<thead>
																			<tr>
																				<th>Name</th>
																				<th>Created Date </th>
																				<th>Description</th>
																				<th>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{services.map((workorder) => {
																				return (
																					<tr>
																						<td>{workorder.name}</td>
																						<td>
																							<span className="mr-10">
																								<Moment format="DD-MM-YYYY">
																									{
																										workorder.created_date
																									}
																								</Moment>
																							</span>
																							<span className="file-time-modified inline-block">
																								<Moment
																									format="hh:mm A"
																									date={
																										workorder.created_date
																									}
																								/>
																							</span>
																						</td>

																						<td>
																							{workorder.request_remarks}
																						</td>
																						<td>
																							<span className="">
																								<Link
																									onClick={() =>
																										view_workorder(
																											workorder.sp_workOrder_id
																										)
																									}
																								>
																									view
																								</Link>
																							</span>
																						</td>
																					</tr>
																				);
																			})}
																		</tbody>
																	</table>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>

					{/* /Row */}
				</div>
			</div>
			{/* /Main Content */}
		</div>
	);
}

import React from 'react'
import Navbar from '../../components/navbar/DashboardNavbarContainer'
import * as Icon from "react-feather";
import Image from '../../assets/dist/img/avatar2.jpg'
import {Link} from 'react-router-dom'

export default function Dashboard({data}) {
  console.log("the data",data)
    return (
        <div>
   
        {/* Container */}
        <div className="container mt-xl-50 mt-sm-30 mt-15">
          {/* Title */}
          <div className="hk-pg-header align-items-top">
            <div>
              <h2 className="hk-pg-title font-weight-600 mb-10">Dashboard</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing? <Link to="/dashboard">Learn more.</Link></p>
            </div>
          </div>
          {/* /Title */}
          {/* Row */}
          <div className="row">
            <div className="col-xl-12">
              <div className="hk-row">
                <div className="col-lg-7">
                  <div className="hk-row">							
                    <div className="col-sm-6">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="d-flex justify-content-between mb-5">
                            <div>
                              <span className="d-block font-15 text-dark font-weight-500">Total Job Tickets</span>
                            </div>
                            <div>
                              <span className="badge badge-primary  badge-sm">+10%</span>
                            </div>
                          </div>
                          <div>
                            <span className="d-block display-5 text-dark mb-5">{data.Total_workorders}</span>
                            <small className="d-block">172,458 Target Users</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="d-flex justify-content-between mb-5">
                            <div>
                              <span className="d-block font-15 text-dark font-weight-500">Accepted Job Tickets</span>
                            </div>
                            <div>
                              <span className="badge badge-danger   badge-sm">+10%</span>
                            </div>
                          </div>
                          <div>
                            <span className="d-block display-5 text-dark mb-5">{data.Accepted_workorders}</span>
                            <small className="d-block">90M Targeted</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="d-flex justify-content-between mb-5">
                            <div>
                              <span className="d-block font-15 text-dark font-weight-500">Pending Job Tickets</span>
                            </div>
                            <div>
                              <span className="badge badge-primary  badge-sm">-1.5%</span>
                            </div>
                          </div>
                          <div>
                            <span className="d-block display-5 text-dark mb-5">{data.Pending_workorders}</span>
                            <small className="d-block">100 Regular</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="d-flex justify-content-between mb-5">
                            <div>
                              <span className="d-block font-15 text-dark font-weight-500">Earnings</span>
                            </div>
                            <div>
                              <span className="badge badge-warning  badge-sm">+60%</span>
                            </div>
                          </div>
                          <div>
                            <span className="d-block display-5 text-dark mb-5">{data.Earnings}</span>
                            <small className="d-block">$100M Targeted</small>
                          </div>
                        </div>
                      </div>
                    </div>	
                  </div>
                  <div className="card card-refresh">
                    <div className="refresh-container">
                      <div className="loader-pendulums" />
                    </div>
                    <div className="card-header card-header-action">
                      <h6>Recent Transactions</h6>
                      <div className="d-flex align-items-center card-action-wrap">
                    
                        <div className="inline-block dropdown">
                          <a className="dropdown-toggle no-caret" data-toggle="dropdown" href="#" aria-expanded="false" role="button"><i className="ion ion-md-more" /></a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider" />
                            <a className="dropdown-item" href="#">Separated link</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="hk-legend-wrap mb-20">
                   
                      </div>
                      <div id="e_chart_4" style={{height: '300px'}} />
                    </div>
                  </div>
                  {/* <div className="card">
                    <div className="card-header card-header-action">
                      <h6>Country Stats</h6>
                      <div className="d-flex align-items-center card-action-wrap">
                        <a href="#" className="inline-block refresh mr-15">
                          <i className="ion ion-md-arrow-down" />
                        </a>
                        <a href="#" className="inline-block full-screen">
                          <i className="ion ion-md-expand" />
                        </a>
                      </div>
                    </div>
                    <div className="card-body pa-0">
                      <div className="pa-20">
                        <div id="world_map_marker_1" style={{height: '360px'}} />
                      </div>
                      <div className="table-wrap">
                        <div className="table-responsive">
                          <table className="table table-sm table-striped table-hover mb-0">
                            <thead>
                              <tr>
                                <th className="w-25">Country</th>
                                <th>Sessions</th>
                                <th>Goals</th>
                                <th>Goals Rate</th>
                                <th>Bounce Rate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Canada</td>
                                <td>55,555</td>
                                <td>210</td>
                                <td>2.46%</td>
                                <td>0.26%</td>
                              </tr>
                              <tr>
                                <td>India</td>
                                <td>24,152</td>
                                <td>135</td>
                                <td>0.58%</td>
                                <td>0.43%</td>
                              </tr>
                              <tr>
                                <td>UK</td>
                                <td>15,640</td>
                                <td>324</td>
                                <td>5.15%</td>
                                <td>2.47%</td>
                              </tr>
                              <tr>
                                <td>Botswana</td>
                                <td>12,148</td>
                                <td>854</td>
                                <td>4.19%</td>
                                <td>0.1%</td>
                              </tr>
                              <tr>
                                <td>UAE</td>
                                <td>11,258</td>
                                <td>453</td>
                                <td>8.15%</td>
                                <td>0.14%</td>
                              </tr>
                              <tr>
                                <td>Australia</td>
                                <td>10,786</td>
                                <td>376</td>
                                <td>5.48%</td>
                                <td>0.45%</td>
                              </tr>
                              <tr>
                                <td>Phillipines</td>
                                <td>9,485</td>
                                <td>63</td>
                                <td>3.51%</td>
                                <td>0.9%</td>
                              </tr>
                              <tr>
                                <td>Srilanka</td>
                                <td>1,485</td>
                                <td>63</td>
                                <td>3.51%</td>
                                <td>0.9%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-5">
                  {/* <div className="card">
                    <div className="card-header card-header-action">
                      <h6>Visit by Traffic Types</h6>
                      <div className="d-flex align-items-center card-action-wrap">
                        <div className="inline-block dropdown">
                          <a className="dropdown-toggle no-caret" data-toggle="dropdown" href="#" aria-expanded="false" role="button"><i className="ion ion-ios-more" /></a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider" />
                            <a className="dropdown-item" href="#">Separated link</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div id="e_chart_3" style={{height: '250px'}} />
                      <div className="hk-legend-wrap mt-20">
                        <div className="hk-legend">
                          <span className="d-10 bg-purple rounded-circle d-inline-block" /><span>18-24</span>
                        </div>
                        <div className="hk-legend">
                          <span className="d-10 bg-grey-light-1 rounded-circle d-inline-block" /><span>25-34</span>
                        </div>
                        <div className="hk-legend">
                          <span className="d-10 bg-grey-light-2  rounded-circle d-inline-block" /><span>35-44</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="hk-legend-wrap mb-10">
                        <div className="hk-legend">
                          <span className="d-10 bg-purple rounded-circle d-inline-block" /><span>Today</span>
                        </div>
                        <div className="hk-legend">
                          <span className="d-10 bg-grey-light-1  rounded-circle d-inline-block" /><span>Yesterday</span>
                        </div>
                      </div>
                      <div id="e_chart_1" className="echart" style={{height: '200px'}} />
                      <div className="row mt-20">
                        <div className="col-4">
                          <span className="d-block text-capitalize mb-5">Previous</span>
                          <span className="d-block font-weight-600 font-13">79.82</span>
                        </div>
                        <div className="col-4">
                          <span className="d-block text-capitalize mb-5">% Change</span>
                          <span className="d-block font-weight-600 font-13">+14.29</span>
                        </div>
                        <div className="col-4">
                          <span className="d-block text-capitalize mb-5">Trend</span>
                          <span className="block">
                            <i className="zmdi zmdi-trending-down text-danger font-20" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h6>Recent Activity</h6>
                      <div className="d-flex align-items-center card-action-wrap">
                        <div className="inline-block dropdown">
                          <a className="dropdown-toggle no-caret" data-toggle="dropdown" href="#" aria-expanded="false" role="button"><i className="ion ion-ios-more" /></a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider" />
                            <a className="dropdown-item" href="#">Separated link</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="user-activity user-activity-sm">
                        <div className="media">
                          <div className="media-img-wrap">
                            <div className="avatar avatar-xs">
                              <img src="dist/img/avatar2.jpg" alt="user" className="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Laura Thompson</span><span className="pl-5">joined josh groben team.</span></span>
                              <span className="d-block font-13">3 hours ago</span>
                            </div>
                          </div>
                        </div>
                        <div className="media">
                          <div className="media-img-wrap">
                            <div className="avatar avatar-xs">
                              <img src="dist/img/avatar3.jpg" alt="user" className="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Meayme Fletcher</span><span className="pl-5">liked photos</span></span>
                              <span className="d-block font-13 mb-10">6 hours ago</span>
                            </div>
                            <div className="d-flex">
                              <a href="#" className="w-75p mr-10"><img className="card-img-top rounded" src="dist/img/gallery/mock7.jpg" alt="Card image cap" /></a>
                              <a href="#" className="w-75p mr-10"><img className="card-img-top rounded" src="dist/img/gallery/mock11.jpg" alt="Card image cap" /></a>
                              <a href="#" className="w-75p mr-10"><img className="card-img-top rounded" src="dist/img/gallery/mock12.jpg" alt="Card image cap" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="media">
                          <div className="media-img-wrap">
                            <div className="avatar avatar-xs">
                              <span className="avatar-text avatar-text-info rounded-circle">
                                <span className="initial-wrap"><span>B</span></span>
                              </span>
                            </div>
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Billy Flowers</span><span className="pl-5">Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text.</span></span>
                              <span className="d-block font-13">3 days ago</span>
                            </div>
                          </div>
                        </div>
                        <div className="media">
                          <div className="media-img-wrap">
                            <div className="avatar avatar-xs">
                              <img src="dist/img/avatar4.jpg" alt="user" className="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Morgan Freeman</span><span className="pl-5">joined josh groben team.</span></span>
                              <span className="d-block font-13">3 hours ago</span>
                            </div>
                          </div>
                        </div>
                        <div className="media">
                          <div className="media-img-wrap">
                            <div className="avatar avatar-xs">
                              <span className="avatar-text avatar-text-success rounded-circle">
                                <span className="initial-wrap"><span>M</span></span>
                              </span>
                            </div>
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Meayme Fletcher</span><span className="pl-5">liked photos</span></span>
                              <span className="d-block font-13 mb-10">6 hours ago</span>
                            </div>
                            <div className="d-flex">
                              <a href="#" className="w-75p mr-10"><img className="card-img-top rounded" src="dist/img/gallery/mock1.jpg" alt="Card image cap" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="media">
                          <div className="media-img-wrap">
                            <div className="avatar avatar-xs">
                              <img src="dist/img/avatar6.jpg" alt="user" className="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Evie Ono</span><span className="pl-5">joined josh groben team.</span></span>
                              <span className="d-block font-13">3 hours ago</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Row */}
        </div>
        {/* /Container */}
        {/* Footer */}
        {/* <div className="hk-footer-wrap container">
          <footer className="footer">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <p>Pampered by<a href="https://hencework.com/" className="text-dark" target="_blank">Hencework</a> © 2019</p>
              </div>
              <div className="col-md-6 col-sm-12">
                <p className="d-inline-block">Follow us</p>
                <a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-facebook" /></span></a>
                <a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-twitter" /></span></a>
                <a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-google-plus" /></span></a>
              </div>
            </div>
          </footer>
        </div> */}
        {/* /Footer */}
      </div>
    
  
    )
}

import React, { useContext } from 'react';
import AddUsers from './AddUsers';
import { ModalContext } from '../../../context/ModalContext';
import { openNotificationWithIcon } from '../../notification';
import { create_user } from '../../../services/apiSrv';
import { useMutation, useQuery } from 'react-query';

const AddUsersFormContainer = ({ departments, roles, refetch }) => {
	const { modalClose } = useContext(ModalContext);

	const mutation = useMutation(create_user, {
		onSuccess: () => {
			refetch();
			modalClose();
			openNotificationWithIcon('success', `Success`, 'User added successfully');
		},
		onError: async (err) => {
			let error = await err;
			modalClose();
			openNotificationWithIcon('error', `Error`, `${error.message}`);
		},
	});

	return <AddUsers _addUser={mutation} departments={departments} roles={roles} />;
};

export default AddUsersFormContainer;

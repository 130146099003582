import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { KycContextProvider } from "./context/KycContext";
import { ModalContextProvider } from "./context/ModalContext";
import { AuthContextProvider } from "./context/AuthContext";
import { AlertsContextProvider } from "./context/AlertsContext";
import { DashboardContextProvider } from "./context/DashboardContext";
import { ToastProvider } from "react-toast-notifications";
import { QueryClient, QueryClientProvider} from 'react-query'

// fonts
import "./assets/dist/fonts/signika/Signika-Bold.ttf";
import "./assets/dist/fonts/signika/Signika-Light.ttf";
import "./assets/dist/fonts/signika/Signika-Regular.ttf";

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
       <QueryClientProvider client={queryClient}>
    <AlertsContextProvider>
      <ModalContextProvider>
          <KycContextProvider>
            <AuthContextProvider>
            <DashboardContextProvider>
              <App />
            </DashboardContextProvider>
            </AuthContextProvider>
          </KycContextProvider>
      </ModalContextProvider>
      </AlertsContextProvider>
      </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useContext } from 'react';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Loader from '../../components/loader';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { user_group_details } from '../../services/apiSrv';
import { Tabs, Descriptions, Empty } from 'antd';
import { MdGroup } from 'react-icons/md';
import AddUsers from '../../components/modals/AddUsersToGroup';
import { ModalContext } from '../../context/ModalContext';

export default function UserGroupDetails() {
	const { TabPane } = Tabs;
	const { id } = useParams();
	const _userGroupDetails = async () => {
		let resp = await user_group_details(id);
		console.log('THE USER GROUP DETAILS', resp);
		return resp.entity_group_details;
	};

	const { data, isLoading, isSuccess, isError, refetch } = useQuery('user group details', _userGroupDetails);
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div className="hk-wrapper hk-vertical-nav">
					<div className="hk-pg-wrpper">
						{/* Container */}
						<div className="container-fluid">
							{/* Row */}
							<div className="row">
								<div className="col-xl-12 pa-0">
									<div className="profile-cover-wrap overlay-wrap">
										<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
										<div className="bg-overlay bg-trans-dark-60" />
										<div className="container profile-cover-content py-30"></div>
									</div>
									<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
										<div className="container">
											<div className="card">
												<div className="card-body">
													<div className="d-flex justify-content-between mb-4">
														<div>
															<h5>
																<span className="mr-2">
																	<MdGroup />
																</span>
																{data.group_name}
															</h5>
														</div>
														<div>
															<button className="btn btn-sm btn-warning mr-3">
																Edit{' '}
															</button>
															<button
																className="btn btn-sm btn-success"
																onClick={() => setModalShow('users')}
															>
																Add Users{' '}
															</button>
														</div>
													</div>
													<AddUsers
														show={modalShow === 'users' ? true : false}
														onHide={modalClose}
														refetch={refetch}
														group_id={id}
													/>
													<Tabs defaultActiveKey="1" type="card">
														<TabPane tab="User Group Details" key="1">
															<Descriptions title="User Group Info" column={2}>
																<Descriptions.Item label="Name">
																	{data.group_name}
																</Descriptions.Item>

																<Descriptions.Item label="Group Code">
																	{data.group_code}
																</Descriptions.Item>
																<Descriptions.Item label="Group Description">
																	{data.description}
																</Descriptions.Item>
															</Descriptions>
														</TabPane>
														<TabPane tab="Users" key="2">
															{data.users.length === 0 ? (
																<Empty />
															) : (
																'users are to be loaded'
															)}
														</TabPane>
													</Tabs>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* /Row */}
						</div>
						{/* /Container */}
						{/* Footer */}

						{/* /Footer */}
					</div>
					{/* /Main Content */}
				</div>
			)}
		</>
	);
}

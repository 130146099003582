import React, { useContext, useEffect } from 'react';
import Tax from './Taxes';
import { list_taxes } from '../../services/apiSrv';
import { useQuery } from 'react-query';
import Loader from '../../components/loader';

const TaxesContainer = () => {
	async function _listTaxes() {
		let resp = await await list_taxes();
		return resp.entity_taxes;
	}
	const { data, isLoading, isSuccess, refetch } = useQuery('list taxes', _listTaxes);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Tax data={data} refetch={refetch} />}
		</div>
	);
};

export default TaxesContainer;

import React, { useContext, useEffect, useState, useRef } from 'react';
import CreateInvoice from './CreateInvoice';
import { DashboardContext } from '../../context/DashboardContext';
import { api_srv } from '../../services';

const CreateInvoiceContainer = (props) => {
  const isUnmounted = useRef(false);
  const { id } = props.match.params;

  const { workOrderDetails, setWorkOrderDetails } = useContext(
    DashboardContext
  );
  const [spDetails, SetSpDetails] = useState('');

  useEffect(() => {
    get_invoice_details();

    // return () => {
    //   isUnmounted.current = true;
    // };
  }, []);

  const get_invoice_details = async () => {
    setWorkOrderDetails({ ...workOrderDetails, loading: true });

    try {
      let resp = await (await api_srv).viewWorkorder(id);
      console.log('the response of view workorder is', resp);
      if (!isUnmounted.current) {
        setWorkOrderDetails({
          ...workOrderDetails,
          data: resp.work_order_details,
          loading: false,
        });
      }

      kycDetails();
      // setWorkOrderDetails(workOrderDetails=>({...workOrderDetails, data:resp, loading:true }));
    } catch (err) {
      let error = await err;
      console.log('error fetching details', error);
      setWorkOrderDetails({ ...workOrderDetails, loading: false });
      // setWorkOrderDetails(workOrderDetails=>({...workOrderDetails, data:null, loading:true }));
    }
  };

  const kycDetails = async () => {
    try {
      let resp = await (await api_srv).check_kyc();
      console.log('KYC RESPONSE', resp);
      SetSpDetails(resp.serviceprovider);
    } catch (err) {
      let error = await err;
      console.log('Error fetching kyc details', error);
    }
  };

  return (
    <div>
      {!workOrderDetails.loading && (
        <CreateInvoice state={workOrderDetails} id={id} spDetails={spDetails} />
      )}
    </div>
  );
};

export default CreateInvoiceContainer;

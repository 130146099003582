import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { DashboardContext } from "../../../context/DashboardContext";
import { AlertsContext } from "../../../context/AlertsContext";
import Alert from "../../alerts/WarningAlert";

const MemoForm = ({ state, handleMemoChange,  send_memo }) => {
  
  

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Enter Memo Title"),
        message: Yup.string().required("This field is required"),
      })}
      onSubmit={() => send_memo()}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="mode">Memo Title</label>
            <input
              name="title"
              value={state.title}
              onChange={handleMemoChange}
              className={
                "form-control" +
                (errors.title && touched.title ? " is-invalid" : "")
              }
              placeholder="Memo Title"
              type="text"
            />
            <ErrorMessage
              name="title"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea 
          name="message"
          value={state.message}
          onChange={handleMemoChange}
          className={
            "form-control" +
            (errors.message && touched.message ? " is-invalid" : "")
          }
        id="memo" rows={3}  />
           <ErrorMessage
              name="message"
              component="div"
              className="invalid-feedback"
            />
      </div>
          {/* <div className="form-group">
            <label className="d-block">
              Upload Report
            </label>
          
           
            <input 
            type="file" 
            name="doc" 
            onChange={handleUpload} />
          </div> */}
        
        

          <button
            className="btn btn-success mx-auto mt-5"
            type="submit"
            disabled={state.loading}
          >
            {state.loading && (
              <i
                className="fa fa-circle-notch fa-spin"
                style={{ marginRight: "5px" }}
              />
            )}
            {state.loading && (
              <span className="text-capitalize">Please wait</span>
            )}
            {!state.loading && <span className="text-capitalize"> Send</span>}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default MemoForm;

import React, { useContext } from 'react';
import { Card } from 'antd';
import './adminServices.css';
import { FcServices } from 'react-icons/fc';
import { DashboardContext } from '../../../context/DashboardContext';

const AdminServices = ({ next, services }) => {
	const { setServiceRef } = useContext(DashboardContext);
	function onChangeService(e) {
		const { value } = e.target;
		setServiceRef(value);
	}
	return (
		<div>
			<h3 className="">Step 01</h3>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
			</p>

			<div className="row mb-4">
				{services.map((service) => (
					<div className="col-md-4" key={service.system_service_ref}>
						<div class="funkyradio">
							<div class="funkyradio-success">
								<input
									type="radio"
									name="service"
									id={service.system_service_ref}
									value={service.system_service_ref}
									onChange={onChangeService}
								/>
								<label for={service.system_service_ref}>
									<div className="d-flex justify-content-center align-items-center">
										<div className="mt-3">
											<FcServices size="5em" />
											<h6 className="card-title ">{service.service_name}</h6>
											<p className="card-text ">~{service.service_type}</p>
										</div>
									</div>

									{/* <div className="card">
										<div className="card-body text-center">
											<p>
												<FcServices size="1.5em" />
											</p>
											<h6 className="card-title">{service.service_name}</h6>
											<p className="card-text ">~{service.service_type}</p>
										</div>
									</div> */}
								</label>
							</div>
						</div>
					</div>
				))}
			</div>

			<button className="btn btn-sm btn-primary float-right" onClick={next}>
				Next
			</button>
		</div>
	);
};

export default AdminServices;

import React, { useEffect, useContext } from "react";
import DashboardNavbar from "./DashboardNavbar";
import { api_srv } from "../../services";
import { DashboardContext } from "../../context/DashboardContext";

const DashboardNavbarContainer = () => {
  const { notifications, setNotifications } = useContext(DashboardContext);

  useEffect(() => {
    notification();
  }, []);

  const notification = async () => {
    setNotifications({ ...notifications, loading: true });
    try {
      let resp = await (await api_srv).fetchNotifications();
      setNotifications({ ...notifications, data: resp, loading: true });
    } catch (err) {
      let error = await err;
      setNotifications({ ...notifications, loading: false });
    }
  };
  return <DashboardNavbar />;
};

export default DashboardNavbarContainer;

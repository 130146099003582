import React from "react";
import Workorders from "./Workorders";
import { api_srv } from "../../services";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { useQuery } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const WorkordersContainer = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

  const history = useHistory();

  const listWorkorders = async () => {
    let resp = await (await api_srv).fetchWorkorders();
    return resp.sp_workOrders;
  };
  const { data, isLoading, isSuccess } = useQuery("workorders", listWorkorders);

  const view_workorder = async (id) => {
    try {
      history.push(`/dashboard/work-orders/view/${id}`);
    } catch (err) {
      console.log(err);
    }
  };
  const create_invoice = async (id) => {
    try {
      history.push(`/dashboard/create-invoice/view/${id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const filterData = (status) => {
    switch (status) {
      case "pending":
        console.log("THE DATA FILTER INSTANCES pending", data, status);
        data.filter((workorder) => {
          return workorder.work_order_status === "pending";
        });
        break;
      case "active:pending approval":
        console.log("THE DATA FILTER INSTANCES pending approval", data);
        data.filter((workorder) => {
          return workorder.work_order_status === "active:pending approval";
        });
        break;

      case "active:approved":
        console.log("THE DATA FILTER INSTANCES approved", data);
        data.filter((workorder) => {
          return workorder.work_order_status === "active:approved";
        });
        break;
      case "completed":
        console.log("THE DATA FILTER INSTANCES completed", data);
        data.filter((workorder) => {
          return workorder.work_order_status === "completed";
        });
        break;
      case "rejected":
        data.filter((workorder) => {
          return workorder.work_order_status === "rejected";
        });
        break;
      default:
    }
  };

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spin className="text-center" indicator={antIcon} />
        </div>
      )}
      {isSuccess && (
        <Workorders
          state={data}
          view_workorder={view_workorder}
          create_invoice={create_invoice}
          filterData={filterData}
        />
      )}
    </>
  );
};

export default WorkordersContainer;

import React from 'react';
import Chat from './Chat';

const ChatContainer = () => {
	return (
		<div>
			<Chat />
		</div>
	);
};

export default ChatContainer;

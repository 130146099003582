import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { DashboardContext } from "../../../context/DashboardContext";
import { AlertsContext } from "../../../context/AlertsContext";
import Alert from "../../alerts/WarningAlert";

const ReportForm = ({
  state,
  handleReportUploadChange,
  handleUpload,
  upload_report,
}) => {
  const { alert } = useContext(DashboardContext);
  const { showAlert } = useContext(AlertsContext);
  const { message, variant } = alert;

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Enter Report Title"),
        description: Yup.string().required("A description is required"),
        // mode: Yup.string().required("This field is required"),
      })}
      onSubmit={() => upload_report()}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="mode">Report Title</label>
            <input
              name="title"
              value={state.title}
              onChange={handleReportUploadChange}
              className={
                "form-control" +
                (errors.title && touched.title ? " is-invalid" : "")
              }
              placeholder="Report Title"
              type="text"
            />
            <ErrorMessage
              name="title"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group">
            <label className="d-block">Upload Report</label>

            <input type="file" name="doc" onChange={handleUpload} />
          </div>

          <div className="form-group">
            <label htmlFor="mode">Comments</label>
            <textarea
              name="description"
              rows="3"
              value={state.description}
              onChange={handleReportUploadChange}
              className={
                "form-control" +
                (errors.description && touched.description ? " is-invalid" : "")
              }
              placeholder="Enter your description"
            />
            <ErrorMessage
              name="description"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <button
            className="btn btn-success mx-auto mt-5"
            type="submit"
            disabled={state.loading}
          >
            {state.loading && (
              <i
                className="fa fa-circle-notch fa-spin"
                style={{ marginRight: "5px" }}
              />
            )}
            {state.loading && (
              <span className="text-capitalize">Please wait</span>
            )}
            {!state.loading && <span className="text-capitalize"> Send</span>}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ReportForm;

import React, { useContext, useEffect, useState } from 'react';

import { Row, Col, Select, Radio, Input, Form, Button, TimePicker } from 'antd';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */

export default function ConfigForm({ create, accounts }) {
	const [form] = Form.useForm();
	const { Option } = Select;
	const [configType, setConfigType] = useState('');
	const [settlementRef, setSettlementRef] = useState('SYSTEM');

	const onFinish = (values) => {
		console.log(values);
		create.mutate(values);
	};
	const config = {
		rules: [
			{
				type: 'object',
				required: true,
				message: 'Please select time!',
			},
		],
	};

	const handleFormValuesChange = (changedValues) => {
		const fieldName = Object.keys(changedValues)[0];

		if (fieldName === 'type') {
			const value = changedValues[fieldName];
			setConfigType(value);
		}
		if (fieldName === 'settlement_ref') {
			const value = changedValues[fieldName];
			setSettlementRef(value);
		}
	};

	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	var monthDay,
		monthDays = [];

	for (monthDay = 1; monthDay <= 31; monthDay++) {
		monthDays.push(monthDay);
	}

	return (
		<div>
			<Form
				form={form}
				layout="vertical"
				name="settlement-config"
				onFinish={onFinish}
				validateMessages={validateMessages}
				onValuesChange={handleFormValuesChange}
			>
				<Form.Item name="name" label="Name" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item name="your_ref" label="Your Reference" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item name="description" label="Description" rules={[{ required: true }]}>
					<Input.TextArea />
				</Form.Item>
				<Form.Item name="account" label="Account" rules={[{ required: true }]}>
					<Select
						showSearch
						placeholder="Select..."
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{accounts.map((account) => (
							<Option key={account.account_number} value={account.account_number}>
								{account.account_name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="channel" label="Channel" rules={[{ required: true }]}>
					<Select
						showSearch
						placeholder="Select..."
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option value="jack">ABC</Option>
						<Option value="lucy">DEF</Option>
					</Select>
				</Form.Item>
				<Form.Item name="type" label="Select Type" rules={[{ required: true }]}>
					<Radio.Group>
						<Radio value="frequency_based">Frequency Based</Radio>
						<Radio value="limit_based">Limit Based</Radio>
					</Radio.Group>
				</Form.Item>
				{configType === 'frequency_based' && (
					<>
						<Form.Item name="frequency" label="Frequency" rules={[{ required: true }]}>
							<Radio.Group>
								<Radio value="daily">Daily</Radio>
								<Radio value="weekly">Weekly</Radio>
								<Radio value="monthly">Monthly</Radio>
							</Radio.Group>
						</Form.Item>
						<Row>
							<Col md={{ span: 12 }}>
								<Form.Item name="frequency_time" label="Time of Day" {...config}>
									<TimePicker format="HH:MM:a" />
								</Form.Item>
							</Col>
							<Col md={{ span: 12 }}>
								<Form.Item
									name="frequency_day_of_week"
									label="Day of Week"
									rules={[{ required: true }]}
								>
									<Select
										showSearch
										placeholder="Select..."
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{days.map((day) => (
											<Option value={day} key={day}>
												{day}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col md={{ span: 12 }}>
								<Form.Item
									name="frequency_day_of_month"
									label="Day of Month"
									rules={[{ required: true }]}
								>
									<Select
										showSearch
										placeholder="Select..."
										optionFilterProp="children"
										style={{ width: '80%' }}
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{monthDays.map((day) => (
											<Option value={day} key={day}>
												{day}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col md={{ span: 12 }}>
								<Form.Item name="frequency_remarks" label="Remarks" rules={[{ required: true }]}>
									<Input.TextArea />
								</Form.Item>
							</Col>
						</Row>
					</>
				)}
				{configType === 'limit_based' && (
					<Form.Item name="release_level" label="Release Level" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				)}
				<Form.Item
					name="settlement_ref"
					label="Settlement Reference"
					initialValue="SYSTEM"
					rules={[{ required: true }]}
				>
					<Radio.Group>
						<Radio value="SYSTEM">Nexus Reference</Radio>
						<Radio value="SERVICEPROVIDER">Service Provider</Radio>
					</Radio.Group>
				</Form.Item>
				{settlementRef === 'SERVICEPROVIDER' && (
					<Form.Item
						name="sp_settlement_ref"
						label="Service Provider Settlement Ref"
						rules={[{ required: true }]}
					>
						<Input />
					</Form.Item>
				)}
				<Form.Item>
					<Button type="primary" htmlType="submit" disabled={create.isLoading}>
						{create.isLoading ? (
							<span>
								<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
								Please wait...
							</span>
						) : (
							'Save'
						)}
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}

import React, { useContext } from "react";
import { KycContext } from "../../../context/KycContext";
import * as Icon from "react-feather";
import { Button } from "antd";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function Partners({ state, onChange, addNewPartner, goNext }) {
  const { prev } = useContext(KycContext);

  console.log("the satet of partners", state);

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Email is required"),
        id_number: Yup.string().required("Identification Number is required"),
        kra_pin: Yup.string().required("KRA Number is required"),
        contact: Yup.string().required("Contact is required"),
        residency: Yup.string().required("Select a residency"),
        partner_type: Yup.string().required("Select a type"),
        address: Yup.string().required("An address is required"),
        postal_code: Yup.string().required("Select a postal code"),
        passport: Yup.string().when("residency", {
          is: (value) => value === "foreigner",
          then: Yup.string().required("Passport Number is required"),
          otherwise: Yup.string(),
        }),
      })}
      onSubmit={() => goNext()}
    >
      {({ errors, touched }) => (
        <Form className="">
          <p>Step 4</p>
          <h4 className="title-background">Add Partners</h4>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut
            voluptas dolor expedita optio unde explicabo nemo? Eius, non.Lorem
            ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas
            dolor expedita optio unde explicabo nemo? Eius, non.
          </p>
          {/* <Button className="float-right">Add Another Partner </Button> */}
          <div>
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Name of Partner</label>
                  <input
                    className={
                      "form-control" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                    placeholder="Enter your name"
                    type="text"
                    name="name"
                    value={state.partner}
                    onChange={onChange}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                    placeholder="example@email.com"
                    type="email"
                    name="email"
                    value={state.email}
                    onChange={onChange}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="id_number">ID Number/Alien ID </label>
                  <input
                    className={
                      "form-control" +
                      (errors.id_number && touched.id_number
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="1234567"
                    type="text"
                    name="id_number"
                    value={state.id_number}
                    onChange={onChange}
                  />
                  <ErrorMessage
                    name="id_number"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="contact">Phone Number</label>
                  <input
                    className={
                      "form-control" +
                      (errors.contact && touched.contact ? " is-invalid" : "")
                    }
                    placeholder="0702123456"
                    type="text"
                    name="contact"
                    value={state.contact}
                    onChange={onChange}
                  />
                  <ErrorMessage
                    name="contact"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="kra_pin">KRA Pin</label>
                  <input
                    className={
                      "form-control " +
                      (errors.kra_pin && touched.kra_pin ? " is-invalid" : "")
                    }
                    placeholder="A1234567T"
                    type="text"
                    name="kra_pin"
                    value={state.kra_pin}
                    onChange={onChange}
                  />
                  <ErrorMessage
                    name="kra_pin"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="residency">Residency</label>
                  <select
                    className={
                      "form-control" +
                      (errors.residency && touched.residency
                        ? " is-invalid"
                        : "")
                    }
                    placeholder=""
                    type="text"
                    name="residency"
                    value={state.residency}
                    onChange={onChange}
                  >
                    <option value="">Choose...</option>
                    <option value="resident">Local Resident</option>
                    <option value="foreigner">Foreigner</option>
                  </select>
                  <ErrorMessage
                    name="residency"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="address">Postal Address</label>
                  <input
                    className={
                      "form-control" +
                      (errors.address && touched.address ? " is-invalid" : "")
                    }
                    placeholder="Enter your address"
                    type="text"
                    name="address"
                    value={state.address}
                    onChange={onChange}
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="postal_code">Postal Code</label>
                  <select
                    className={
                      "form-control" +
                      (errors.postal_code && touched.postal_code
                        ? " is-invalid"
                        : "")
                    }
                    placeholder=""
                    type="text"
                    name="postal_code"
                    value={state.postal_code}
                    onChange={onChange}
                  >
                    <option value="">Choose...</option>
                    <option value="00100">00100</option>
                    <option value="80100">80100</option>
                  </select>
                  <ErrorMessage
                    name="postal_code"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="partner_type">Partner Type</label>
                  <select
                    className={
                      "form-control" +
                      (errors.partner_type && touched.partner_type
                        ? " is-invalid"
                        : "")
                    }
                    placeholder=""
                    type="text"
                    name="partner_type"
                    value={state.partner_type}
                    onChange={onChange}
                  >
                    <option value="">Choose...</option>
                    <option value="executive">Executive</option>
                    <option value="non-executive">Non Executive</option>
                  </select>
                  <ErrorMessage
                    name="partner_type"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="passport">
                    Passport Number{" "}
                    <small className="text-danger">
                      {" "}
                      *compulsory for non-resident partners
                    </small>
                  </label>
                  <input
                    className={
                      "form-control" +
                      (errors.passport && touched.passport ? " is-invalid" : "")
                    }
                    placeholder="Enter Passport Number"
                    type="text"
                    name="passport"
                    value={state.passport}
                    onChange={onChange}
                  />
                  <ErrorMessage
                    name="passport"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>
          </div>
          <p>
            Have more than one partner,{" "}
            <a className="text-primary" href onClick={() => addNewPartner()}>
              <u>click here</u>
            </a>{" "}
            to add another
          </p>

          <Button
            className=" float-right mb-35 mt-30 py-1 btn btn-primary btn-blue"
            htmlType="submit"
          >
            Next
            <Icon.ArrowRight size={22} className="pl-1" />
          </Button>
          <Button
            className=" float-right btn py-1  mb-35 mt-30 btn-secondary mr-3"
            onClick={prev}
          >
            <Icon.ArrowLeft size={22} className="pr-1" />
            Previous
          </Button>
        </Form>
      )}
    </Formik>
  );
}

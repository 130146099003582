import React from 'react';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Loader from '../../components/loader';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { view_user } from '../../services/apiSrv';
import { Tabs, Descriptions } from 'antd';
import { FaUser } from 'react-icons/fa';
import Moment from 'react-moment';

export default function UserDetails() {
	const { TabPane } = Tabs;
	const { id } = useParams();
	const _userDetails = async () => {
		let resp = await view_user(id);
		console.log('THE USER DETAILS', resp);
		return resp.user_details;
	};

	const { data, isLoading, isSuccess, isError } = useQuery('user details', _userDetails);

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div className="hk-wrapper hk-vertical-nav">
					<div className="hk-pg-wrpper">
						{/* Container */}
						<div className="container-fluid">
							{/* Row */}
							<div className="row">
								<div className="col-xl-12 pa-0">
									<div className="profile-cover-wrap overlay-wrap">
										<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
										<div className="bg-overlay bg-trans-dark-60" />
										<div className="container profile-cover-content py-30"></div>
									</div>
									<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
										<div className="container">
											<div className="card">
												<div className="card-body">
													<div className="d-flex justify-content-between mb-4">
														<div>
															<h5>
																<span className="mr-2">
																	<FaUser />
																</span>
																{data.username}
															</h5>
														</div>
														<div>
															<button className="btn btn-sm btn-warning mr-3">
																Reset Password{' '}
															</button>
														</div>
													</div>

													<Tabs defaultActiveKey="1" type="card">
														<TabPane tab="User Details" key="1">
															<Descriptions title="User Info" column={2}>
																<Descriptions.Item label="Name">
																	{data.names}
																</Descriptions.Item>

																<Descriptions.Item label="Email">
																	{data.email}
																</Descriptions.Item>
																<Descriptions.Item label="Contact">
																	{data.contact}
																</Descriptions.Item>
																<Descriptions.Item label="Created Date">
																	<Moment format="DD-MMM-YYYY HH:MM:a">
																		{data.created_date}
																	</Moment>
																</Descriptions.Item>
																<Descriptions.Item label="Login status">
																	{data.login_status}
																</Descriptions.Item>
																<Descriptions.Item label="Password Policy Code">
																	{data.password_policy_code}
																</Descriptions.Item>
																<Descriptions.Item label="Department Code">
																	{data.department_code}
																</Descriptions.Item>
																<Descriptions.Item label="User Type">
																	{data.user_type}
																</Descriptions.Item>
																<Descriptions.Item label="User Sub Type">
																	{data.user_sub_type}
																</Descriptions.Item>

																<Descriptions.Item label="Role Code">
																	{data.role_code}
																</Descriptions.Item>
																<Descriptions.Item label="Email Notification Settings">
																	{`${data.notification_settings.email}`}
																</Descriptions.Item>
																<Descriptions.Item label="SMS Notification Settings">
																	{`${data.notification_settings.sms}`}
																</Descriptions.Item>
																<Descriptions.Item label="InApp Notification Settings">
																	{`${data.notification_settings.inapp}`}
																</Descriptions.Item>
																<Descriptions.Item label="Security Context Code">
																	{data.security_context_code}
																</Descriptions.Item>
																<Descriptions.Item label="Two Factor Authentication">
																	{`${data.two_factory_auth_enabled}`}
																</Descriptions.Item>
															</Descriptions>
														</TabPane>
														<TabPane tab="Audit Trail" key="2">
															Audit trail will appear here
														</TabPane>
														<TabPane tab="Login History" key="3">
															Login History will appear here
														</TabPane>
													</Tabs>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* /Row */}
						</div>
						{/* /Container */}
						{/* Footer */}

						{/* /Footer */}
					</div>
					{/* /Main Content */}
				</div>
			)}
		</>
	);
}

import React, { useContext } from "react";
import SignUpForm from "./SignUpForm";
import { AuthContext } from "../../../context/AuthContext";
import { AlertsContext } from "../../../context/AlertsContext";
import { register,checkPin } from "../../../services/apiSrv";
import { useMutation } from "react-query";

const SignUpFormContainer = ({ servicesCategories }) => {
  const { newProvider, setNewProvider, setAlert, alert, setPinExists } = useContext(
    AuthContext
  );
  const { setShowAlert } = useContext(AlertsContext);



  const _checkPin = useMutation(checkPin, {
    onSuccess: (data) => {
    },
    onError: (error) => {
      setAlert({ ...alert, message: error.message, variant: "danger" });
      setShowAlert(true);
    },
  });

   const mutation = useMutation(register, {
    onMutate: (data)=>_checkPin.mutate(data.pin.value),
    onSuccess: (data) => {setAlert({
        ...alert,
        message: `${data.status} : Email verification link has been sent to your email`,
        variant: "success",
      });
      setShowAlert(true)},
    onError:  async(error) => {
      let err = await error
   setAlert({ ...alert, message: err.message, variant: "danger" });
     
      setShowAlert(true);
    },
  });



 
  return (
    <SignUpForm
      servicesCategories={servicesCategories}
      _signup={mutation}
    />
  );
};

export default SignUpFormContainer;

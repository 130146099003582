import React from 'react'
import Login from './Login'

const LoginContainer = () => {

    return (
      <Login
      />
    )
}

export default LoginContainer

import React, { useEffect, useContext } from "react";
import VerifyToken from "./VerifyToken";
import { AuthContext } from "../../context/AuthContext";
import {api_srv} from '../../services'
import queryString from 'query-string'
import {useHistory} from 'react-router-dom'
import routes from '../../routes'
import { useToasts } from "react-toast-notifications";


const VerifyTokenContainer = (props) => {
  
  const { token } = queryString.parse(props.location.search);
  const history = useHistory()
  const { addToast } = useToasts();
  const { registrationId, confirmimgToken, setConfirmingToken, setRegistrationId } = useContext(AuthContext);

  const verifyEmail = async () => {
    // setConfirmingToken(true);
  
    try {
      let resp = await (await api_srv).verifyEmail(token);
      await setRegistrationId(resp.registration_setup_id);
      localStorage.setItem("REGISTRATION_ID", resp.registration_setup_id);
      // setConfirmingToken(false);
    history.push('/credentials')
   
    } catch (err) {
      let error = await err;
      // setConfirmingToken(false);
      console.log("Error verifying your email", error.message);
      history.push(routes.signup);   
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);
  return <VerifyToken 
      registrationId={registrationId}
      // confirmimgToken={confirmimgToken}
  
  />;
};

export default VerifyTokenContainer;

import React, {useState, useContext} from 'react'
import Search from './Search'
import {DashboardContext} from '../../context/DashboardContext'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow
} from '@react-google-maps/api'


const libraries =["places"];
const mapContainerStyle = {
  height: "100vh",
  width: "100%",
};

const options = {
  zoomControl: true,
};


const MapComponent = () => {

  const context = useContext(DashboardContext)
const {coordinates, setCoordinates}= context
  const [markers, setMarkers]= useState([])
  const [selected, setSelected]= useState(null)
  const onMapClick = React.useCallback((e) => {
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const center = {
    lat: 	Number(coordinates.lat),
    lng: Number(coordinates.lng),
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
const onMarkerDragEnd=(event)=>{

  setCoordinates({...coordinates,lat: event.latLng.lat(),lng: event.latLng.lng()})

}


  // pan and zoom to the exact lat and lng when user selects a location from comboOptions
  const panTo = React.useCallback(({lat,lng})=>{
    mapRef.current.panTo({lat,lng});
    mapRef.current.setZoom(18)
  },[])

  return (
    <div>
      <Search panTo={panTo} />
      <GoogleMap 
      mapContainerStyle={mapContainerStyle} 
      center={center}
      options={options}
      zoom={14}
      onClick={onMapClick}
      onLoad={onMapLoad}
      >
        <Marker position={center} draggable={true} onDragEnd ={onMarkerDragEnd } />
 
   {selected ?
    (<InfoWindow 
   position={center}
   onCloseClick={()=>{setSelected(null)}}
   >
   
   </InfoWindow>):null}
      </GoogleMap>
    </div>
  )
}


export default MapComponent

import React from "react";
import {Spin} from 'antd'



export default (props) => (
  <div>
    
      <Spin tip="Redirecting, Please wait..." />
     
  </div>
);

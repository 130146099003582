import React, { useContext } from 'react';

import { AuthContext } from '../../../context/AuthContext';
import { AlertsContext } from '../../../context/AlertsContext';
import Alert from '../../alerts/WarningAlert';
import { credentialsForm } from '../../../shared/utils/formConfig';
import useForm from '../../../shared/customHooks/useForm';

const CredentialForm = ({ submitCredentials }) => {
	const { alert } = useContext(AuthContext);
	const { showAlert } = useContext(AlertsContext);
	const { message, variant } = alert;

	const { renderFormInputs, isFormValid, form } = useForm(credentialsForm);
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				submitCredentials.mutate(form);
			}}
		>
			<h1 className="display-4 mb-50">Set up Your Credentials</h1>
			{showAlert && <Alert message={message} variant={variant} />}

			{renderFormInputs()}

			<button
				className="btn btn-primary btn-block mt-4"
				type="submit"
				disabled={!isFormValid() || submitCredentials.isLoading}
			>
				{submitCredentials.isLoading ? (
					<span>
						<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
						Please wait...
					</span>
				) : (
					'Submit'
				)}
			</button>
		</form>
	);
};

export default CredentialForm;

import React, { useState, useContext } from 'react';
import { Form, Input, TimePicker, Radio, Select } from 'antd';
import Search from '../../map/Search';
import ReactTags from 'react-tag-autocomplete';
import { useMutation } from 'react-query';
import { add_services } from '../../../services/apiSrv';
import { DashboardContext } from '../../../context/DashboardContext';

const AddService = ({ prev }) => {
	const { serviceRef } = useContext(DashboardContext);
	console.log('THE SERVICE REF IS', serviceRef);
	const [tags, setTags] = useState([]);
	const [suggestions, setSuggestions] = useState([
		{ id: 3, name: 'Bananas' },
		{ id: 4, name: 'Mangos' },
		{ id: 5, name: 'Lemons' },
		{ id: 6, name: 'Apricots' },
	]);
	let reactTags = React.createRef();
	const { Option } = Select;
	const { TextArea } = Input;

	const _addService = async (values) => {
		try {
			let resp = await await add_services(serviceRef, values);
			console.log('SUCCESSFULLY ADDED SEVICE', resp);
		} catch (err) {
			let error = await err;
			console.log('THE ERROR ADDING SERVICE', error.message);
		}
	};

	const onFinish = (values) => {
		console.log('Received values of form: ', values);
		_addService(values);
	};

	const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

	function onDelete(i) {
		const tempTags = tags.slice(0);
		tempTags.splice(i, 1);
		setTags(tempTags);
	}

	function onAddition(tag) {
		const tempTags = [].concat(tags, tag);
		setTags(tempTags);
	}

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<div>
			<h3 className="">Step 02</h3>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
			</p>
			<Form
				name="service_form"
				className=""
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				layout="vertical"
			>
				<Form.Item
					label="Service Name"
					name="service_name"
					rules={[{ required: true, message: 'Please input the Name!' }]}
				>
					<Input placeholder="" />
				</Form.Item>
				<Form.Item
					label="Select a Fee"
					name="fee_code"
					rules={[{ required: true, message: 'Please select a fee!' }]}
				>
					<Select
						showSearch
						placeholder="Select..."
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option value="jack">Jack</Option>
						<Option value="lucy">Lucy</Option>
						<Option value="tom">Tom</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Service Description"
					name="description"
					rules={[{ required: true, message: 'Please input the description of the service!' }]}
				>
					<TextArea rows={3} />
				</Form.Item>
				<Form.Item
					label="Emails"
					name="emails"
					// rules={[{ required: true, message: 'Please input email!' }]}
				>
					<ReactTags
						style={{ width: '100%' }}
						ref={reactTags}
						tags={tags}
						suggestions={suggestions}
						onDelete={onDelete}
						onAddition={onAddition}
					/>
				</Form.Item>
				<Form.Item
					label="Service Account Management"
					name="account_management"
					rules={[{ required: true, message: 'Please choose an option!' }]}
				>
					<Radio.Group>
						<Radio value={1}>Main</Radio>
						<Radio value={2}>Service</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label="Location"
					name="location"
					// rules={[{ required: true, message: 'Location is required!' }]}
				>
					<Search />
				</Form.Item>
				<Form.Item
					label="Working Hours"
					name="work_hours"
					rules={[{ required: true, message: 'Working hours are required!' }]}
				>
					{/* <Select
						showSearch
						style={{ width: 250, marginRight: '1.5em' }}
						placeholder="Select a day"
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{days.map((day) => (
							<Option key={day} value={day}>
								{day.charAt(0).toUpperCase() + day.slice(1)}
							</Option>
						))}
					</Select> */}
					<TimePicker.RangePicker format="HH.mm.A" />
				</Form.Item>
				{/* <button className="btn btn-primary btn-sm ml-3">Add</button> */}
				<Form.Item>
					<button
						type="submit"
						className="btn btn-sm btn-success float-right"
						onClick={() => console.log('save')}
					>
						Save
					</button>
					<button className="btn btn-sm btn-primary float-right mr-3" onClick={prev}>
						Prev
					</button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default AddService;

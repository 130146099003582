import React,{useEffect, useState} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import {Link} from 'react-router-dom'

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import Carousel from 'react-bootstrap/Carousel'


import Image from '../assets/dist/img/image1.png'
import Image1 from '../assets/dist/img/img1.jpg'
import Image3 from '../assets/dist/img/img3.jpg'
import Image4 from '../assets/dist/img/img4.jpg'
import Image14 from '../assets/dist/img/img14.jpg'
import Image5 from '../assets/dist/img/img5.jpg'
import Image7 from '../assets/dist/img/img7.jpg'
import Image11 from '../assets/dist/img/img11.jpg'
// import Functions from '../scripts/'

export default function Homepage() {

  // const [key, setKey] = useState('home');
  const [step, setStep] = useState(1);

  useEffect(() => {
 const script = document.createElement("script");
 script.async = true;
 script.src = '../scripts/functions';
//  div.appendChild(script)
document.head.appendChild(script)
  }, [])
  useEffect(() => {
 const script = document.createElement("script");
 script.async = true;
 script.src = '../scripts/jquery';
//  div.appendChild(script)
document.head.appendChild(script)
  }, [])


  useEffect(() => {
  },[step])

  const options = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: true
};


  const ShowTab=()=>{
  
    return(
            
    
      <Tab.Container id="left-tabs-example" defaultActiveKey={step}>
          <section className="insurances-slider section-gray">
      
     
      <div className="center">
        <div className="green-line" />
       
        <div className="images">
        <Tab.Content>
        <Tab.Pane eventKey={1}>
          <div className="image" style={{backgroundImage:  `url(${Image11})`}} />
   </Tab.Pane>
   <Tab.Pane eventKey={2}>
          <div className="image" style={{backgroundImage:  `url(${Image14})`}} data-insurance-key="travel" />
          </Tab.Pane>
          <Tab.Pane eventKey={3}>
          <div className="image" style={{backgroundImage:  `url(${Image3})`}} data-insurance-key="life" />
          </Tab.Pane>
          <Tab.Pane eventKey={4}>
          <div className="image" style={{backgroundImage:  `url(${Image4})`}} data-insurance-key="car" />
        </Tab.Pane>
        </Tab.Content>
        </div>
        
        <div className="content">
      <div className="descriptions">
     
      <Tab.Content>
        <Tab.Pane eventKey={1}>
        <div className="" >
          <h3>Get an insurance</h3>
          <h2>House insurance</h2>
          <p>
            Suspendisse potenti. Nullam pharetra sed felis non sodales. Nam
            eleifend, arcu sed imperdiet euismod, augue eros consequat
            tortor, ut convallis eros. Aenean a ipsum vel eros elementum
            efficitur.
          </p>
        </div>
        </Tab.Pane>
        <Tab.Pane eventKey={2}>
        <div className="" data-insurance-key="travel">
          <h3>Get an insurance quote!</h3>
          <h2>Travel insurance</h2>
          <p>
            Etiam id ante est. Aenean pellentesque quam vel purus tincidunt
            tristique. Integer arcu nibh, tempus id ultricies ut, vulputate
            nec nunc. Etiam elit elit, commodo at interdum sed.
          </p>
        </div>
</Tab.Pane>
        <Tab.Pane eventKey={3}>
        <div className="" data-insurance-key="life">
          <h3>Get an insurance quote!</h3>
          <h2>Life insurance</h2>
          <p>
            Quisque pharetra lorem quis libero ornare fringilla. Maecenas
            nisl justo, suscipit vel tortor at, varius auctor erat. Maecenas
            efficitur felis nec arcu volutpat lacinia.
          </p>
        </div>
</Tab.Pane>
        <Tab.Pane eventKey={4}>
        <div className="" data-insurance-key="car">
          <h3>Get an insurance quote!</h3>
          <h2>Car insurance</h2>
          <p>
            Proin et posuere dolor, a finibus tellus. Phasellus suscipit
            gravida magna. Nam posuere nisi eu ex sodales, sit amet dictum
            turpis maximus. Maecenas sodales vehicula tellus, at imperdiet
            risus.
          </p>
        </div>
        </Tab.Pane>
        </Tab.Content>
      </div>
     
      <Nav  className="">

      <ul className="d-flex tabs">
      <Nav.Item>
      <Nav.Link eventKey={1}>
        <li className="active" data-insurance-key="house">
          <i className="fa fa-home" />
          <span>House insurance</span>
        </li>
     </Nav.Link>
     </Nav.Item>
    
     <Nav.Item>
      <Nav.Link eventKey={2}>
        <li data-insurance-key="travel">
          <i className="fa fa-bus" />
          <span>Travel insurance</span>
        </li>
        </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={3}>
            <li data-insurance-key="life">
              <i className="fa fa-umbrella" />
              <span>Life insurance</span>
            </li>
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={4}>
          <li data-insurance-key="car">
            <i className="fa fa-car" />
            <span>Car insurance</span>
          </li>
          </Nav.Link>
        </Nav.Item>
      </ul>
</Nav>
</div>
            <div className="clear" />
          </div>
  
        </section>
</Tab.Container>

    )}


    return (
      
          <div>
        <div id="contact-popup">
          <div id="contact-popup-background" />
          <div id="contact-popup-content" className="animated speed">
            <div id="contact-popup-close"><i className="fa fa-times" /></div>
            <div id="google-map" data-lat="37.42565" data-lng="-122.13535" data-zoom-level={12} />
            <div className="padding">
              <i className="fa fa-envelope-o background-icon" />
              <h3>Contact us</h3>
              <h2>Send us an <span>email!</span></h2>
              <form method="post" className="form">
                <h4>Contact details:</h4>
                <input type="text" name="Name" placeholder="Your name..." className="contact-form-element contact-form-client-name" />
                <input type="text" name="Phone number" placeholder="Your phone number..." className="contact-form-element last" />
                <input type="text" name="E-mail" placeholder="Your e-mail..." className="contact-form-element contact-form-client-email" />
                <input type="text" name="Title" placeholder="E-mail subject..." className="contact-form-element contact-form-client-message-title last" />
                <h4>Message:</h4>
                <textarea name="Message" rows={5} cols={5} placeholder="Your message..." className="contact-form-element" defaultValue={""} />
                <button className="button button-navy-blue send-contact" type="button">
                  Send a message! <i className="fa fa-paper-plane-o" />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="heading">
          <header>
            <div className="center">
              <div className="header-logo">
                <a href="index.html" className="text-light"><span className="text-white">Nexus</span>Insurance</a>
              </div>
              <nav>
                <ul className="menu">
                  <li ><a href="/login" className="text-white tx-24">Login</a></li>
                  <li className><a href="/register">Register</a></li>
                </ul>
                <div className="menu-responsive"><i className="fa fa-bars" /></div>
              </nav>
              <div className="clear" />
            </div>
          </header>
          <div className="heading-slides">
            <div className="heading-slide-single" data-slide-id={1} style={{backgroundImage: `url(${Image})`}}>
              <div className="flying-2">
                <h3>
                  Life insurances<br />
                  for your family
                </h3>
                <p className="pb-3">
                  Curabitur iaculis ante a nibh vulputate sollicitudin.
                </p>
              </div>
              <div className="heading-content">
                <h2>Insurances do matter</h2>
                <h3>
                  Lorem ipsum dolor sit<br />
                  amet, consectetur.
                </h3>
                <a className="button button-navy-blue-header pb-30" href="/login">Get started <i className="fa fa-paper-plane-o" /></a>
              </div>
            </div>
            <div className="heading-slide-single" data-slide-id={2} style={{backgroundImage:  `url(${Image3})`}}>
              <div className="flying-2">
                <h3>
                  Life insurances<br />
                  for your family
                </h3>
                <p className="pb-3">
                  Curabitur iaculis ante a nibh vulputate sollicitudin.
                </p>
              </div>
              <div className="heading-content">
                <h2>Insurances do matter</h2>
                <h3>
                  Lorem ipsum dolor sit<br />
                  amet, consectetur.
                </h3>
                <a className="button button-navy-blue-header" href="./signup.html">Get Started <i className="fa fa-paper-plane-o" /></a>
              </div>
            </div>
          </div>
        </div>
        <section className="call-to-action background">
          <div className="center">
            <div className="left">
              <h2>Take care of your family</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                hendrerit fringilla ligula, nec congue leo pharetra in. Quisque
                commodo vehicula justo, tincidunt vehicula elit consequat vel.
              </p>
              <div className="form">
                <div className="call-to-action-thanks">
                  <div className="call-to-action-thanks-content">
                    Thank you, we'll call you soon.
                    <span className="call-to-action-thanks-close">Close this notice.</span>
                  </div>
                </div>
                <input type="text" name="Phone number" placeholder="Your phone number..." className="phone-number" />
                <button className="button button-navy-blue send-phone-call-quote">
                  Get a phone call <i className="fa fa-phone" />
                </button>
              </div>
            </div>
            <div className="right">
              <div className="circles">
                <div className="circle-1 circle-pulse" />
                <div className="circle-2 circle-pulse" />
                <div className="circle-3 circle-pulse" />
                <div className="circle-4 circle-pulse" />
                <a href="tel:+2547000000">
                  <div className="circle-center">
                    <div className="circle-center-tip">
                      Call us: <strong>(+254) 720 000 000</strong>
                    </div>
                    <i className="fa fa-phone" />
                  </div>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>
        </section>
      
       <ShowTab />
          
  
       
        <section className="about-us">
          <div className="center">
            <div className="green-line" />
            <div className="left">
              <h3>About us</h3>
              <h2>Meet our insurance agency.</h2>
              <p>
                Curabitur iaculis ante a nibh vulputate sollicitudin. Integer ut
                tincidunt ligula. Praesent pretium, tellus at elementum
                ultrices.Curabitur iaculis ante a nibh vulputate sollicitudin.
                Integer ut tincidunt ligula. Praesent pretium, tellus at elementum
                ultrices.
              </p>
            </div>
            <div className="right">

              
{/* 
              <div className="images-slier"> */}
              <Carousel className="slides" indicators='false'>
  <Carousel.Item>
    <img
  
      className=""
      src={Image1}
      alt="First slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img

      className="d-block h-100 w-100"
      src={Image5}
      alt="Second slide"
    />

  </Carousel.Item>
  <Carousel.Item>
    <img

      className="d-block h-100 w-100"
      src={Image11}
      alt="Third slide"
    />

  </Carousel.Item>
  </Carousel>
              {/* <OwlCarousel  options={options}  >
               <div>
                 <img src={Image1} alt=""/>
                </div>
                 <div>
                 <img src={Image3} alt=""/>

                 </div> */}
                {/* <div className="images-slider-change">
                  <div className="images-slider-prev">
                    <i className="fa fa-angle-left" />
                  </div>
                  <div className="images-slider-next">
                    <i className="fa fa-angle-right" />
                  </div>
                </div> */}
                {/* <div className="images-slider-single" style={{backgroundImage:  `url(${Image5})`}} />
                <div className="images-slider-single" style={{backgroundImage:  `url(${Image7})`}} /> */}
                {/* </OwlCarousel> */}
              </div>
            </div>
            <div className="clear" />
          {/* </div> */}
        </section>
        <section className="slogan section-gray-top">
          <div className="center">
            <i className="fa fa-bar-chart-o background-icon" />
            <div className="left">
              <h3>Our vision</h3>
              <h2>Best insurances for everyone.</h2>
              <blockquote>
                <p>
                  Phasellus quis tortor in arcu maximus vehicula. Donec eget elit
                  vitae tortor convallis mattis. Aliquam erat volutpat. Integer
                  vitae quam ut leo accumsan consequat eu ac sapien.
                </p>
                <cite>John Doe</cite>
              </blockquote>
            </div>
            <div className="right">
              <div className="key-number-values">
                <div className="single single-top single-left">
                  <span className="number">1294</span>
                  <span className="description">satisfied customers</span>
                </div>
                <div className="single single-top single-right">
                  <span className="number">5</span>
                  <span className="description">professional agents</span>
                </div>
                <div className="single single-bottom single-left">
                  <span className="number">8</span>
                  <span className="description">insurances in our offer</span>
                </div>
                <div className="single single-bottom single-right">
                  <span className="number">12</span>
                  <span className="description">years of experience</span>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </section>
        <section className="image-slogan with-parallax" style={{backgroundImage:  `url(${Image11})`}}>
          <div className="flying-1">
            <span>Take care of your child</span>
          </div>
          <div className="flying-2">
            <h3>
              Insurances for<br />
              your child's future
            </h3>
            <p>Curabitur iaculis ante a nibh vulputate sollicitudin.</p>
          </div>
        </section>
        <section className="contact section-gray">
          <div className="center">
            <i className="fa fa-envelope-o background-icon" />
            <div className="contact-details">
              <div className="contact-detail-single">
                <h3><i className="fa fa-map-marker" /> Company information</h3>
                <p>
                  <strong>Insurance Agency</strong><br />
                  John Doe
                </p>
                <p className="without-margin-bottom">
                  <strong>Location</strong><br />Nairobi
                </p>
              </div>
              <div className="contact-detail-single">
                <h3><i className="fa fa-envelope-o" /> Contact details</h3>
                <p>
                  E-mail address: <br /><a href="#">contact@nexusinsurance.com</a>
                </p>
                <p className="without-margin-bottom">
                  <button className="button button-navy-blue" data-action="show-contact-popup">
                    Contact us! <i className="fa fa-phone" />
                  </button>
                </p>
              </div>
              <div className="contact-detail-single contact-detail-single-last">
                <h2>(+254) 720 000 000</h2>
                <p className="without-margin-bottom">Available 8:00am - 4:00pm</p>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="center">
            <div className="green-line" />
            <nav>
              <ul className="left">
                <li>
                  <h4>Find us!</h4>
                  <ul className="sub">
                    <li><a href="#">Facebook</a></li>
                    <li><a href="#">Google+</a></li>
                  </ul>
                </li>
              </ul>
            </nav>
            <ul className="right">
              <li>
                Copyright © 2020 <span className="divider">·</span>
                <a href="/index.html">Nexus Insurance</a>
              </li>
            </ul>
            <div className="clear" />
          </div>
        </footer>
      </div>
       
    )
}

import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { api_srv } from "../../services";
import Signup from './Signup'
import {useQuery} from 'react-query'
import Loader from '../../components/loader'

const SignupContainer = () => {
  const { servicesCategories, setServiceCategories } = useContext(AuthContext);

  const listCategories = async () => {
      let list_resp = await (await api_srv).listCategories();
    
      setServiceCategories(list_resp);
    return list_resp.categories
    }
  

const {isLoading, isError, isSuccess, data} = useQuery("fetch categories",listCategories)

  return(
    <>
    {isLoading && <Loader/>}
   { isSuccess && <Signup servicesCategories={data} />}
    </>

   );
};

export default SignupContainer;

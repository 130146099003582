import React from 'react';
import ServiceCenter from './ServiceCenter';
import { api_srv } from '../../services';
import { useQuery } from 'react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const ServiceCenterContainer = () => {
	const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

	const _listServices = async () => {
		let resp = await (await api_srv).list_services();
		console.log('the services', resp);
		return resp.sp_services;
	};
	const { data, isLoading, isSuccess } = useQuery('list services', _listServices);

	return (
		<div>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center">
					<Spin className="text-center" indicator={antIcon} />
				</div>
			)}
			{isSuccess && <ServiceCenter services={data} />}
		</div>
	);
};

export default ServiceCenterContainer;

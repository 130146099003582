import React, {useState, useContext} from 'react'
import { api_srv } from '../../services'
import RecoverPassword from './RecoverPassword'
import {AuthContext} from '../../context/AuthContext'
import {AlertsContext} from '../../context/AlertsContext'

const RecoverPasswordContainer = () => {

    const { setShowAlert} = useContext(AlertsContext);
    const {alert, setAlert} = useContext(AuthContext);

    const [recover, setRecover]= useState({
        username:'',
        loading:false
    })

    function handleRecoverUsername(e){
        const {value,name}= e.target
        setRecover({...recover, [name]:value})

    }

    const send_recovery_username = async(event)=>{
        event.preventDefault();
        setRecover({...recover, loading:true})
        try{
            let resp = await (await api_srv).sendUsername(recover.username)
            console.log("success",resp)
            setRecover({...recover, loading:false})
            setAlert({ ...alert, message: resp.status, variant: "success" });
            setShowAlert(true);


        }catch(err){
            let error = await err
            setRecover({...recover, loading:false})
            setAlert({ ...alert, message: error.message, variant: "danger" });
            setShowAlert(true);
        }
    }
    return (
        <RecoverPassword
        state={recover}
        handleChange={handleRecoverUsername}
        send_recovery_username={send_recovery_username}
        />
    )
}

export default RecoverPasswordContainer

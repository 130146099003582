import React from 'react';

import { useQuery } from 'react-query';

import { getProfile } from 'services/apiSrv';
import Loader from 'components/loader';
import Profile from './Profile';

const ProfileContainer = () => {
	const profile = async () => {
		let resp = await getProfile();
		console.log('THE PROFILE', resp);
		return resp.serviceprovider;
	};
	const { data, isLoading, isSuccess, isError } = useQuery('profile', profile);

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Profile data={data} />}
		</div>
	);
};

export default ProfileContainer;

import React, { useState, createContext } from "react";
// import { useToasts } from "react-toast-notifications";

const KycContext = createContext();
const KycContextProvider = (props) => {
  // states

  const [provider, setProvider] = useState("");

  // Sidebar Menu State
  const [isOpen, setIsOpen] = useState(false);

  // STEPPER CURRENT PAGE states
  const [current, setCurrent] = useState(0);

  const [mapLocation, setMapLocation] = useState("");
  // SOLE PROPRIETOR KYC DETAILS
  const [soleKyc, setSoleKyc] = useState({
    category: provider,
    name: "",
    email: "",
    id_number: "",
    kra_pin: "",
    contact: "",
    license: "",
    address: "",
    postal_code: "",
    location: "",
    description: "",
  });

  // COMPANY KYC DETAILS
  const [companyKyc, setCompanyKyc] = useState({
    name: "",
    email: "",
    certificate_number: "",
    kra_pin: "",
    contact: "",
    address: "",
    postal_code: "",
    location: "",
    description: "",
  });

  // INDIVIDUAL(KYC) UPLOADS
  const [individualUploads, setIndividualUploads] = useState([]);

  // PARTNERSHIP(KYC) UPLOADS
  const [partnersUploads, setPartnersUploads] = useState([]);

  // COMPANY(KYC) UPLOADS
  const [companyUploads, setCompanyUploads] = useState([]);

  // PARTNERSHIP KYC DETAILS
  const [partnershipKyc, setPartnershipKyc] = useState({
    name: "",
    email: "",
    certificate_number: "",
    kra_pin: "",
    contact: "",
    address: "",
    postal_code: "",
    location: "",
    description: "",
  });

  // DIRECTORS(KYC) DETAILS
  const [directors, setDirectors] = useState([]);

  // PARTNERS(KYC) DETAILS
  const [partners, setPartners] = useState([]);

  // JOINT OWNERS(KYC) DETAILS
  const [jointOwners, setJointOwners] = useState([]);

  // METHODS

  function next() {
    const current_step = current + 1;
    setCurrent(current_step);
  }

  function prev() {
    const current_step = current - 1;
    setCurrent(current_step);
  }

  return (
    <KycContext.Provider
      value={{
        provider,
        setProvider,
        next,
        prev,
        current,
        setCurrent,
        isOpen,
        setIsOpen,
        soleKyc,
        setSoleKyc,
        companyKyc,
        setCompanyKyc,
        directors,
        setDirectors,
        partnershipKyc,
        setPartnershipKyc,
        jointOwners,
        setJointOwners,
        partners,
        setPartners,
        individualUploads,
        setIndividualUploads,
        partnersUploads,
        setPartnersUploads,
        companyUploads,
        setCompanyUploads,
        mapLocation,
        setMapLocation,
      }}
    >
      {props.children}
    </KycContext.Provider>
  );
};

const KycConsumer = KycContext.Consumer;
export { KycContextProvider, KycConsumer, KycContext };

import React, { useContext } from 'react';
import AddUserToGroupForm from './AddUserToGroupForm';
import { ModalContext } from '../../../context/ModalContext';
import { openNotificationWithIcon } from '../../notification';
import { add_user_to_group, list_users } from '../../../services/apiSrv';
import { useMutation, useQuery } from 'react-query';

const AddUserToGroupFormContainer = ({ refetch, group_id }) => {
	const { modalClose } = useContext(ModalContext);

	const _listUsers = async () => {
		let resp = await list_users();
		console.log('USERS TO BE ADDED', resp.users);
		return resp.users;
	};

	const { data, isLoading, isSuccess } = useQuery('list, users', _listUsers);

	const _add = async (data) => {
		try {
			let resp = await add_user_to_group(data);
			modalClose();
			openNotificationWithIcon('success', `Success`, 'User added successfully');
		} catch (err) {
			let error = await err;
			modalClose();
			openNotificationWithIcon('error', `Error`, `${error.message}`);
		}
	};

	const mutation = useMutation(add_user_to_group, {
		onSuccess: () => {
			console.log('WE STATED POA');
			refetch();
			modalClose();
			openNotificationWithIcon('success', `Success`, 'User added successfully');
		},
		onError: async (err) => {
			console.log('WE NOTHING');
			let error = await err;
			modalClose();
			openNotificationWithIcon('error', `Error`, `${error.message}`);
		},
	});

	return (
		<AddUserToGroupForm
			_addUserToGroup={mutation}
			users={data}
			isLoading={isLoading}
			isSuccess={isSuccess}
			group_id={group_id}
		/>
	);
};

export default AddUserToGroupFormContainer;

import React,{useContext} from 'react'
import SelectProvider from './SelectProviderType'
import {KycContext} from '../../../context/KycContext'
import {AlertsContext} from '../../../context/AlertsContext'
import {AuthContext} from '../../../context/AuthContext'

const SelectProviderTypeContainer = () => {

    const {provider, setProvider, next} = useContext(KycContext);
    const {setShowAlert}= useContext(AlertsContext)
    const {setAlert,alert}= useContext(AuthContext)

    const onChange = e=> {
        console.log('radio checked', e.target.value);
        setProvider(e.target.value);
      };

      const goNext =()=>{
          if(provider===''){
            setAlert({ ...alert, message:"Please select a category", variant: "danger" });
            setShowAlert(true);

          }
          else{
              next()
          }
      }


    return (
       <SelectProvider
       onChange={onChange}
       goNext={goNext}
       
       />
    )
}

export default SelectProviderTypeContainer

import React, { useContext } from "react";
import LogInForm from "./LogInForm";
import { AuthContext } from "../../../context/AuthContext";
import { AlertsContext } from "../../../context/AlertsContext";
import { useHistory } from "react-router-dom";
import { login, check_kyc } from "../../../services/apiSrv";
import { useMutation } from "react-query";

const LoginFormContainer = () => {
  const history = useHistory();
  const { existingUser, setExistingUser, alert, setAlert } = useContext(
    AuthContext
  );
  const { setShowAlert } = useContext(AlertsContext);



  const check_kyc_status = (kyc_status_resp) => {
    if (kyc_status_resp.serviceprovider.KYC_status === "COMPLETE") {
      history.push("/dashboard");
    } else {
      history.push("/kyc");
    }
  };

  const kycStatus = useMutation(check_kyc, {
    onSuccess: (data) => check_kyc_status(data),
    onError: (error) => {
      setAlert({ ...alert, message: error.message, variant: "danger" });
      setShowAlert(true);
    },
  });
  const mutation = useMutation(login, {
    onSuccess: () => kycStatus.mutate(),
    onError:  async(error) => {
      let err = await error
   setAlert({ ...alert, message: err.message, variant: "danger" });
     
      setShowAlert(true);
    },
  });

  return (
    <LogInForm
      login={mutation}
    />
  );
};

export default LoginFormContainer;

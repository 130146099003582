import React, { useContext, useEffect } from 'react';
import Fees from './Fees';
import { list_fees } from '../../services/apiSrv';
import { useQuery } from 'react-query';
import Loader from '../../components/loader';

const FeesContainer = () => {
	async function _fees() {
		let resp = await await list_fees();
		return resp.entity_fees;
	}
	const { data, isLoading, isSuccess, refetch } = useQuery('list fees', _fees);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Fees data={data} refetch={refetch} />}
		</div>
	);
};

export default FeesContainer;

import React, { useContext } from 'react';
import AddDepartment from './AddRoles';
import { ModalContext } from '../../../context/ModalContext';
import { openNotificationWithIcon } from '../../notification';
import { add_roles } from '../../../services/apiSrv';
import { useMutation } from 'react-query';

const AddRolesFormContainer = ({ refetch }) => {
	const { modalClose } = useContext(ModalContext);

	const mutation = useMutation(add_roles, {
		onSuccess: () => {
			refetch();
			modalClose();
			openNotificationWithIcon('success', `Success`, 'Role added successfully');
		},
		onError: async (err) => {
			let error = await err;
			modalClose();
			openNotificationWithIcon('error', `Error`, `${error.message}`);
		},
	});

	return <AddDepartment _addRoles={mutation} />;
};

export default AddRolesFormContainer;

import React, { useContext } from "react";
import CompanyInfo from "./CompanyInfo";
import { KycContext } from "../../../context/KycContext";

const CompanyInfoContainer = () => {
  const { companyKyc, setCompanyKyc } = useContext(KycContext);

  function handleCompanyKycChange(e) {
    const { name, value } = e.target;
    setCompanyKyc({ ...companyKyc, [name]: value });
  }
  return (
    <CompanyInfo
      handleCompanyKycChange={handleCompanyKycChange}
      state={companyKyc}
    />
  );
};

export default CompanyInfoContainer;

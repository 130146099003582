import React from 'react';
import { addUserGroup } from '../../../shared/utils/formConfig';
import useForm from '../../../shared/customHooks/useForm';

const AddUserGroupForm = ({ _addUserGroup }) => {
	const { renderFormInputs, isFormValid, form } = useForm(addUserGroup);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				_addUserGroup.mutate(form);
			}}
		>
			{renderFormInputs()}

			<button
				className="btn btn-primary btn-block"
				type="submit"
				disabled={!isFormValid() || _addUserGroup.isLoading}
			>
				{_addUserGroup.isLoading ? (
					<span>
						<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
						Please wait...
					</span>
				) : (
					'Add'
				)}
			</button>
		</form>
	);
};

export default AddUserGroupForm;

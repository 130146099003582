import React, { useContext, useState } from "react";
import { DashboardContext } from "../../context/DashboardContext";
import { api_srv } from "../../services";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { message } from "antd";

export default function InvoiceSettings() {
  const { invoiceSettings, setInvoiceSettings, workOrderDetails } = useContext(
    DashboardContext
  );
  const { currency, invoiceNumber, notes } = invoiceSettings;
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const handleChange = (evt) => {
    const value = evt.target.value;
    setInvoiceSettings({
      ...invoiceSettings,
      [evt.target.name]: value,
    });
  };

  const send_invoice = async () => {
    setLoading(true);
    try {


      let resp = await (await api_srv).sendInvoice(
        workOrderDetails.data.underwritter_id,
        workOrderDetails.data.work_order_ref,
        invoiceSettings.lineItems,
        invoiceSettings.taxRate,
        invoiceSettings.currency,
        invoiceSettings.total
      );
      console.log("successfully sent,", resp);
      history.push(routes.invoice);
      setLoading(false);
      message.success("Invoice sent successfully");
    } catch (err) {
      console.log(err)
      let error = await err;
      console.log("error sending invoice", error.err_msg);
      setLoading(false);
      message.error("Error sending Invoice");
    }
  };

  return (
    <div className="container">
      <h5 className="hk-pg-title title-background font-weight-600 mt-40 mb-10 text-center">
        Invoice Settings
      </h5>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          send_invoice();
        }}
      >
        <div className="form-group">
          <label>Invoice Number</label>
          <input
            name="invoiceNumber"
            type="text"
            value={invoiceNumber}
            onChange={handleChange}
            className="form-control"
            placeholder="Invoice Number"
          />
        </div>
        <div className="form-group ">
          <label>Select Currency</label>
          <select
            name="currency"
            value={currency}
            onChange={handleChange}
            className="form-control custom-select d-block w-100"
            id="country"
          >
            <option value>Choose...</option>
            <option value="USD">USD</option>
            <option value="KES">KES</option>
            <option value="TSH">TSH</option>
          </select>
        </div>
        <div className="form-group ">
          <label>Add Notes</label>
          <textarea
            name="notes"
            type="text"
            value={notes}
            onChange={handleChange}
            class="form-control"
            id="exampleFormControlTextarea1"
            placeholder="Notes- any relevant information not already covered"
            rows="4"
          ></textarea>
        </div>

        <button
          type="submit"
          disabled={loading}
          // onClick={() => send_invoice()}
          className="btn btn-success mg-t-25 btn-block text-white"
        >
          {loading && (
            <i
              className="fa fa-circle-notch fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && <span className="text-capitalize">Please wait...</span>}
          {!loading && <span className="text-capitalize"> Send Invoice</span>}
        </button>
      </form>
    </div>
  );
}

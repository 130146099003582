import React, { useContext } from 'react';
import { KycContext } from '../../../context/KycContext';
import * as Icon from 'react-feather';
import { Descriptions, Button } from 'antd';

export default function ConfirmKyc({ loading, submit_kyc }) {
	const { prev, provider, soleKyc, companyKyc, directors, jointOwners, partners } = useContext(KycContext);
	console.log('the kyc details, sole', soleKyc);
	console.log('the kyc details, partners', companyKyc);

	const RenderDetails = () => {
		switch (provider) {
			case 'sole':
				return Object.entries(soleKyc).map(([key, val]) => (
					<Descriptions.Item key={key} label={key}>
						{val}
					</Descriptions.Item>
				));

			case 'partnership':
				return Object.entries(companyKyc).map(([key, val]) => (
					<Descriptions.Item key={key} label={key}>
						{val}
					</Descriptions.Item>
				));

			case 'company':
				return Object.entries(companyKyc).map(([key, val]) => (
					<Descriptions.Item key={key} label={key}>
						{val}
					</Descriptions.Item>
				));

			default:
				break;
		}
	};

	const RenderDirectors = () => {
		switch (provider) {
			case 'sole':
				return jointOwners.map((owner) =>
					Object.entries(owner).map(([key, val]) => (
						<Descriptions.Item key={key} label={key}>
							{val}
						</Descriptions.Item>
					))
				);
			case 'partnership':
				return partners.map((partner) =>
					Object.entries(partner).map(([key, val]) => (
						<Descriptions.Item key={key} label={key}>
							{val}
						</Descriptions.Item>
					))
				);
			case 'company':
				return directors.map((director) =>
					Object.entries(director).map(([key, val]) => (
						<Descriptions.Item key={key} label={key}>
							{val}
						</Descriptions.Item>
					))
				);

			default:
				break;
		}
	};
	return (
		<>
			<p>Step 5</p>

			<h4 className="title-background">Confirm your details</h4>
			<p>
				Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas dolor expedita optio unde
				explicabo nemo? Eius, non.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas dolor
				expedita optio unde explicabo nemo? Eius, non.
			</p>
			<br />
			<span className="float-right">
				{' '}
				<Button>
					<Icon.Edit3 size={16} /> Edit
				</Button>
			</span>
			<Descriptions title="Service Provider Information" layout="horizontal">
				<Descriptions.Item label="Service Provider Type">{provider}</Descriptions.Item>
				{RenderDetails()}
			</Descriptions>
			<br />
			<Descriptions
				title={
					provider === 'sole'
						? 'Joint Owners'
						: provider === 'partnership'
						? 'Partners'
						: provider === 'company'
						? 'Directors'
						: null
				}
				layout="horizontal"
			>
				{RenderDirectors()}
			</Descriptions>
			<button className=" float-right py-1 mb-35 btn btn-primary" onClick={() => submit_kyc()} disabled={loading}>
				{loading && <i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />}
				{loading && <span className="text-capitalize">Finalizing ...</span>}
				{!loading && <span className="text-capitalize"> Submit</span>}
			</button>
			<button className=" float-right btn mb-35 py-1 btn-secondary mr-3" onClick={prev}>
				<Icon.ArrowLeft size={22} className="pr-1" />
				Previous
			</button>
		</>
	);
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import { FcLock } from 'react-icons/fc';

import AddRolesForm from '../forms/addRoles/AddRolesContainer';

const AddRoles = (props) => {
	return (
		<div>
			<Modal
				{...props}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h6 className="">
							<FcLock />
							<span className="pl-2 pt-1">Add a Role</span>
						</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddRolesForm refetch={props.refetch} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddRoles;

import React from 'react';
import Roles from './Roles';
import { list_roles } from '../../services/apiSrv';
import { useQuery } from 'react-query';
import Loader from '../../components/loader';

const RolesContainer = () => {
	const _listRoles = async () => {
		let resp = await list_roles();
		return resp.roles;
	};
	const { data, isLoading, isSuccess, isError, refetch } = useQuery('listRoles', _listRoles);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Roles roles={data} refetch={refetch} />}
		</div>
	);
};

export default RolesContainer;

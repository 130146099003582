import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

// css
import './assets/dist/_libs/animate.css';
import './assets/dist/css/index-style.css';
import './assets/dist/css/style.css';
import 'antd/dist/antd.css';

// Layouts
import routes from './routes';
import PublicLayout from './shared/layout/PublicLayout';
import AuthLayout from './shared/layout/AuthLayout';
import KycLayout from './shared/layout/KycLayout';

// Public pages
import Login from './pages/login/LoginContainer';
import Homepage from './pages/Homepage';
import Signup from './pages/signup/SignupContainer';
import RecoverPassword from './pages/recoverPassword/RecoverPasswordContainer';
import verifyResetToken from './pages/verifyResetToken/VerifyResetTokenContainer';
import NewPassword from './pages/newPassword/NewPasswordContainer';
import VerifyToken from './pages/verifyToken/VerifyTokenContainer';
import NotFound from './pages/Error';

// Authenticated Pages
import Dashboard from './pages/dashboard';
import Account from './pages/account/AccountContainer';
import Kyc from './pages/Kyc';
import Profile from './pages/profile/ProfileContainer';
import Workorders from './pages/workorders/WorkordersContainer';
import ViewWorkorder from './pages/viewWorkorder/ViewWorkorderContainer';
import InProgressRequests from './progressRequests/ProgressRequestsContainer';
import PendingRequests from './pages/PendingRequests';
import Invoice from './pages/invoices/InvoicesContainer';
import CreateInvoice from './pages/createInvoice/CreateInvoiceContainer';
import Credentials from './pages/Credentials';
import Departments from './pages/departments/DepartmentsContainer';
import DepartmentDetails from './pages/departments/DepartmentDetails';
import Roles from './pages/roles/RolesContainer';
import RoleDetails from './pages/roles/RoleDetails';
import Users from './pages/users/UsersContainer';
import UserDetails from './pages/users/UserDetails';
import UserGroup from './pages/userGroups/UserGroupContainer';
import UserGroupDetails from './pages/userGroups/UserGroupDetails';
import SecurityContext from './pages/securityContext/SecurityContextContainer';
import SecurityContextDetails from './pages/securityContext/SecurityContextDetails';
import Taxes from './pages/taxes/TaxesContainer';
import Fees from './pages/fees/FeesContainer';
import FeesDetails from './pages/fees/FeeDetails';
import TaxDetails from './pages/taxes/TaxDetails';
import ServiceCenter from './pages/serviceCenter/ServiceCenterContainer';

// settlement
import Settlement from './pages/settlement/settlement/SettlementContainer';
import SettlementDetails from './pages/settlement/settlement/SettlementDetails';
import SettlementConfig from './pages/settlement/config/ConfigContainer';
import ConfigDetails from './pages/settlement/config/ConfigDetails';
import SettlementChannel from './pages/settlement/channel/ChannelContainer';
import ChannelDetails from './pages/settlement/channel/ChannelDetails';

import Chat from 'pages/chat/ChatContainer';

const pages = [
	{
		exact: true,
		path: routes.feeDetails,
		component: FeesDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.fees,
		component: Fees,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.taxDetails,
		component: TaxDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.taxes,
		component: Taxes,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.viewDepartment,
		component: DepartmentDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.viewRole,
		component: RoleDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.viewUser,
		component: UserDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.viewUserGroup,
		component: UserGroupDetails,
		layout: AuthLayout,
	},

	{
		exact: true,
		path: routes.login,
		component: Login,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.home,
		component: Homepage,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.signup,
		component: Signup,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.recover,
		component: RecoverPassword,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.verify,
		component: verifyResetToken,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.credentials,
		component: Credentials,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.newPassword,
		component: NewPassword,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.verifyToken,
		component: VerifyToken,
		layout: PublicLayout,
	},
	{
		exact: true,
		path: routes.workorders,
		component: Workorders,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.dashboard,
		component: Dashboard,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.chat,
		component: Chat,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.users,
		component: Users,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.createInvoice,
		component: CreateInvoice,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.invoice,
		component: Invoice,
		layout: AuthLayout,
	},

	{
		exact: true,
		path: routes.settlementChannelDetails,
		component: ChannelDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.settlementChannel,
		component: SettlementChannel,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.settlementConfigDetails,
		component: ConfigDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.settlementConfig,
		component: SettlementConfig,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.settlementDetails,
		component: SettlementDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.settlement,
		component: Settlement,
		layout: AuthLayout,
	},

	{
		exact: true,
		path: routes.account,
		component: Account,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.serviceCenter,
		component: ServiceCenter,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.kyc,
		component: Kyc,
		layout: KycLayout,
	},
	{
		exact: true,
		path: routes.departments,
		component: Departments,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.roles,
		component: Roles,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.userGroups,
		component: UserGroup,
		layout: AuthLayout,
	},

	{
		exact: true,
		path: routes.profile,
		component: Profile,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.viewworkorder,
		component: ViewWorkorder,
		layout: AuthLayout,
	},

	{
		exact: true,
		path: routes.inProgressRequests,
		component: InProgressRequests,
		layout: AuthLayout,
	},

	{
		exact: true,
		path: routes.pendingRequests,
		component: PendingRequests,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.viewSecurityContext,
		component: SecurityContextDetails,
		layout: AuthLayout,
	},
	{
		exact: true,
		path: routes.securityContext,
		component: SecurityContext,
		layout: AuthLayout,
	},
];

function App() {
	const history = useHistory();
	return (
		<div className="App">
			<Router history={history}>
				<ToastProvider>
					<Switch>
						{pages.map(({ exact, path, component: Component, layout: Layout }, index) => (
							<Route
								key={index}
								exact={exact}
								path={path}
								render={(props) => (
									<Layout history={history}>
										<Component {...props} />
									</Layout>
								)}
							/>
						))}

						<Route component={NotFound} />
					</Switch>
				</ToastProvider>
			</Router>
		</div>
	);
}

export default App;

import React, { useContext } from "react";
import { Tab, Nav } from "react-bootstrap";
import * as Icon from "react-feather";
import bg from "../../assets/dist/img/tickets-bg.jpg";
import { GiWallet } from "react-icons/gi";
import Transactions from "../../components/modals/Transactions";
import { ModalContext } from "../../context/ModalContext";

const Account = ({ state }) => {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      {/* Container */}

      <Transactions
        show={modalShow === "transactions" ? true : false}
        onHide={modalClose}
        // get_workorder_details={get_workorder_details}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 pa-0">
            <div className="profile-cover-wrap overlay-wrap">
              <div
                className="profile-cover-img"
                style={{ backgroundImage: `url(${bg})` }}
              />
              <div className="bg-overlay bg-trans-dark-60" />
              <div className="container profile-cover-content py-30">
                <div className="text-white text-capitalize display-6 mb-5 font-weight-400">
                  <GiWallet className="mr-2" />
                  My Accounts
                </div>
              </div>
            </div>
            <div className="mt-5 container">
              <div className="card rounded-10 mg-b-20">
                <div className="collapse show" id="transactionsHistory">
                  <div className="card-body pd-x-0 pd-y-0">
                    <Nav className="m-0">
                      <ul className="list-group list-group-flush w-100">
                        {state.map((account) => {
                          return (
                            <li
                              className="list-group-item d-flex pd-y-10 pd-sm-x-15 "
                              key={account.account_number}
                            >
                              <div className="d-none d-sm-block">
                                <GiWallet className="mr-2" size={24} />
                              </div>
                              <div className="pd-sm-l-10">
                                <p className="tx-dark mg-b-0">
                                  {account.account_name} -{" "}
                                  {account.account_type}
                                </p>
                                <span className="mg-b-0 tx-gray-500">
                                  Account Number: {account.account_number}
                                </span>
                              </div>
                              <div className="ml-auto">
                                <h6 className="tx-dark mg-b-0">
                                  Bal:{account.currency_code}{" "}
                                  {account.available_balance}
                                </h6>
                                <p>
                                  <a
                                    href
                                    onClick={() => setModalShow("transactions")}
                                  >
                                    View Transactions
                                  </a>
                                </p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </Nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;

import React, { useContext } from "react";
import "./map.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { DashboardContext } from "../../context/DashboardContext";
import { KycContext } from "../../context/KycContext";
import * as Icon from "react-feather";

const Search = ({ panTo }) => {
  const { coordinates, setCoordinates } = useContext(DashboardContext);
  const { mapLocation, setMapLocation, companyKyc, setCompanyKyc } = useContext(
    KycContext
  );

  const handleChange = (address) => {
    setMapLocation(address);
  };

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      // get the lat and lng of the  result user has selected
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({ ...coordinates, lat: lat, lng: lng });
      setCompanyKyc({ ...companyKyc, location: value });
      setMapLocation(value);

      panTo({ lat, lng });
    } catch (error) {}
  };

  return (
    <div className="canvas">
      <PlacesAutocomplete
        value={mapLocation}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search">
            <input
              {...getInputProps({
                placeholder: "Search Location ...",
                className: "location-search-input ",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#f5f5f5", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    className="input-suggestion"
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    <span>
                      <Icon.MapPin size={12} className="mr-2" />{" "}
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default Search;

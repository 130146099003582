import React from 'react';
import Departments from './Departments';
import { list_departments } from '../../services/apiSrv';
import { useQuery } from 'react-query';
import Loader from '../../components/loader';

const DepartmentsContainer = () => {
	const _listDepartments = async () => {
		let resp = await list_departments();
		console.log('THE DEP LIST', resp);
		return resp.departments;
	};
	const { data, isLoading, isSuccess, isError, refetch } = useQuery('listDepartments', _listDepartments);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Departments departments={data} refetch={refetch} />}
		</div>
	);
};

export default DepartmentsContainer;

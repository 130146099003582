import React from 'react';
import { GrCompliance } from 'react-icons/gr';
import bg from '../../../assets/dist/img/tickets-bg.jpg';
import { Tabs, Descriptions } from 'antd';

const SettlementDetails = () => {
	const { TabPane } = Tabs;
	return (
		<div className="hk-wrapper hk-vertical-nav">
			<div className="hk-pg-wrpper">
				{/* Container */}
				<div className="container-fluid">
					{/* Row */}
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="profile-cover-wrap overlay-wrap">
								<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
								<div className="bg-overlay bg-trans-dark-60" />
								<div className="container profile-cover-content py-30"></div>
							</div>
							<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
								<div className="container">
									<div className="card">
										<div className="card-body">
											<div className="d-flex justify-content-between mb-4">
												<div>
													<h5>
														<span className="mr-2">
															<GrCompliance />
														</span>
														Settlement Title
													</h5>
												</div>
											</div>
											<Tabs defaultActiveKey="1" type="card">
												<TabPane tab="Settlement Details" key="1">
													<Descriptions title="Settlement Info" size="default">
														<Descriptions.Item label="Account">name</Descriptions.Item>

														<Descriptions.Item label="Reference">ref123</Descriptions.Item>
														<Descriptions.Item label="Amount">10000</Descriptions.Item>
														<Descriptions.Item label="Status">Active</Descriptions.Item>
														<Descriptions.Item label="Channel">Bank</Descriptions.Item>
														<Descriptions.Item label="Transaction Ref">
															<a href>retette</a>
														</Descriptions.Item>
														<Descriptions.Item label="Created by">
															<ul>
																<li>date: 12/05/2021</li>
																<li>name: RBNJR</li>
															</ul>
														</Descriptions.Item>
													</Descriptions>
												</TabPane>
											</Tabs>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* /Row */}
				</div>
				{/* /Container */}
				{/* Footer */}

				{/* /Footer */}
			</div>
			{/* /Main Content */}
		</div>
	);
};

export default SettlementDetails;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { AlertsContext } from '../../../context/AlertsContext';
import Alert from '../../alerts/WarningAlert';
import { loginForm } from '../../../shared/utils/formConfig';
import useForm from '../../../shared/customHooks/useForm';

const LogInForm = ({ login }) => {
	const { alert } = useContext(AuthContext);
	const { showAlert } = useContext(AlertsContext);
	const { message, variant } = alert;

	const { renderFormInputs, isFormValid, form } = useForm(loginForm);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				login.mutate(form);
			}}
		>
			<h1 className="display-4 mb-10">Welcome</h1>
			<p className="mb-30">Sign in to your account</p>
			{showAlert && <Alert message={message} variant={variant} />}
			{renderFormInputs()}

			<p className="text-right">
				Forgot Password? <Link to="/reset">Reset</Link>
			</p>
			<button className="btn btn-primary btn-block" type="submit" disabled={!isFormValid() || login.isLoading}>
				{login.isLoading ? (
					<span>
						<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
						Please wait...
					</span>
				) : (
					'Login'
				)}
			</button>
			<div className="option-sep">or</div>
			<p className="text-center">
				Don't have an account? <Link to="/register">Signup</Link>
			</p>
		</form>
	);
};

export default LogInForm;

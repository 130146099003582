import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { fees_details, calculateFee } from '../../services/apiSrv';
import { Tabs, Descriptions, Form, InputNumber, Radio, Button } from 'antd';
import { FcDocument } from 'react-icons/fc';
import Loader from 'components/loader';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Moment from 'react-moment';
import { openNotificationWithIcon } from 'components/notification';

const FeeDetails = () => {
	const { id } = useParams();
	const [form] = Form.useForm();
	const { TabPane } = Tabs;

	const [calculations, setCalculations] = useState(null);

	async function _feesDetails() {
		let resp = await await fees_details(id);
		console.log('the fees details', resp);
		return resp.fee_details;
	}
	const { data, isLoading, isSuccess } = useQuery('fee detail', _feesDetails);

	const calculate = useMutation(calculateFee, {
		onSuccess: (data) => {
			setCalculations(data.tax_calculation);
		},
		onError: async (error) => {
			let err = await error;
			openNotificationWithIcon('error', `Error`, `${err.message}`);
		},
	});
	const onFinish = async (values) => {
		let system_fee_ref = data.system_fee_ref;
		let modValues = { ...values, system_fee_ref };
		console.log('MODIFIED VALUES', modValues);
		calculate.mutate(modValues);
	};

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div className="hk-wrapper hk-vertical-nav">
					<div className="hk-pg-wrpper">
						{/* Container */}
						<div className="container-fluid">
							{/* Row */}
							<div className="row">
								<div className="col-xl-12 pa-0">
									<div className="profile-cover-wrap overlay-wrap">
										<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
										<div className="bg-overlay bg-trans-dark-60" />
										<div className="container profile-cover-content py-30"></div>
									</div>
									<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
										<div className="container">
											<div className="card">
												<div className="card-body">
													<div className="d-flex justify-content-between mb-4">
														<div>
															<h5>
																<span className="mr-2">
																	<FcDocument />
																</span>
																{data.fee_name}
															</h5>
														</div>
													</div>
													<Tabs defaultActiveKey="1" type="card">
														<TabPane tab="Fee Details" key="1">
															<Descriptions title="Fee Info" column={2}>
																<Descriptions.Item label="Fee Name">
																	{data.fee_name}
																</Descriptions.Item>

																<Descriptions.Item label="Fee Type">
																	{data.fee_type}
																</Descriptions.Item>
																<Descriptions.Item label="Fee Value">
																	{data.fee_value}
																</Descriptions.Item>
																<Descriptions.Item label="Created Date">
																	<Moment format="DD-MMM-YYYY HH:MM:a">
																		{data.created_date}
																	</Moment>
																</Descriptions.Item>
																<Descriptions.Item label="Fee Account">
																	{data.entity_fee_account}
																</Descriptions.Item>
																<Descriptions.Item label="Fee Reference">
																	{data.entity_fee_ref}
																</Descriptions.Item>
																<Descriptions.Item label="Fee Management">
																	{data.fee_management}
																</Descriptions.Item>
																<Descriptions.Item label="Fee Status">
																	{data.status}
																</Descriptions.Item>
																<Descriptions.Item label="Currency">
																	{data.entity_fee_currency}
																</Descriptions.Item>
																<Descriptions.Item label="Description">
																	{data.fee_description}
																</Descriptions.Item>
															</Descriptions>
														</TabPane>
														<TabPane tab="Calculate" key="2">
															<div className="row">
																<div className="col-md-3 border-right">
																	<h6 className="text-center">Calculate Fee</h6>
																	<Form
																		form={form}
																		layout="vertical"
																		onFinish={onFinish}
																	>
																		<Form.Item
																			label="Enter Amount"
																			name="amount"
																			rules={[
																				{
																					required: true,
																					message: 'Amount is required',
																				},
																			]}
																		>
																			<InputNumber
																				placeholder=""
																				style={{ width: '100%' }}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Button
																				type="primary"
																				htmlType="submit"
																				className="text-white"
																				block
																				disabled={calculate.isLoading}
																			>
																				{calculate.isLoading ? (
																					<span>
																						<i
																							className="fa fa-circle-notch fa-spin"
																							style={{
																								marginRight: '5px',
																							}}
																						/>
																						Please wait...
																					</span>
																				) : (
																					'Calculate'
																				)}
																			</Button>
																		</Form.Item>
																	</Form>
																</div>
																<div className="col-md-9">
																	{calculations === null ? (
																		'Enter a value and press calculate Button'
																	) : (
																		<Descriptions title="Results" column={2}>
																			<Descriptions.Item label="Fee Type">
																				{calculations.tax_type}
																			</Descriptions.Item>

																			<Descriptions.Item label="Gross Amount">
																				{calculations.gross_amount}
																			</Descriptions.Item>
																			<Descriptions.Item label="Fee Value">
																				{calculations.tax_value}
																			</Descriptions.Item>
																			<Descriptions.Item label="Net Amount">
																				{calculations.net_amount}
																			</Descriptions.Item>
																			<Descriptions.Item label="Fee Amount">
																				{calculations.tax_amount}
																			</Descriptions.Item>
																		</Descriptions>
																	)}
																</div>
															</div>
														</TabPane>
													</Tabs>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* /Row */}
						</div>
						{/* /Container */}
						{/* Footer */}

						{/* /Footer */}
					</div>
					{/* /Main Content */}
				</div>
			)}
		</>
	);
};

export default FeeDetails;

import React, { useContext } from 'react';
import Config from './Config';
import { createConfig, fetchAccounts } from 'services/apiSrv';
import { useMutation, useQuery } from 'react-query';
import Loader from 'components/loader';
import { ModalContext } from 'context/ModalContext';
import { openNotificationWithIcon } from 'components/notification';

const ConfigLogic = ({ refetch }) => {
	const { modalClose } = useContext(ModalContext);
	const listAcc = async () => {
		let resp = await fetchAccounts();
		console.log('ACCOUNTS', resp);
		return resp.entity_accounts;
	};

	const create = useMutation(createConfig, {
		onSuccess: () => {
			refetch();
			modalClose();
			openNotificationWithIcon('success', `Success`, 'Configuration added successfully');
		},
		onError: async (err) => {
			let error = await err;
			modalClose();
			openNotificationWithIcon('error', `Error`, `${error.message}`);
		},
	});

	const { data, isLoading, isSuccess } = useQuery('list accounts', listAcc);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Config accounts={data} create={create} />}
		</div>
	);
};

export default ConfigLogic;

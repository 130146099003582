/* eslint-disable react/destructuring-assignment */
import React, { useState, createContext } from "react";

const ModalContext = createContext();
const ModalContextProvider = (props) => {
  const [modalShow, setModalShow] = useState("");
  const [modalShowReport, setModalShowReport] = useState(false);

  const modalClose = () => {
    setModalShow(false);
  };
  const modalCloseReport = () => {
    setModalShow(false);
  };

  return (
    <ModalContext.Provider
      value={{
        modalShow,
        setModalShow,
        modalClose,
        modalCloseReport,
        modalShowReport,
        setModalShowReport,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

const ModalConsumer = ModalContext.Consumer;
export { ModalContextProvider, ModalConsumer, ModalContext };

import React from 'react'
import ProgressRequests from './ProgressRequests'

const ProgressRequestsContainer = () => {
    return (
        <div>
           <ProgressRequests/> 
        </div>
    )
}

export default ProgressRequestsContainer

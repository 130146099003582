import React, { useContext} from "react";
import * as Yup from "yup";
import { KycContext } from "../../context/KycContext";
import { ModalContext } from "../../context/ModalContext";
import { Formik, Form, ErrorMessage } from "formik";


const Director_form = () => {
  const { directors, handleDirectorsChange } = useContext(KycContext);
  const { setModalShow} = useContext(ModalContext);

  const submit=()=>{
      console.log("submitted another director")
  }
  return (
    <Formik
      enableReinitialize
      initialValues={directors}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Email is required"),
        id_number: Yup.string().required("Identification Number is required"),
        kra_pin: Yup.string().required("KRA Number is required"),
        contact: Yup.string().required("Contact is required"),
        residency: Yup.string().required("Select a residency"),
        address: Yup.string().required("An address is required"),
        postal_code: Yup.string().required("Select a postal code"),
        director_type: Yup.string().required("Select type of director"),
        passport: Yup.string().when("residency", {
          is: (value) => value === "foreigner",
          then: Yup.string().required("Passport Number is required"),
          otherwise: Yup.string(),
        }),
      })}
      onSubmit={() => submit()}
    >
      {({ errors, touched }) => (
        <Form className="">
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Name of Director</label>
                <input
                  className={
                    "form-control" +
                    (errors.name && touched.name ? " is-invalid" : "")
                  }
                  placeholder="Enter your name"
                  name="name"
                  value={directors.name}
                  onChange={handleDirectorsChange}
                  type="text"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Email</label>
                <input
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                  placeholder="example@email.com"
                  name="email"
                  value={directors.email}
                  onChange={handleDirectorsChange}
                  type="email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">ID Number/Alien ID </label>
                <input
                  className={
                    "form-control" +
                    (errors.id_number && touched.id_number ? " is-invalid" : "")
                  }
                  name="id_number"
                  value={directors.id_number}
                  onChange={handleDirectorsChange}
                  placeholder="1234567"
                  type="text"
                />
                <ErrorMessage
                  name="id_number"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="contact">Phone Number</label>
                <input
                  className={
                    "form-control" +
                    (errors.contact && touched.contact ? " is-invalid" : "")
                  }
                  placeholder="0702123456"
                  name="contact"
                  value={directors.contact}
                  onChange={handleDirectorsChange}
                  type="text"
                />
                <ErrorMessage
                  name="contact"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="kra_pin">KRA Pin</label>
                <input
                  className={
                    "form-control" +
                    (errors.kra_pin && touched.kra_pin ? " is-invalid" : "")
                  }
                  placeholder="A1234567T"
                  name="kra_pin"
                  value={directors.kra_pin}
                  onChange={handleDirectorsChange}
                  type="text"
                />
                <ErrorMessage
                  name="kra_pin"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Postal Address</label>
                <input
                  className={
                    "form-control" +
                    (errors.address && touched.address ? " is-invalid" : "")
                  }
                  placeholder="1234"
                  type="text"
                  name="address"
                  value={directors.address}
                  onChange={handleDirectorsChange}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="postal_code">Postal Code</label>
                <select
                  className={
                    "form-control" +
                    (errors.postal_code && touched.postal_code
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Select Code"
                  name="postal_code"
                  value={directors.code}
                  onChange={handleDirectorsChange}
                >
                  <option value="">Select Code</option>
                  <option value="00100">00100</option>
                  <option value="80100">80100</option>
                  <option value="40100">40100</option>
                </select>
                <ErrorMessage
                  name="postal_code"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Residency</label>
                <select
                  className={
                    "form-control" +
                    (errors.residency && touched.residency ? " is-invalid" : "")
                  }
                  placeholder="Select A residency"
                  name="residency"
                  value={directors.residency}
                  onChange={handleDirectorsChange}
                >
                  <option value="">Select A residency</option>
                  <option value="resident">Local Resident</option>
                  <option value="foreigner">Foreigner</option>
                </select>
                <ErrorMessage
                  name="residency"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Director Type</label>
                <select
                  className={
                    "form-control" +
                    (errors.director_type && touched.director_type
                      ? " is-invalid"
                      : "")
                  }
                  name="director_type"
                  value={directors.director_type}
                  onChange={handleDirectorsChange}
                >
                  <option value="">Select A Type</option>
                  <option value="executive">Executive</option>
                  <option value="non-executive">Non Executive</option>
                </select>
                <ErrorMessage
                  name="director_type"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">
                  Passport Number{" "}
                  <small className="text-danger">
                    {" "}
                    *compulsory for non-resident directors
                  </small>
                </label>
                <input
                  className={
                    "form-control" +
                    (errors.passport && touched.passport ? " is-invalid" : "")
                  }
                  placeholder=""
                  name="passport"
                  value={directors.passport}
                  onChange={handleDirectorsChange}
                  type="text"
                />
                <ErrorMessage
                  name="passport"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

          </div>
          <button
        className=" float-right mb-45 mt-30 py-1 btn btn-success"
        type="submit"
      >
        Submit
      </button>
      <button
      onClick={()=>setModalShow(false)}
        className=" float-right btn py-1 mb-45 mt-30 btn-danger mr-3">
        Cancel
      </button>
        </Form>
      )}
    </Formik>
  );
};
export default Director_form;

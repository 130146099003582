import React, { useContext } from "react";
import MemoForm from "./MemoForm";
import { DashboardContext } from "../../../context/DashboardContext";
import { ModalContext } from "../../../context/ModalContext";
import { api_srv } from "../../../services";
import {message} from 'antd'

const MemoFormContainer = () => {
  // sendReport(sp_workOrder_id,name,url)
  // title: "",
  // doc: [],
  // mode: "",

  const { memo, setMemo,workOrderDetails } = useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);

  const handleMemoChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
      setMemo({
      ...memo,
      [evt.target.name]: value,
    });
  };

  // const handleUpload =(evt)=>{
  //   setReportPref({
  //     ...reportPref, doc: evt.target.files[0]
  //   })
  // }

  // const upload_report = async () => {
  //   setReportPref({ ...reportPref, loading: true });
  //   try {
  //     let resp = await (await api_srv).upload_api(
  //       reportPref.title,
  //       reportPref.doc
  //     );
  //     await setReportPref((reportPref) => ({ url: resp }));
  //     // sendReport();
  //     setReportPref({ ...reportPref, loading: false });
  //   } catch (err) {
  //     let error = await err;
  //     console.log("upload report error", error);
  //     setReportPref({ ...reportPref, loading: false });
  //   }
  // };

  const send_memo = async () => {
    setMemo({...memo, loading:true})
    try {
      let resp = await (await api_srv).sendMemo(workOrderDetails.data.sp_workOrder_id, memo)
      console.log("the response of sending memo", resp);
      modalClose()
      message.success(`${resp.status} Memo Sent`);
      setMemo({...memo, loading:false})

    } catch (err) {
      let error = await err;
      console.log("error occured in sending memo", error);
      setMemo({...memo, loading:false})
      modalClose()
      message.error(`${error.message} Memo failed to send`);
    }
  };
  return (
    <div>
      <MemoForm
        handleMemoChange ={handleMemoChange }
        send_memo={send_memo}
        state={memo}
        // handleUpload={handleUpload}
      />
    </div>
  );
};

export default MemoFormContainer;

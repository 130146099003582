import React, { useContext, useState } from 'react';
import { Avatar, notification } from 'antd';
import Avatar12 from '../../assets/dist/img/avatar12.jpg';
import Avatar1 from '../../assets/dist/img/avatar1.jpg';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { DashboardContext } from '../../context/DashboardContext';
import Moment from 'react-moment';
import routes from '../../routes';
import logo from '../../assets/dist/img/Logo_1.png';

export default function DashboardNavbar() {
	const [searchOpen, setSearchOpen] = useState(false);
	const { notifications } = useContext(DashboardContext);

	return (
		<div>
			<nav className="navbar navbar-expand-xl navbar-dark  hk-navbar hk-navbar-alt">
				<a
					className="navbar-toggle-btn nav-link-hover navbar-toggler"
					href
					data-toggle="collapse"
					data-target="#navbarCollapseAlt"
					aria-controls="navbarCollapseAlt"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="feather-icon">
						<Icon.Menu color="red" />
					</span>
				</a>
				<Link className="navbar-brand font-weight-700" to="/">
					<img src={logo} alt="Logo" style={{ width: 'auto', height: '25px' }} />
				</Link>
				<div className="collapse navbar-collapse" id="navbarCollapseAlt">
					<ul className="navbar-nav">
						<li className="nav-item show-on-hover active">
							<Link className="nav-link " to={routes.dashboard}>
								Dashboard
							</Link>
						</li>

						<li className="nav-item dropdown show-on-hover">
							<Link
								className="nav-link dropdown-toggle"
								href
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								User Management
							</Link>
							<div className="dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
								<div className="sub-dropdown-menu show-on-hover">
									<Link to={routes.departments} className=" dropdown-item">
										Departments
									</Link>
									<Link to={routes.roles} className=" dropdown-item">
										Roles
									</Link>
									<Link to={routes.users} className=" dropdown-item">
										Users
									</Link>
									<Link to={routes.userGroups} className=" dropdown-item">
										User Groups
									</Link>
									<Link to={routes.securityContext} className=" dropdown-item">
										Security Context
									</Link>
								</div>
							</div>
						</li>
						<li className="nav-item ">
							<Link className="nav-link" to={routes.workorders}>
								Work Orders
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={routes.account}>
								Account
							</Link>
						</li>
						<li className="nav-item dropdown show-on-hover">
							<Link
								className="nav-link dropdown-toggle"
								href
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								Settlement
							</Link>
							<div className="dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
								<div className="sub-dropdown-menu show-on-hover">
									<Link to={routes.settlement} className=" dropdown-item">
										Settlements
									</Link>
									<Link to={routes.settlementConfig} className=" dropdown-item">
										Settlement Configurations
									</Link>
									<Link to={routes.settlementChannel} className=" dropdown-item">
										Settlement Channels
									</Link>
								</div>
							</div>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={routes.invoice}>
								Invoices
							</Link>
						</li>
						{/* <li className="nav-item dropdown show-on-hover">
                <Link className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Invoices
                </Link>
                <div className="dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                  <div className="sub-dropdown-menu show-on-hover">
                    <Link to={routes.invoice} className=" dropdown-item tx-16 ">My Invoices</Link>
                    <Link to="" className=" dropdown-item tx-16 ">Credit Notes</Link>
                  </div>
                </div>       
              </li> */}

						<li className="nav-item">
							<Link className="nav-link" href>
								Reports
							</Link>
						</li>
						<li className="nav-item dropdown show-on-hover">
							<Link
								className="nav-link dropdown-toggle"
								href
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								Taxes and Fees
							</Link>
							<div className="dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
								<div className="sub-dropdown-menu show-on-hover">
									<Link to={routes.taxes} className=" dropdown-item">
										Taxes
									</Link>
									<Link to={routes.fees} className=" dropdown-item">
										Fees
									</Link>
								</div>
							</div>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={routes.serviceCenter}>
								Service Center
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={routes.chat}>
								Chat
							</Link>
						</li>
					</ul>
				</div>
				<ul className="navbar-nav hk-navbar-content">
					{/* <li class="nav-item">
                    <a id="navbar_search_btn" class="nav-link nav-link-hover" href="javascript:void(0);"><span class="feather-icon"><Icon.Search onClick={() => setSearchOpen(!searchOpen)}/></span></a>
                </li> */}
					<li className="nav-item dropdown dropdown-notifications">
						<a
							className="nav-link dropdown-toggle no-caret"
							href
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<span className="feather-icon">
								<Icon.Bell />
							</span>
							<span className="badge-wrap">
								<span className="badge badge-primary badge-indicator badge-indicator-sm badge-pill pulse" />
							</span>
						</a>
						<div
							className="dropdown-menu dropdown-menu-right"
							data-dropdown-in="fadeIn"
							data-dropdown-out="fadeOut"
						>
							<h6 className="dropdown-header">
								Notifications{' '}
								<a href className>
									View all
								</a>
							</h6>
							<div className="notifications-nicescroll-bar">
								{notifications.data === ''
									? null
									: notifications.data.map((notification, i) => (
											<>
												<a href className="dropdown-item" key={i}>
													<div className="media">
														<div className="media-img-wrap">
															<div className="avatar avatar-sm">
																<img
																	src={Avatar1}
																	alt="user"
																	className="avatar-img rounded-circle"
																/>
															</div>
														</div>
														<div className="media-body">
															<span className="text-dark text-capitalize">
																{notification.subject}
															</span>
															<div>
																<div className="notifications-text">
																	<span className="text-capitalize">
																		{notification.notification}
																	</span>{' '}
																</div>
																<div className="notifications-time">
																	<Moment fromNow>
																		{notification.notification_date}
																	</Moment>{' '}
																</div>
															</div>
														</div>
													</div>
												</a>
												<div className="dropdown-divider" />
											</>
									  ))}
							</div>
						</div>
					</li>
					<li className="nav-item dropdown dropdown-authentication">
						<a
							className="nav-link dropdown-toggle no-caret"
							href
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<div className="media">
								<div className="media-img-wrap">
									<div className="avatar">
										<img src={Avatar12} alt="user" className="avatar-img rounded-circle" />
									</div>
									<span className="badge badge-success badge-indicator" />
								</div>
								<div className="media-body">
									<span>
										Robert Njoroge
										<i className="zmdi zmdi-chevron-down" />
									</span>
								</div>
							</div>
						</a>
						<div
							className="dropdown-menu dropdown-menu-right"
							data-dropdown-in="flipInX"
							data-dropdown-out="flipOutX"
						>
							<Link className="dropdown-item" to={routes.profile}>
								<i className="dropdown-icon zmdi zmdi-account" />
								<span>Profile</span>
							</Link>
							<a className="dropdown-item" href>
								<i className="dropdown-icon zmdi zmdi-card" />
								<span>My balance</span>
							</a>
							<a className="dropdown-item" href="inbox.html">
								<i className="dropdown-icon zmdi zmdi-email" />
								<span>Inbox</span>
							</a>
							<a className="dropdown-item" href>
								<i className="dropdown-icon zmdi zmdi-settings" />
								<span>Settings</span>
							</a>
							<div className="dropdown-divider" />
							<div className="sub-dropdown-menu show-on-hover">
								<a href className="dropdown-toggle dropdown-item no-caret">
									<i className="zmdi zmdi-check text-success" />
									Online
								</a>
								<div className="dropdown-menu open-left-side">
									<a className="dropdown-item" href>
										<i className="dropdown-icon zmdi zmdi-check text-success" />
										<span>Online</span>
									</a>
									<a className="dropdown-item" href>
										<i className="dropdown-icon zmdi zmdi-circle-o text-warning" />
										<span>Busy</span>
									</a>
									<a className="dropdown-item" href>
										<i className="dropdown-icon zmdi zmdi-minus-circle-outline text-danger" />
										<span>Offline</span>
									</a>
								</div>
							</div>
							<div className="dropdown-divider" />
							<a className="dropdown-item" href>
								<i className="dropdown-icon zmdi zmdi-power" />
								<span>Log out</span>
							</a>
						</div>
					</li>
				</ul>
			</nav>
			{searchOpen && (
				<form role="search" className="navbar-search">
					<div className="position-relative">
						<a href className="navbar-search-icon">
							<span className="feather-icon">
								<Icon.Search />
							</span>
						</a>
						<input
							type="text"
							name="example-input1-group2"
							className="form-control"
							placeholder="Type here to Search"
						/>
						<a id="navbar_search_close" className="navbar-search-close" href>
							<span className="feather-icon">
								<Icon.X onClick={() => setSearchOpen(false)} />
							</span>
						</a>
					</div>
				</form>
			)}
		</div>
	);
}

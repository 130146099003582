import React, { useContext } from 'react';
import { Input, Empty } from 'antd';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Moment from 'react-moment';
import * as Icon from 'react-feather';
import { FcClock, FcCancel, FcPaid } from 'react-icons/fc';
import ViewInvoice from '../../components/modals/ViewInvoice';
import { ModalContext } from '../../context/ModalContext';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

export default function Invoices({ data, view_invoice }) {
	const { Search } = Input;
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	return (
		<div className="hk-wrapper hk-vertical-nav">
			{/* Container */}
			<ViewInvoice
				show={modalShow === 'viewInvoice' ? true : false}
				onHide={modalClose}
				// get_workorder_details={get_workorder_details}
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 pa-0">
						<div className="profile-cover-wrap overlay-wrap">
							<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
							<div className="bg-overlay bg-trans-dark-60" />
							<div className="container profile-cover-content py-30">
								<div className="text-white text-capitalize display-6 mb-5 font-weight-400">
									<Icon.FileText className="mr-2" />
									My Invoices
								</div>
							</div>
						</div>
						<div className="bg-white shadow-bottom">
							<div className="container">
								<Search
									className="float-right"
									placeholder="Search an invoice"
									onSearch={(value) => console.log(value)}
									style={{ maxWidth: 250, marginTop: '2em' }}
								/>
								<Tabs defaultActiveKey="1" size="large" className="clearfix">
									<TabPane
										tab={
											<span>
												<FcClock className="mr-1" />
												Pending
											</span>
										}
										key="1"
									>
										{data.filter((invoice) => {
											return invoice.status === 'PENDING';
										}).length === 0 ? (
											<Empty description="You have no pending invoices" />
										) : (
											<div className="fmapp-table-view">
												<table id="fmapp_table_view" className="table table-hover w-100">
													<thead>
														<tr>
															<th>Underwriter</th>
															<th>Date</th>
															<th>Transaction Number </th>
															<th>Status </th>
															<th>Total Amount</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{data
															.filter((invoice) => {
																console.log('ZE INVOICE', invoice);
																return invoice.status === 'PENDING';
															})
															.map((invoice) => (
																<tr key={invoice.sp_invoice_id}>
																	<td>{invoice.underwriter_name}</td>

																	<td>
																		<span className="mr-10">
																			<Moment format="DD/MM/YYYY">
																				{invoice.created_date}
																			</Moment>
																		</span>
																		<span className="d-block">
																			<Moment format="hh:mm:ss">
																				<small>{invoice.created_date}</small>
																			</Moment>
																		</span>
																	</td>
																	<td>{invoice.transaction_no}</td>

																	<td>{invoice.status}</td>
																	<td>
																		{invoice.currency} {invoice.total_amount}
																	</td>
																	<td>
																		<a
																			href
																			onClick={() => {
																				sessionStorage.setItem(
																					'invoice_id',
																					invoice.sp_invoice_id
																				);
																				setModalShow('viewInvoice');
																			}}
																		>
																			view
																		</a>
																	</td>
																</tr>
															))}
													</tbody>
												</table>
											</div>
										)}
									</TabPane>

									<TabPane
										tab={
											<span>
												<FcPaid className="mr-1" />
												Paid out
											</span>
										}
										key="2"
									>
										{data.filter((invoice) => {
											return invoice.status === 'APPROVED';
										}).length === 0 ? (
											<Empty description="You have no approved invoices" />
										) : (
											<div className="fmapp-table-view">
												<table id="fmapp_table_view" className="table table-hover w-100">
													<thead>
														<tr>
															<th>Underwriter</th>
															<th>Date</th>
															<th>Transaction Number </th>
															<th>Status </th>
															<th>Total Amount</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{data
															.filter((invoice) => {
																console.log('ZE INVOICE', invoice);
																return invoice.status === 'APPROVED';
															})
															.map((invoice) => (
																<tr key={invoice.sp_invoice_id}>
																	<td>{invoice.underwriter_name}</td>

																	<td>
																		<span className="mr-10">
																			<Moment format="DD/MM/YYYY">
																				{invoice.created_date}
																			</Moment>
																		</span>
																		<span className="d-block">
																			<Moment format="hh:mm:ss">
																				<small>{invoice.created_date}</small>
																			</Moment>
																		</span>
																	</td>
																	<td>{invoice.transaction_no}</td>

																	<td>{invoice.status}</td>
																	<td>
																		{invoice.currency} {invoice.total_amount}
																	</td>
																	<td>
																		<a href>view</a>
																	</td>
																</tr>
															))}
													</tbody>
												</table>
											</div>
										)}
									</TabPane>
									<TabPane
										tab={
											<span>
												<FcCancel className="mr-1" />
												Declined
											</span>
										}
										key="3"
									>
										{data.filter((invoice) => {
											return invoice.status === 'REJECTED';
										}).length === 0 ? (
											<Empty description="You have no rejected invoices" />
										) : (
											<div className="fmapp-table-view">
												<table id="fmapp_table_view" className="table table-hover w-100">
													<thead>
														<tr>
															<th>Underwriter</th>
															<th>Date</th>
															<th>Transaction Number </th>
															<th>Status </th>
															<th>Total Amount</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{data
															.filter((invoice) => {
																console.log('ZE INVOICE', invoice);
																return invoice.status === 'REJECTED';
															})
															.map((invoice) => (
																<tr key={invoice.sp_invoice_id}>
																	<td>{invoice.underwriter_name}</td>

																	<td>
																		<span className="mr-10">
																			<Moment format="DD/MM/YYYY">
																				{invoice.created_date}
																			</Moment>
																		</span>
																		<span className="d-block">
																			<Moment format="hh:mm:ss">
																				<small>{invoice.created_date}</small>
																			</Moment>
																		</span>
																	</td>
																	<td>{invoice.transaction_no}</td>

																	<td>{invoice.status}</td>
																	<td>
																		{invoice.currency} {invoice.total_amount}
																	</td>
																	<td>
																		<a href>view</a>
																	</td>
																</tr>
															))}
													</tbody>
												</table>
											</div>
										)}
									</TabPane>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* /Main Content */}
		</div>
	);
}

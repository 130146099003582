/**
 * creates and returns a validation rule object that
 * is used by useForm hook to validate the form inputs
 
 */
function createValidationRule(ruleName, errorMessage, validateFunc) {
	return {
		name: ruleName,
		message: errorMessage,
		validate: validateFunc,
	};
}

export function requiredRule(inputName) {
	return createValidationRule('required', `${inputName} required`, (inputValue, formObj) => inputValue.length !== 0);
}

export function minLengthRule(inputName, minCharacters) {
	return createValidationRule(
		'minLength',
		`${inputName} should contain atleast ${minCharacters} characters`,
		(inputValue, formObj) => inputValue.length >= minCharacters
	);
}

export function maxLengthRule(inputName, maxCharacters) {
	return createValidationRule(
		'minLength',
		`${inputName} cannot contain more than ${maxCharacters} characters`,
		(inputValue, formObj) => inputValue.length <= maxCharacters
	);
}

export function passwordMatchRule() {
	return createValidationRule(
		'passwordMatch',
		`passwords do not match`,
		(inputValue, formObj) => inputValue === formObj.password.value
	);
}

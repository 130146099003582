import React, { useContext } from "react";
import IndividualInfo from "./IndividualInfo";
import { KycContext } from "../../../context/KycContext";

const IndividualInfoContainer = () => {
  const { soleKyc, setSoleKyc } = useContext(KycContext);

  function handleSoleKycChange(e) {
    const { name, value } = e.target;
    setSoleKyc({ ...soleKyc, [name]: value });
  }

  return <IndividualInfo state={soleKyc} onChange={handleSoleKycChange} />;
};

export default IndividualInfoContainer;

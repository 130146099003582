import React from "react";
import Dashboard from "./Dashboard";
import { useQuery } from "react-query";
import { api_srv } from "../../services";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const fetchSummary = async () => {
  let resp = await (await api_srv).fetchSummary();
  return resp.summary;
};
const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

const DashboardContainer = () => {
  const { isLoading, isSuccess, data } = useQuery("summary", fetchSummary);
  return (
    <div>
      {isLoading && (
        <Spin
          style={{ position: "absolute", top: "50%", left: "50%" }}
          indicator={antIcon}
        />
      )}
      {isSuccess && <Dashboard data={data} />}
    </div>
  );
};

export default DashboardContainer;

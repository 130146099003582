import React from 'react';
import { Modal } from 'react-bootstrap';
import { LoadingOutlined, UserAddOutlined } from '@ant-design/icons';

import DirectorForm from '../forms/Director_form';

const AddDirector = (props) => {
	return (
		<div>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h6 className="">
							<UserAddOutlined style={{ fontSize: '20px' }} />
							<span className="pl-2 pt-1">Add a Department</span>
						</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<DirectorForm />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddDirector;

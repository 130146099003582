import React from 'react'

const SelectField = ({
    label,
    type,
    name,
    handleChange,
    errorMessage,
    isValid,
    value, options
  }) => {
    return (
        
             <div className="form-group">
         <label htmlFor={name}>{label}</label>
         <select
          name={name} value={value} onChange={handleChange}
           className={
             "form-control custom-select d-block w-100" 
           }
         >
           <option value>Choose...</option>
          { options.length === 0
             ? null
             : options.map((option) => (
                 <option value={option[Object.keys(option)[0]]}>
                   {option[Object.keys(option)[1]]}
                 </option>
               ))}
         </select>
        </div>
    )
}

export default React.memo(SelectField);
 

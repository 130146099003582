import React, { useContext } from 'react';
import AddDepartment from './AddDepartment';
import { ModalContext } from '../../../context/ModalContext';
import { openNotificationWithIcon } from '../../notification';
import { create_department } from '../../../services/apiSrv';
import { useMutation } from 'react-query';

const AddDepartmentFormContainer = ({ refetch }) => {
	const { modalClose } = useContext(ModalContext);

	const mutation = useMutation(create_department, {
		onSuccess: () => {
			refetch();
			modalClose();
			openNotificationWithIcon('success', `Success`, 'Department added successfully');
		},
		onError: async (err) => {
			let error = await err;
			modalClose();
			openNotificationWithIcon('error', `Error`, `${error.message}`);
		},
	});

	return <AddDepartment _addDepartment={mutation} />;
};

export default AddDepartmentFormContainer;

import React, { useState } from 'react';
import Invoice from '../../components/Invoice/Invoice';
import Navbar from '../../components/navbar/DashboardNavbar';
import InvoiceSettings from '../../components/Invoice/InvoiceSettings';
import * as Icon from 'react-feather';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function InvoiceGenerator({ id, state, spDetails }) {
	const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

	return (
		<div>
			<div className="hk-wrapper hk-alt-nav">
				<div className="hk-wrapper hk-alt-nav">
					<div className="hk-pg-wrapper">
						{!state.data ? (
							<div className="d-flex justify-content-center align-items-center">
								<Spin className="text-center" indicator={antIcon} />
							</div>
						) : (
							<div className="row">
								<div className="col-md-3 bg-white " style={{ position: 'fixed', height: '100%' }}>
									<InvoiceSettings />
								</div>

								<div className="offset-md-3 col-md-9">
									<h5 className="hk-pg-title font-weight-600 mt-40 mb-10 text-center">
										<Icon.File className="mr-2" />
										Generate an Invoice
									</h5>

									<Invoice spDetails={spDetails} id={id} />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { useContext, useState } from "react";
import Partners from "./Partners";
import { KycContext } from "../../context/KycContext";

const CompanyUploadsContainer = () => {
  const { partnersUploads, setPartnersUploads } = useContext(KycContext);

  const onChangeHandler = (event) => {
    const name = event.target.name;
    setPartnersUploads({ ...partnersUploads, [name]: event.target.files[0] });
  };

  return <Partners  onChangeHandler={onChangeHandler} />;
};
export default CompanyUploadsContainer;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReportForm from "../forms/report/ReportFormContainer.js";

const AddReport = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="">New Report</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReportForm
          modalClose={props.onHide}
          get_workorder_details={props.get_workorder_details}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddReport;

import React, { useContext, useEffect } from "react";
import ReportForm from "./ReportForm";
import { DashboardContext } from "../../../context/DashboardContext";
import { ModalContext } from "../../../context/ModalContext";
import { api_srv } from "../../../services";
import { notification } from "antd";

class ReportFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      doc: null,
      url: "",
      type: "",
      description: "",
      loading: false,
    };
    this.handleReportUploadChange = this.handleReportUploadChange.bind(this);
  }

  static contextType = DashboardContext;

  handleReportUploadChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleUpload = (evt) => {
    this.setState({
      doc: evt.target.files[0],
    });
  };

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  upload_report = async () => {
    this.setState({ loading: true });
    try {
      let resp = await (await api_srv).upload_api(
        this.state.title,
        this.state.doc
      );

      await this.setState({ url: resp.file_path });
      this.sendReport();
      this.setState({ loading: false });
    } catch (err) {
      let error = await err;
      console.log("upload report error", error);
      this.setState({ loading: false });
    }
  };

  sendReport = async () => {
    try {
      let fileExtension = this.state.url.split(".").pop();
      let resp = await (await api_srv).sendReport(
        this.context.workOrderDetails.data.sp_workOrder_id,
        this.state.title,
        this.state.url,
        fileExtension,
        this.state.description
      );
      this.props.modalClose();

      this.openNotificationWithIcon(
        "success",
        "Success",
        "Report sent successfully"
      );
      this.props.get_workorder_details();
    } catch (err) {
      let error = await err;
      console.log("error occured in sending report", error);
      this.openNotificationWithIcon("error", "Error", `${error.message}`);
    }
  };
  render() {
    return (
      <div>
        <ReportForm
          handleReportUploadChange={this.handleReportUploadChange}
          upload_report={this.upload_report}
          state={this.state}
          handleUpload={this.handleUpload}
        />
      </div>
    );
  }
}

export default ReportFormContainer;

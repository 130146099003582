import React from 'react';
import bg from '../../../assets/dist/img/tickets-bg.jpg';
import Moment from 'react-moment';
import { useHistory, Link } from 'react-router-dom';
import { Input } from 'antd';
import { FcClock } from 'react-icons/fc';
import { AiOutlineHourglass } from 'react-icons/ai';
import { Tabs, Empty } from 'antd';

import * as Icon from 'react-feather';

const Settlement = ({ data }) => {
	const history = useHistory();
	const { Search } = Input;
	const { TabPane } = Tabs;

	let pendingSettlements = data.filter((settlements) => settlements.status === 'pending');
	let progressSettlements = data.filter((settlements) => settlements.status === 'in-progress');
	return (
		<div className="hk-wrapper hk-vertical-nav">
			<div className="hk-pg-wrpper">
				{/* Container */}
				<div className="container-fluid">
					{/* Row */}
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="profile-cover-wrap overlay-wrap">
								<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
								<div className="bg-overlay bg-trans-dark-60" />
								<div className="container profile-cover-content py-30">
									<div className="text-white text-capitalize display-6 mb-5 font-weight-400">
										<Icon.Layers className="mr-2" />
										Settlement
									</div>
								</div>
							</div>

							<div className="tab-content mt-sm-60 mt-30">
								<div className="container">
									<div className="fmapp-wrap">
										<div className="fm-box">
											<div className="fmapp-main fmapp-view-switch">
												<header>
													<div className="fm-options-wrap">
														<a href id="fmapp_sidebar_move" className="fmapp-sidebar-move">
															<span className="feather-icon">
																<i data-feather="menu" />
															</span>
														</a>
														<a href>
															<span className="feather-icon">
																<Icon.Filter size={18} />
															</span>
														</a>
													</div>
													<span className> Settlements</span>
													<Search
														placeholder="Search a settlement"
														onSearch={(value) => console.log(value)}
														style={{ maxWidth: 250 }}
													/>
												</header>

												<div className="fm-body mt-75">
													<div className="nicescroll-bar">
														<div className="fmapp-view-wrap">
															<div className="fmapp-grid-view">
																<div className="row">
																	<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																		<span className="file-name mt-10"></span>
																	</div>
																</div>
															</div>
															<Tabs
																defaultActiveKey="1"
																size="large"
																className="clearfix"
															>
																<TabPane
																	tab={
																		<span>
																			<FcClock className="mr-1" />
																			Pending
																		</span>
																	}
																	key="1"
																>
																	{pendingSettlements.length === 0 ? (
																		<Empty />
																	) : (
																		<div className="fmapp-table-view">
																			<table
																				id="fmapp_table_view"
																				className="table table-hover w-100"
																			>
																				<thead>
																					<tr>
																						<th>Account Number</th>
																						<th>Amount</th>
																						<th>Channel </th>
																						<th>Reference</th>
																						<th>Date</th>
																						<th>Status</th>
																						<th>Action</th>
																					</tr>
																				</thead>
																				<tbody>
																					{pendingSettlements.map((row) => (
																						<tr>
																							<td>12345</td>
																							<td>10000</td>
																							<td>Bank </td>

																							<td>ref123</td>
																							<td>12/05/2021</td>
																							<td>Active</td>
																							<td>
																								<span className="">
																									<Link
																										onClick={() =>
																											history.push(
																												`/dashboard/settlement/${1}`
																											)
																										}
																									>
																										view
																									</Link>
																								</span>
																							</td>
																						</tr>
																					))}
																				</tbody>
																			</table>
																		</div>
																	)}
																</TabPane>
																<TabPane
																	tab={
																		<span>
																			<AiOutlineHourglass className="mr-1" />
																			In Progress
																		</span>
																	}
																	key="2"
																>
																	{progressSettlements.length === 0 ? (
																		<Empty />
																	) : (
																		<div className="fmapp-table-view">
																			<table
																				id="fmapp_table_view"
																				className="table table-hover w-100"
																			>
																				<thead>
																					<tr>
																						<th>Account Number</th>
																						<th>Amount</th>
																						<th>Channel </th>
																						<th>Reference</th>
																						<th>Date</th>
																						<th>Status</th>
																						<th>Action</th>
																					</tr>
																				</thead>
																				<tbody>
																					{progressSettlements.map((row) => (
																						<tr>
																							<td>12345</td>
																							<td>10000</td>
																							<td>Bank </td>

																							<td>ref123</td>
																							<td>12/05/2021</td>
																							<td>Active</td>
																							<td>
																								<span className="">
																									<Link
																										onClick={() =>
																											history.push(
																												`/dashboard/settlement/${1}`
																											)
																										}
																									>
																										view
																									</Link>
																								</span>
																							</td>
																						</tr>
																					))}
																				</tbody>
																			</table>
																		</div>
																	)}
																</TabPane>
															</Tabs>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* /Row */}
				</div>
				{/* /Container */}
				{/* Footer */}
				{/* <div className="hk-footer-wrap container">
          <footer className="footer">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <p>
                  A product by
                  <a href="/" className="text-dark" target="_blank">
                    Nexus Insurance
                  </a>{" "}
                  © 2020
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <p className="d-inline-block">Follow us</p>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-facebook" />
                  </span>
                </a>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-twitter" />
                  </span>
                </a>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-google-plus" />
                  </span>
                </a>
              </div>
            </div>
          </footer>
        </div> */}
				{/* /Footer */}
			</div>
			{/* /Main Content */}
		</div>
	);
};

export default Settlement;

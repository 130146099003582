import React from "react";
import './input.css'



function InputField( {
    label,
    type,
    name,
    handleChange,
    errorMessage,
    isValid,
    value
  }) {
 

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input type={type} name={name} value={value} onChange={handleChange} />
      {errorMessage && !isValid && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
       
  );
}

export default React.memo(InputField);
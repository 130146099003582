import React, { useContext} from "react";
import {Redirect } from "react-router-dom";
import {AuthContext} from '../../context/AuthContext'

export default function VerifyEmail() {


    const {confirmimgToken} = useContext(AuthContext)
  
    const styles = {
        height: "70px",
        width: "70px",
    };

  return (
    <div className="centerdiv">
      {(confirmimgToken) ? 
        <>
          <p>Please wait...</p>
          {/* <Spinner style={styles} />{" "} */}
        </>
      : (!confirmimgToken) ? 
        <>
          <p>Please wait...</p>
          {/* <Spinner style={styles} /> */}
           <Redirect to="/register" />
        </>
      :<p>Please wait...</p>
      }
    </div>
  );
}

import React, { useState } from 'react';
import styles from './Profile.module.css';
import { Tabs, Descriptions } from 'antd';
import { AiOutlineStar, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { MdLocationOn } from 'react-icons/md';
import { image_url } from 'services/apiSrv';

const { TabPane } = Tabs;

const Profile = ({ data }) => {
	return (
		<div className="container">
			<div className={`${styles.cover} row`}>
				<h1>{data.service_provider_name}</h1>
				<button className="btn btn-sm btn-secondary">Change Cover Photo</button>
			</div>
			<div className={`${styles.profileImg}`}>
				<img
					className="img-thumbnail"
					src={image_url + data.image_path || 'http://via.placeholder.com/640x360'}
					alt=""
				/>
				<button className="btn btn-sm btn-secondary">Change Picture</button>
			</div>
			<div className={`row ${styles.content}`}>
				<div className={`${styles.profile} col-md-3 col-xs-12`}>
					<ul className="profile-links">
						<li>
							<AiOutlineMail />{' '}
							<a href="#">
								<i className />
								{data.emails}
							</a>
						</li>
						<li>
							<AiOutlinePhone />{' '}
							<a href="#">
								<i className />
								{data.contacts}
							</a>
						</li>
						<li>
							<MdLocationOn />{' '}
							<a href="#">
								<i className />
								{data.physical_address}
							</a>
						</li>
						<li>
							<AiOutlineStar />{' '}
							<a href="#">
								<i className />
								{data.rating}
							</a>
						</li>
					</ul>
					<hr />
					<p>{data.description}</p>
				</div>
				<div className={`${styles.feed} col-md-9 col-xs-12`}>
					<div className={`media ${styles.feedItem}`}>
						<Descriptions title="Profile Details">
							<Descriptions.Item label="Category">{data.category}</Descriptions.Item>
							<Descriptions.Item label="Category Code">{data.category_code}</Descriptions.Item>
							<Descriptions.Item label="PIN">{data.pin}</Descriptions.Item>
							<Descriptions.Item label="Certificate No.">{data.certificate_no}</Descriptions.Item>
							<Descriptions.Item label="Kyc Status">{data.KYC_status}</Descriptions.Item>
							<Descriptions.Item label="License No.">{data.license_no}</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;

import React, { useState, createContext, useContext, useEffect } from 'react';
import { AlertsContext } from './AlertsContext';

const DashboardContext = createContext();
const DashboardContextProvider = (props) => {
	const { setShowAlert, showAlert } = useContext(AlertsContext);

	//  states

	const [alert, setAlert] = useState({
		message: '',
		variant: '',
	});
	const [notifications, setNotifications] = useState({
		data: '',
		loading: false,
	});

	const [workOrderDetails, setWorkOrderDetails] = useState({
		data: '',
		loading: false,
	});

	// workorder reports
	const [reportPref, setReportPref] = useState({
		title: '',
		doc: null,
		url: '',
		type: '',
		description: '',
		loading: false,
	});

	// workorder memo
	const [memo, setMemo] = useState({
		title: '',
		message: '',
		loading: false,
	});

	const [showTemplate, setShowTemplate] = useState(false);

	// invoice
	const [invoices, setInvoices] = useState({
		data: [],
		loading: false,
	});

	const [invoiceSettings, setInvoiceSettings] = useState({
		underwriter: '',
		invoiceNumber: '',
		currency: 'KES',
		notes: '',
		taxRate: 0.0,
		lineItems: [
			{
				id: 0,
				name: '',
				description: '',
				quantity: 0,
				price: 0.0,
			},
		],
		lineTotal: 0,
		taxTotal: 0,
		total: 0,
		loading: false,
	});

	// accept workorder
	const [acceptWorkorder, SetAcceptWorkorder] = useState({
		completionDate: '',
		expectedCost: 0,
	});

	// reject workorder
	const [rejectionRemarks, setRejectionRemarks] = useState({
		loading: false,
		data: '',
	});

	const [coordinates, setCoordinates] = useState({
		lat: localStorage.getItem('lat'),
		lng: localStorage.getItem('lng'),
	});

	// service ref while adding a service
	const [serviceRef, setServiceRef] = useState('');

	useEffect(() => {
		if (showAlert) {
			setTimeout(() => {
				setShowAlert(false);
			}, 5000);
		}
	}, [alert, setShowAlert, showAlert]);

	return (
		<DashboardContext.Provider
			value={{
				alert,
				setAlert,
				notifications,
				setNotifications,
				workOrderDetails,
				setWorkOrderDetails,
				reportPref,
				setReportPref,
				showTemplate,
				setShowTemplate,
				invoices,
				setInvoices,
				invoiceSettings,
				setInvoiceSettings,
				acceptWorkorder,
				SetAcceptWorkorder,
				rejectionRemarks,
				setRejectionRemarks,
				coordinates,
				setCoordinates,
				memo,
				setMemo,
				serviceRef,
				setServiceRef,
			}}
		>
			{props.children}
		</DashboardContext.Provider>
	);
};

const DashboardConsumer = DashboardContext.Consumer;
export { DashboardContextProvider, DashboardConsumer, DashboardContext };

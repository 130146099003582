import React, { useState } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ReactQuill from 'react-quill';
import { Button } from 'antd';
export default function TextEditor() {
	function handleChange(content) {
		console.log('THE EDITOR CONTENT', content); //Get Content Inside Editor
	}
	function handleClick(event) {
		console.log('THD CLICKED', event); //Get the click event
	}
	return (
		//     <ReactQuill/>
		<SunEditor
			onChange={handleChange}
			onClick={handleClick}
			setOptions={{
				height: 300,
				buttonList: [
					[
						'undo',
						'redo',
						'font',
						'fontSize',
						'formatBlock',
						'paragraphStyle',
						'blockquote',
						'bold',
						'underline',
						'italic',
						'strike',
						'subscript',
						'superscript',
						'fontColor',
						'hiliteColor',
						'textStyle',
						'removeFormat',
						'outdent',
						'indent',
						'align',
						'horizontalRule',
						'list',
						'lineHeight',
						'table',
						'link',
						'image',
						'video',
						'audio' /** 'math', */, // You must add the 'katex' library at options to use the 'math' plugin.
						'fullScreen',
						'showBlocks',
						'codeView',
						'preview',
						'print',
						'save',
					],
				],
				// buttonList: buttonList.formatting // Or Array of button list, eg. [['font', 'align'], ['image']]
				// Other option
			}}
		/>
	);
}

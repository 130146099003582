import React from 'react';
import { list_admin_services } from '../../../services/apiSrv';
import { useQuery } from 'react-query';
import AdminServices from './AdminServices';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const AdminServicesContainer = ({ next }) => {
	const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

	const _listAdminServices = async () => {
		let resp = await list_admin_services();
		console.log('ADMIN SERV', resp);
		return resp.insurance_services;
	};

	const { isLoading, isSuccess, data } = useQuery('admin services', _listAdminServices);
	return (
		<div>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center">
					<Spin className="text-center" indicator={antIcon} />
				</div>
			)}
			{isSuccess && <AdminServices next={next} services={data} />}
		</div>
	);
};

export default AdminServicesContainer;

import React from 'react';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Loader from '../../components/loader';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { get_context_details } from '../../services/apiSrv';
import { Tabs, Descriptions, Empty } from 'antd';
import { GrShieldSecurity } from 'react-icons/gr';
import Moment from 'react-moment';

export default function SecurityContextDetails() {
	const { TabPane } = Tabs;
	const { id } = useParams();
	const _securityContextDetails = async () => {
		let resp = await get_context_details(id);
		console.log('THE CONTEXT DETAILS', resp);
		return resp.security_context_details;
	};

	const { data, isLoading, isSuccess, isError } = useQuery('security context details', _securityContextDetails);

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div className="hk-wrapper hk-vertical-nav">
					<div className="hk-pg-wrpper">
						{/* Container */}
						<div className="container-fluid">
							{/* Row */}
							<div className="row">
								<div className="col-xl-12 pa-0">
									<div className="profile-cover-wrap overlay-wrap">
										<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
										<div className="bg-overlay bg-trans-dark-60" />
										<div className="container profile-cover-content py-30"></div>
									</div>
									<div className=" position-absolute" style={{ width: '100%', top: '70px' }}>
										<div className="container">
											<div className="card">
												<div className="card-body">
													<div className="d-flex justify-content-between mb-4">
														<div>
															<h5>
																<span className="mr-2">
																	<GrShieldSecurity />
																</span>
																{data.name}
															</h5>
														</div>
													</div>
													<Tabs defaultActiveKey="1" type="card">
														<TabPane tab="Security Context Details" key="1">
															<Descriptions title="Security Context Info" column={2}>
																<Descriptions.Item label="Name">
																	{data.name}
																</Descriptions.Item>

																<Descriptions.Item label="Context Code">
																	{data.security_context_code}
																</Descriptions.Item>
																<Descriptions.Item label="Status">
																	{data.security_context_status}
																</Descriptions.Item>
																<Descriptions.Item label="Description">
																	{data.description}
																</Descriptions.Item>
																<Descriptions.Item label="Login Context (High)">
																	<Moment format="DD-MMM-YYYY HH:MM:a">
																		{data.login_context.high}
																	</Moment>
																</Descriptions.Item>
																<Descriptions.Item label="Login Context (Low)">
																	<Moment format="DD-MMM-YYYY HH:MM:a">
																		{data.login_context.low}
																	</Moment>
																</Descriptions.Item>
																<Descriptions.Item label="Password Policy Change">
																	{data.password_change_policy}
																</Descriptions.Item>
																<Descriptions.Item label="Idle Maximum Timeout (Minutes)">
																	{data.idle_max_timeout}
																</Descriptions.Item>
																<Descriptions.Item label="Token Time to Live (Hours)">
																	{data.token_ttl}
																</Descriptions.Item>
																<Descriptions.Item label="Allowed devices">
																	<ul>
																		{data.allowed_devices.map((device) => (
																			<li key={device}>{device}</li>
																		))}
																	</ul>
																</Descriptions.Item>
																<Descriptions.Item label="Allowed channels">
																	<ul>
																		{data.allowed_channels.map((channel) => (
																			<li key={channel}>{channel}</li>
																		))}
																	</ul>
																</Descriptions.Item>
																<Descriptions.Item label="Device Version">
																	<ul>
																		{data.device_versions.map((version) => (
																			<li key={version.device_version}>
																				{version.device_type} ~ version{' '}
																				{version.device_version}
																			</li>
																		))}
																	</ul>
																</Descriptions.Item>
																<Descriptions.Item label="Holiday Login">
																	{`${data.holiday_login}`}
																</Descriptions.Item>
																<Descriptions.Item label="Two Factor Authentication">
																	{`${data.two_factor_authentication}`}
																</Descriptions.Item>
															</Descriptions>
														</TabPane>
													</Tabs>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* /Row */}
						</div>
						{/* /Container */}
						{/* Footer */}

						{/* /Footer */}
					</div>
					{/* /Main Content */}
				</div>
			)}
		</>
	);
}

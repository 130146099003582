import React, { useEffect, useContext } from "react";
import VerifyToken from "./VerifyResetToken";
import { AuthContext } from "../../context/AuthContext";
import {api_srv} from '../../services'
import { useToasts } from "react-toast-notifications";
import queryString from 'query-string'
import {useHistory} from 'react-router-dom'


const VerifyTokenContainer = (props) => {
  
  // const { addToast } = useToasts();
  const { token } = queryString.parse(props.location.search);
  const history = useHistory()

  const { setConfirmingToken} = useContext(AuthContext);

  // const verifyEmail = async (token) => {
  //   setConfirmingToken(true);
  //   localStorage.clear()
  //   try {
  //     let resp = await (await api_srv).verifyResetToken(token);
  //     localStorage.setItem(
  //       "password_change_request",
  //       resp.password_change_request_id
  //     );
  //     setConfirmingToken(false);
  //     history.push("/reset2");
  //   } catch (err) {
  //     let error = await err;
  //     setConfirmingToken(false);
 
  //   }
  // };

  // useEffect(() => {
  //   verifyEmail(token);
  // }, []);
  return <VerifyToken />;
};

export default VerifyTokenContainer;

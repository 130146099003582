import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './loader.css';

const Loader = () => {
	const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

	return (
		<div className="loader">
			<Spin indicator={antIcon} />
		</div>
	);
};

export default Loader;

import React, {useState, useContext} from 'react'
import { api_srv } from '../../services'
import RecoverPassword from './NewPassword'
import {AuthContext} from '../../context/AuthContext'
import {AlertsContext} from '../../context/AlertsContext'
import {useHistory} from 'react-router-dom'

const NewPasswordContainer = () => {

    const history = useHistory();

    const { alert, setAlert } = useContext(AuthContext);
    const { setShowAlert } = useContext(AlertsContext);
  
    const [password, setPassword] = useState({
      newPass: "",
      newPass2: "",
      showPassword: false,
      showConfirmPassword: false,
      loading: false,
    });
  
    const handlePasswordChange = (evt) => {
      const { name, value } = evt.target;
      setPassword({ ...password, [name]: value });
    };
  
    const togglePassword = () => {
      setPassword({ ...password, showPassword: !password.showPassword });
    };
  
    const toggleConfirmPassword = () => {
      setPassword({
        ...password,
        showConfirmPassword: !password.showConfirmPassword,
      });
    };
  
    const send_new_password = async (event) => {
        event.preventDefault();
      setPassword({ ...password, loading: true });
      try {
        let request_id = localStorage.getItem("password_change_request");
        let resp = await (await api_srv).reset(request_id, password.newPass);
        setAlert({ ...alert, message: resp.user_det.status, variant: "success" });
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
          setPassword({ ...password, loading: false, newPass:'', newPass2:'' });
          history.push("/login");
        }, 3000);
       
      } catch (err) {
        let error = await err;
        setPassword({ ...password, loading: false, newPass:'', newPass2:'' });
        setAlert({ ...alert, message: error.message, variant: "danger" });
        setShowAlert(true)
      
      }
    };
    return (
        <RecoverPassword
        state={password}
        handleChange={handlePasswordChange}
        send_new_password={send_new_password}
        togglePassword={togglePassword}
        toggleConfirmPassword={toggleConfirmPassword}
        />
    )
}

export default NewPasswordContainer

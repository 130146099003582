import React,{useContext} from "react";
import { Modal } from "react-bootstrap";
import {DashboardContext} from '../../context/DashboardContext'


const CompletionTime = (props) => {

  const {rejectionRemarks,setRejectionRemarks} = useContext(DashboardContext)

  function handleChange(e) {
    const value = e.target.value
    setRejectionRemarks({...rejectionRemarks, data:value})
  }
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Remarks</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e)=>{
            e.preventDefault()
            props.reject()}}>
        <div className="form-group">
        <label htmlFor="remarks">Reason for Rejection</label>
        <textarea 
        className="form-control" 
        name="remarks" 
        required 
        value={rejectionRemarks.data} 
        rows={3}
        onChange={handleChange}
         />
      </div>
      <p className="-20"><button type= "submit" className=" btn btn-sm btn-danger btn-block">Reject Job</button></p>
      </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompletionTime 

import React, { useContext } from "react";
import Company from "./Company";
import { KycContext } from "../../context/KycContext";

const CompanyUploadsContainer = () => {
  const { companyUploads, setCompanyUploads } = useContext(KycContext);

  const onChangeHandler = (event) => {
    const name = event.target.name;
    setCompanyUploads({ ...companyUploads, [name]: event.target.files[0] });
  };

  return <Company onChangeHandler={onChangeHandler} />;
};
export default CompanyUploadsContainer;

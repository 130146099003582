import React from 'react';
import { addRoles } from '../../../shared/utils/formConfig';
import useForm from '../../../shared/customHooks/useForm';

const AddRolesForm = ({ _addRoles }) => {
	const { renderFormInputs, isFormValid, form } = useForm(addRoles);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				_addRoles.mutate(form);
			}}
		>
			{renderFormInputs()}

			<button
				className="btn btn-primary btn-block"
				type="submit"
				disabled={!isFormValid() || _addRoles.isLoading}
			>
				{_addRoles.isLoading ? (
					<span>
						<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
						Please wait...
					</span>
				) : (
					'Add'
				)}
			</button>
		</form>
	);
};

export default AddRolesForm;

import { handle_api_error } from "./errorHandler";
import axios from "axios";

const location=window.location.hostname
let apiEnv=location.split(".");

  apiEnv="prod"
  console.log(" ==== api env === ",apiEnv)
    
    // if(apiEnv == 'nexus') {
    //   apiEnv='prod'; // handle for production
    // }else{
    //   apiEnv='prod';
    // }

//let endpoint = `${domain}/api/web/v1/admin`;
// let api_url = "https://api-uat.nexus.ke/api/web/v1/serviceprovider";
let api_url = `https://api.${apiEnv}.nexus.ke/v1/api/web/v1/serviceprovider`;
let upload_url = `https://api.${apiEnv}.nexus.ke/v1/api/upload/v1/client`;
// let upload_url = 'https://api.dev.nexus.ke/v1/api/upload/v1/client`;
let image_url = `https://api.${apiEnv}.nexus.ke/`;

// register service provider

function register({ pin, category, email }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        resource: "registration",
        action: "register",
        data: {
          pin: pin.value,
          category_code: category.value,
          email: email.value,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Login

function login({ username, password }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      sessionStorage.clear();
      const body = {
        resource: "login",
        action: "login",
        data: {
          username: username.value,
          password: password.value,
          device_details: {
            device_type: "CHROME",
            device_category: "WEB",
            device_version: "V1",
            device_name: "CHROME",
            device_uuid: "1223455",
          },
        },
      };
      let response = await call_api(body, config);
      sessionStorage.setItem("token", response.token);
      sessionStorage.setItem(
        "id",
        response.employee_details.service_provider_id
      );
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// List Service Categories

function listCategories() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        resource: "category",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Check if Pin Exists

function checkPin(pin) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        resource: "registration",
        action: "pin_exists",
        data: {
          pin: pin,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Verify token sent to email

function verifyEmail(token) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        resource: "registration",
        action: "validation",
        data: {
          email_token: token,
        },
      };
      let response = await call_api(body, config);
      console.log("EMAIL VERIFICATION RESPONSE", response);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Reset password
function sendUsername(username) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        resource: "forgot_password",
        action: "request",
        data: {
          username: username,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Setup credentials

function setupCredentials({ names, contact, username, password }) {
  return new Promise(async function (resolve, reject) {
    try {
      let setup_id = localStorage.getItem("REGISTRATION_ID");
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        resource: "registration",
        action: "credential",
        data: {
          registration_setup_id: setup_id,
          tnc: true,
          names: names.value,
          contact: contact.value,
          password: password.value,
          username: username.value,
        },
      };
      console.log("the credentials body", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Add Kyc Details

function addIndividualKycInfo({
  name,
  kra_pin,
  email,
  contact,
  license,
  description,
  id_number,
  address,
  location,
  category,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "kyc",
        action: "add_individual_kyc",
        data: {
          update: {
            category: category,
            service_provider_name: name,
            pin: kra_pin,
            emails: email.split(),
            contacts: contact.split(),
            license_no: license,
            description: description,
            ID_number: id_number,
            address: address,
            physical_address: location,
            image_path: "ret/terrain/photo.jpg",
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
// Add Kyc Details Company

function addCompanyKycInfo({
  name,
  kra_pin,
  email,
  contact,
  certificate_number,
  description,
  address,
  location,
  category,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "kyc",
        action: "add_company_kyc",
        data: {
          update: {
            category: category,
            service_provider_name: name,
            pin: kra_pin,
            emails: email.split(),
            contacts: contact.split(),
            description: description,
            address: address,
            physical_address: location,
            image_path: "ret/terrain/photo.jpg",
            certificate_no: certificate_number,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Notification

function fetchNotifications() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "notifications",
        action: "list",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// verify reset Token
function verifyResetToken(token) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        resource: "forgot_password",
        action: "validation",
        data: {
          email_token: token,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Check kyc stataus

function check_kyc() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        timeout: 15000,
      };
      const body = {
        resource: "kyc",
        action: "check_sp_details",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// reset password
function reset(request_id, password) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 15000,
      };
      const body = {
        resource: "forgot_password",
        action: "change",
        data: {
          password_change_request_id: request_id,
          password: password,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Add Kyc Joint Owners

function addKycJointOwners(jointOwners) {
  return new Promise(async function (resolve, reject) {
    jointOwners.map(
      async ({
        name,
        email,
        residency,
        contact,
        passport,
        kra_pin,
        id_number,
      }) => {
        try {
          let config = {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          };

          const body = {
            resource: "kyc",
            action: "add_service_provider_director",
            data: {
              service_provider_id: 16,
              director: {
                names: name,
                email: email,
                image: "joint owner.jpg",
                residency: residency,
                contact: contact,
                passport_number: passport,
                pin: kra_pin,
                ID_number: id_number,
              },
            },
          };
          let response = await call_api(body, config);
          return resolve(response);
        } catch (err) {
          return reject(err);
        }
      }
    );
  });
}
// Add Kyc Partners/ Directors

function addKycPartners(directors) {
  return new Promise(async function (resolve, reject) {
    directors.map(
      async ({
        name,
        email,
        residency,
        contact,
        passport,
        kra_pin,
        id_number,
      }) => {
        try {
          let config = {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          };

          const body = {
            resource: "kyc",
            action: "add_service_provider_director",
            data: {
              service_provider_id: 16,
              director: {
                names: name,
                email: email,
                image: "joint owner.jpg",
                residency: residency,
                contact: contact,
                passport_number: passport,
                pin: kra_pin,
                ID_number: id_number,
              },
            },
          };
          let response = await call_api(body, config);
          return resolve(response);
        } catch (err) {
          return reject(err);
        }
      }
    );
  });
}

// Dashboard Summary

function fetchSummary() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "profile",
        action: "dashboard",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
// Workorders

function fetchWorkorders() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "list_sp_workorders",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// workorder details
function viewWorkorder(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "get_workorder_details",
        data: {
          sp_workOrder_id: id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function acceptWorkorder(sp_workOrder_id, date, expected_cost) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "accept_workOrder",
        data: {
          sp_workOrder_id: sp_workOrder_id,
          work_order: {
            estimated_end_date: date,
            expected_cost: expected_cost,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function addCostEstimate(sp_workOrder_id, details) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "add_cost_estimate",
        data: {
          sp_workOrder_id: sp_workOrder_id,
          cost_estimate: details,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function rejectWorkorder(sp_workOrder_id, remarks) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "reject_workorder",
        data: {
          sp_workOrder_id: sp_workOrder_id,
          data: {
            rejection_remarks: remarks,
          },
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// workorder reports

function sendReport(sp_workOrder_id, name, url, report_type, description) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "add_report",
        data: {
          sp_workOrder_id: sp_workOrder_id,
          report: {
            report_name: name,
            report_description: description,
            report_type: report_type,
            report_path: url,
          },
        },
      };
      console.log("TESTING RPORTS BIDY", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Underwriter details

function fetchUnderwriterDetails(underwritter_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "get_underwritter_details",
        data: {
          underwritter_id: underwritter_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function listInvoices() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "sp_invoice",
        action: "list_my_invoices",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function sendInvoice(
  underwriter_id,
  workorder_ref,
  details,
  tax,
  currency,
  total
) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      console.log(" == invoiceSettings tax=== ",tax)
      if(!tax) {
        tax=0;
      }

      const body = {
        resource: "sp_invoice",
        action: "create",
        data: {
          sp_id: Number(sessionStorage.getItem("id")),
          underwritter_id: underwriter_id,
          workorder_ref: workorder_ref,
          details: details,
          tax: tax,//tax.split(),
          currency: currency,
          total_amount: total,
        },
      };
      console.log("body of invoice", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function sendMemo(workorder_id, memo) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "add_memo",
        data: {
          sp_workOrder_id: workorder_id,
          Memo: {
            subject: memo.title,
            memo: memo.message,
          },
        },
      };
      console.log("THE BODY SENT TO MEMO", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function completeWorkorder(sp_workOrder_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "workOrder",
        action: "complete_workorder",
        data: {
          sp_workOrder_id: sp_workOrder_id,
          data: {
            completion_remarks: "",
          },
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// list my accounts
function fetchAccounts() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "account",
        action: "list",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// view Invoice Details
function viewInvoice(invoice_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      };

      const body = {
        resource: "sp_invoice",
        action: "get_invoice_details",
        data: {
          sp_invoice_id: invoice_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_taxes() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_tax_category() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "tax_category",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_fees() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "list_fees",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_taxes({
  tax_name,
  tax_type,
  entity_tax_account,
  management,
  entity_tax_ref,
  tax_value,
  tax_description,
  tax_category,
  slab,
  remarks,
  systemTaxRef,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "add",
        data: {
          entity_tax_ref: entity_tax_ref,
          tax_name: tax_name,
          tax_description: tax_description,
          tax_type: tax_type,
          system_tax_category_ref: systemTaxRef,
          entity_tax_currency: "KES",
          entity_tax_account: entity_tax_account,
          tax_management: management,
          tax_value: tax_value,
          tax_category: tax_category,
          tax_slab: slab,
          remarks: remarks,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function calculateTax(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "calculate",
        data: {
          system_tax_ref: values.system_tax_ref,
          amount: values.amount,
          option: values.option,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function add_fees({
  fee_name,
  fee_type,
  management,
  fee_reference,
  fee_description,
  taxed,
  entity_fee_account,
  entity_tax_ref,
  fee_value,
  remarks,
  slab,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "add_fee",
        data: {
          fee_name: fee_name,
          fee_description: fee_description,
          fee_type: fee_type,
          fee_management: management,
          fee_value: fee_value,
          fee_slab: slab,
          entity_fee_ref: fee_reference,
          entity_fee_currency: "KES",
          tax_details: {
            is_taxed: taxed,
            taxes: entity_tax_ref.map((tax) => ({
              system_tax_ref: tax,
              is_inclusive: true,
            })),
          },
          entity_fee_account: entity_fee_account,
          remarks: remarks,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fees_details(entity_fee_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "get",
        data: {
          entity_fee_ref: entity_fee_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function tax_details(entity_tax_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "get",
        data: {
          entity_tax_ref: entity_tax_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function calculateFee(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "calculate",
        data: {
          system_fee_ref: values.system_fee_ref,
          amount: values.amount,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_services() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "sp_services",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_admin_services() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "sp_services",
        action: "list_insurance",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_services(system_service_ref, values) {
  return new Promise(async function (resolve, reject) {
    try {
      console.log("THE SYSTEM VALUES ARE HERE", values);
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "sp_services",
        action: "add",
        data: {
          system_service_ref: system_service_ref,
          service_name: values.service_name,
          coordinates: [465363464, 352354325],
          mobile_contacts: ["0745263726", "072135463"],
          emails: ["joa@email.com", "kent@email.com"],
          service_image_path: "../../path",
          work_hours: [
            {
              day: "weekdays",
              from: "0800hrs",
              to: "1700hrs",
            },
            {
              day: "weekends",
              from: "0900hrs",
              to: "1230hrs",
            },
          ],
          service_description: values.description,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// USER MANAGEMENT
function list_departments() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "department",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function department_details(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "department",
        action: "get",
        data: {
          department_id: id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_department({ code, name, description }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "department",
        action: "create",
        data: {
          department_code: code.value,
          department_name: name.value,
          department_description: description.value,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_roles() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function view_role_details(role_code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "get",
        data: {
          role_code: role_code,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function add_roles({ name, description, role_code }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "add",
        data: {
          name: name.value,
          description: description.value,
          role_code: role_code.value,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_users() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_user({
  ref,
  name,
  department,
  role,
  contact,
  email,
  username,
  security,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user",
        action: "create",
        data: {
          employee_ref: ref,
          names: name,
          department: department,
          role_code: role,
          contact: contact,
          email: email,
          username: username,
          security_context_code: security,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function view_user(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user",
        action: "get",
        data: {
          user_id: id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_user_groups() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user_group",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_user_group({ code, name, description }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user_group",
        action: "create",
        data: {
          group_code: code.value,
          group_name: name.value,
          description: description.value,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function user_group_details(group_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user_group",
        action: "details",
        data: {
          group_id: group_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_user_to_group(data) {
  console.log("API SRV ADD USER", data);
  return new Promise(async function (resolve, reject) {
    try {
      console.log("API SRV ADD USER", data.users);
      data.users.map(async (user) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };
        const body = {
          resource: "user_group",
          action: "add_user",
          data: {
            group_id: data.group_id,
            user_id: user,
          },
        };

        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}

function list_security_context() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "security_context",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_context({
  name,
  password_policy,
  login_period_start,
  login_period_end,
  security_context_code,
  max_timeout,
  token_ttl,
  allowed_channels,
  browser_version,
  allowed_devices,
  device_version,
  two_factor_auth,
  holiday_login,
  description,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "security_context",
        action: "create",
        data: {
          name: name,
          description: description,
          password_change_policy: password_policy,
          idle_max_timeout: max_timeout,
          token_ttl: token_ttl,
          security_context_code: security_context_code,
          login_context: {
            high: login_period_start,
            low: login_period_end,
          },
          allowed_channels: allowed_channels,
          allowed_devices: allowed_devices,
          device_versions: [
            {
              device_type: "and",
              device_version: "1",
            },
          ],
          two_factor_authentication: two_factor_auth,
          holiday_login: holiday_login,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function get_context_details(security_context_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "security_context",
        action: "get",
        data: {
          security_context_id: security_context_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// settlement
function listSettlement() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function listConfigs() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "list_config",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function createConfig({
  intermediary_settlement_ref,
  name,
  description,
  account,
  type,
  frequency,
  frequency_day_of_week,
  frequency_day_of_month,
  frequency_remarks,
  your_ref,
  frequency_time,
  release_level,
  channel,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "create_config",
        data: {
          stc_system_ref: channel,
          st_config_name: name,
          st_config_description: description,
          st_config_entity_ref: intermediary_settlement_ref,
          entity_account_number: account,
          st_config_type: type,
          st_config_release_level: release_level,
          st_config_frequency: {
            frequency_type: frequency,
            time_of_day: frequency_time,
            day_of_week: frequency_day_of_week,
            day_of_month: frequency_day_of_month,
          },
          st_remarks: frequency_remarks,
          st_reference: {
            reference_type: type,
            entity_ref: your_ref,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function listChannels() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "list_channel",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function createChannel() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "create_channel",
        data: {
          settlement_channel_currency: "KES",
          settlement_channel_name: "settlement test",
          settlement_channel_description: "describe...",
          tran_channel_code: "test",
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
// profile details
function getProfile() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "kyc",
        action: "check_sp_details",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
// base functions

async function call_api(body, config) {
  return new Promise(async function (resolve, reject) {
    axios
      .post(api_url, body, config)
      .then(function (response) {
        return resolve(response.data.result);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return reject(handle_api_error(error.response.data));
          }
        } else {
          return reject(error);
        }
      });
  });
}

async function upload_api(fileName, file) {
  return new Promise(async function (resolve, reject) {
    const fmData = new FormData();
    const uploadconfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };
    fmData.append("file", file);
    axios
      .post(upload_url, fmData, uploadconfig)
      .then(function (response) {
        return resolve(response.data.result);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return reject(handle_api_error(error.response.data));
          }
        } else {
          return reject(error);
        }
      });
  });
}

export {
  image_url,
  register,
  login,
  sendUsername,
  listCategories,
  checkPin,
  verifyEmail,
  setupCredentials,
  addIndividualKycInfo,
  addCompanyKycInfo,
  upload_api,
  addKycJointOwners,
  fetchNotifications,
  verifyResetToken,
  reset,
  check_kyc,
  addKycPartners,
  fetchSummary,
  fetchWorkorders,
  fetchUnderwriterDetails,
  viewWorkorder,
  listInvoices,
  sendInvoice,
  acceptWorkorder,
  rejectWorkorder,
  sendReport,
  sendMemo,
  completeWorkorder,
  fetchAccounts,
  viewInvoice,
  addCostEstimate,
  list_taxes,
  list_fees,
  add_taxes,
  add_fees,
  fees_details,
  tax_details,
  list_services,
  list_admin_services,
  add_services,
  list_departments,
  create_department,
  list_roles,
  add_roles,
  list_users,
  create_user,
  list_user_groups,
  create_user_group,
  list_security_context,
  create_context,
  department_details,
  view_role_details,
  view_user,
  user_group_details,
  get_context_details,
  add_user_to_group,
  listSettlement,
  listConfigs,
  createConfig,
  listChannels,
  list_tax_category,
  calculateTax,
  calculateFee,
  getProfile,
};

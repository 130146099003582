import React from "react";
import Account from "./Account";
import { useQuery } from "react-query";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { api_srv } from "../../services";

const AccountWrapper = () => {
  const fetchAccounts = async () => {
    let resp = await (await api_srv).fetchAccounts();
    console.log("THE RESP FROM ACCOUNTS", resp);
    return resp.entity_accounts;
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

  const { isLoading, isSuccess, data } = useQuery("accounts", fetchAccounts);
  return (
    <div>
      {isLoading && (
        <Spin
          style={{ position: "absolute", top: "50%", left: "50%" }}
          indicator={antIcon}
        />
      )}
      {isSuccess && <Account state={data} />}
    </div>
  );
};

export default AccountWrapper;

import React from "react";
import { Modal } from "react-bootstrap";
import { LoadingOutlined, UserAddOutlined } from "@ant-design/icons";

import TransactionTable from "../table/transactionTable";

const Transactions = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="">
              <UserAddOutlined style={{ fontSize: "20px" }} />
              <span className="pl-2 pt-1">Transactions for Account 12345</span>
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransactionTable />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Transactions;

import Conversations from 'components/chat/Conversations';
import Messages from 'components/chat/Messages';
import React from 'react';
import bg from '../../assets/dist/img/tickets-bg.jpg';

const Chat = () => {
	return (
		<div className="hk-wrapper hk-vertical-nav">
			<div className="hk-pg-wrpper">
				{/* Container */}
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="chatapp-wrap container">
								<Conversations />
								<Messages />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Chat;

import React, { useContext, useReducer } from "react";
import Directors from "./Directors";
import { KycContext } from "../../../context/KycContext";

const DirectorsContainer = () => {
  const { directors, next } = useContext(KycContext);

  const initialState = {
    name: "",
    email: "",
    id_number: "",
    kra_pin: "",
    contact: "",
    address: "",
    postal_code: "",
    residency: "",
    passport: "",
    director_type: "",
  };

  const reducer = (state, action) => {
    if (action.type === "reset") {
      return initialState;
    }
    const result = { ...state };
    result[action.type] = action.value;
    return result;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  const addNewDirector = () => {
    directors.push(state);
    dispatch({ type: "reset" });
    window.scrollTo(0, 0);
  };

  const goNext = () => {
    if (JSON.stringify(state) === JSON.stringify(initialState)) {
      next();
    } else {
      directors.push(state);
      next();
    }
  };

  return (
    <Directors
      state={state}
      addNewDirector={addNewDirector}
      goNext={goNext}
      handleChange={onChange}
    />
  );
};

export default DirectorsContainer;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { LoadingOutlined, UserAddOutlined } from '@ant-design/icons';

import SettlementConfigForm from '../forms/settlement/config/ConfigLogic';

const AddConfig = (props) => {
	return (
		<div>
			<Modal
				{...props}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h6 className="">Add Configuration </h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<SettlementConfigForm refetch={props.refetch} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddConfig;

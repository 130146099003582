import React from "react";
import styles from "./Invoice.module.scss";
import Logo from "../../assets/dist/img/jubilee.png";
import { useQuery } from "react-query";
import { api_srv } from "../../services";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// import uuidv4 from 'uuid/v4'

export default function ViewInvoice() {
  const fetchInvoice = async () => {
    let invoiceId = sessionStorage.getItem("invoice_id");
    let resp = await (await api_srv).viewInvoice(invoiceId);
    console.log("THE RESP FROMINVOICE DETAILS", resp.sp_invoice[0]);
    return resp.sp_invoice[0];
  };

  const { isLoading, isSuccess, data } = useQuery(
    "invoice detail",
    fetchInvoice
  );
  const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;
  return (
    <>
      {isLoading && (
        <>
          <Spin
            style={{ position: "absolute", top: "50%", left: "50%" }}
            indicator={antIcon}
          />
          <p>Fetching invoice.....</p>
        </>
      )}
      {isSuccess && (
        <div className={styles.invoice}>
          <div className={styles.brand}>
            {/* <img src={Logo} alt="Logo" className={styles.logo} /> */}
          </div>
          <div className={styles.addresses}>
            <div className={styles.from}>
              <strong>kev kamau</strong>
              <br />
              P.O Box 6830
              <br />
              NAIROBI
              <br />
              test@email.com
            </div>

            <div>
              <strong className="pl-3 pb-10">Bill to:</strong>
              <div className={`${styles.valueTable} ${styles.to}`}>
                <div className={styles.row}>
                  <div className={styles.label}>Customer:</div>
                  <div className={styles.value}>{data.underwriter_name}</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Invoice No:</div>
                  <div className={styles.value}>1234</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Date:</div>
                  <div className={`${styles.value} ${styles.date}`}>
                    {data.created_date}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h6 className="pb-10 pt-40">{`Invoice under Claim reference 1234(Policy Number #qwsd1234)`}</h6>

          <div className="fmapp-table-view">
            <table id="fmapp_table_view" className="table table-hover w-100">
              <thead className="bg-light">
                <tr>
                  <th>Item Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.details.map((row) => (
                  <tr>
                    <td>
                      {row.name}
                      <p className="font-italic">~ {row.description}</p>
                    </td>

                    <td>{row.quantity}</td>
                    <td>{row.price}</td>

                    <td>{Number(row.quantity) * Number(row.price)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={styles.totalContainer}>
            <form>
              <div className={styles.valueTable}>
                <div className={styles.row}>
                  <div className={styles.label}>Tax ({data.tax[0]})</div>
                  <div className={`${styles.value} ${styles.currency}`}>
                    {(Number(data.tax[0]) / 100) * Number(data.total_amount)}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Total</div>
                  <div className={`${styles.value} ${styles.currency}`}>
                    {data.total_amount}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className={styles.footer}>
            <div className={styles.comments}>
              <h6>Notes</h6>
              Notes will appear here
            </div>
            <div className={styles.closing}>
              <div>Thank-you for your business</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

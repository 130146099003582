import React, { useState, createContext, useContext, useEffect } from 'react';
import { AlertsContext } from '../context/AlertsContext';

const AuthContext = createContext();
const AuthContextProvider = (props) => {
	const { setShowAlert, showAlert } = useContext(AlertsContext);
	// states

	const [servicesCategories, setServiceCategories] = useState([]);

	// verify token states

	const [confirmimgToken, setConfirmingToken] = useState(false);
	const [registrationId, setRegistrationId] = useState('');

	// alerts state

	const [alert, setAlert] = useState({
		message: '',
		variant: '',
	});

	useEffect(() => {
		if (showAlert) {
			setTimeout(() => {
				setShowAlert(false);
			}, 5000);
		}
	}, [alert, setShowAlert, showAlert]);

	return (
		<AuthContext.Provider
			value={{
				alert,
				setAlert,
				servicesCategories,
				setServiceCategories,
				confirmimgToken,
				setConfirmingToken,
				registrationId,
				setRegistrationId,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

const AuthConsumer = AuthContext.Consumer;
export { AuthContextProvider, AuthConsumer, AuthContext };

import React from 'react';
import Users from './Users';
import { list_roles, list_departments, list_users } from '../../services/apiSrv';
import { useQuery } from 'react-query';
import Loader from '../../components/loader';

const UsersContainer = () => {
	const _listUsers = async () => {
		let resp = await list_users();
		console.log('THE USERS', resp);
		return resp.users;
	};

	const _listRoles = async () => {
		let resp = await list_roles();
		console.log('THE ROLES', resp.roles);
		return resp.roles;
	};

	const _listDepartments = async () => {
		let resp = await list_departments();
		console.log('THE DEPS', resp.departments);
		return resp.departments;
	};
	const { data, isLoading, isSuccess, isError, refetch } = useQuery('listUsers', _listUsers);

	const {
		data: departments,
		isLoading: isLoadingDepartments,
		isSuccess: isSuccessDepartments,
		isError: isErrorDepartments,
	} = useQuery('listDepartments', _listDepartments);

	const { data: roles, isLoading: isLoadingRoles, isSuccess: isSuccessRoles, isError: isErrorRoles } = useQuery(
		'listRoles',
		_listRoles
	);
	return (
		<div>
			{isLoading && isLoadingDepartments && isLoadingRoles && <Loader />}
			{isSuccess && isSuccessRoles && isSuccessDepartments && (
				<Users users={data} departments={departments} roles={roles} refetch={refetch} />
			)}
		</div>
	);
};

export default UsersContainer;

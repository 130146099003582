import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import Image4 from "../../assets/dist/img/img4.jpg";
import Image1 from "../../assets/dist/img/img1.jpg";
import Image3 from "../../assets/dist/img/img3.jpg";
import SignUpFormContainer from "../../components/forms/signup/SignUpFormContainer";

export default function Signup({ servicesCategories }) {
  const options = {
    items: 1,
    dots: true,
    rewind: true,
    autoplay: true,
  };

  return (
    <div className="hk-wrapper">
      {/* Main Content */}
      <div className="hk-pg-wrapper hk-auth-wrapper">
        <header className="d-flex justify-content-between align-items-center">
          <a className="d-flex text-white auth-brand" href="./index.html">
            Nexus<span className="text-primary">Insurance</span>
          </a>
        </header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 pa-0">
              <OwlCarousel options={options}>
                <div
                  className="fadeOut item auth-cover-img overlay-wrap"
                  style={{ backgroundImage: `url(${Image4})` }}
                >
                  <div className="auth-cover-info py-xl-0 pt-100 pb-50">
                    <div className="auth-cover-content text-center w-xxl-75 w-sm-90 w-xs-100">
                      <h1 className="display-3 text-white mb-20">
                        Made just for you.
                      </h1>
                      <p className="text-white">
                        The purpose of lorem ipsum is to create a natural
                        looking block of text (sentence, paragraph, page, etc.)
                        that doesn't distract from the layout. Again during the
                        90s as desktop publishers bundled the text with their
                        software.
                      </p>
                    </div>
                  </div>
                  <div className="bg-overlay bg-trans-dark-50" />
                </div>
                <div
                  className="fadeOut item auth-cover-img overlay-wrap"
                  style={{ backgroundImage: `url(${Image3})` }}
                >
                  <div className="auth-cover-info py-xl-0 pt-100 pb-50">
                    <div className="auth-cover-content text-center w-xxl-75 w-sm-90 w-xs-100">
                      <h1 className="display-3 text-white mb-20">
                        Be life confident..
                      </h1>
                      <p className="text-white">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Nobis veniam culpa nemo rem obcaecati atque
                        perferendis facere ab omnis itaque.
                      </p>
                    </div>
                  </div>
                  <div className="bg-overlay bg-trans-dark-50" />
                </div>
                <div
                  className="fadeOut item auth-cover-img overlay-wrap"
                  style={{ backgroundImage: `url(${Image1})` }}
                >
                  <div className="auth-cover-info py-xl-0 pt-100 pb-50">
                    <div className="auth-cover-content text-center w-xxl-75 w-sm-90 w-xs-100">
                      <h1 className="display-3 text-white mb-20">
                        Your Future Protected.
                      </h1>
                      <p className="text-white">
                        The passage experienced a surge in popularity during the
                        1960s when Letraset used it on their dry-transfer
                        sheets, and again during the 90s as desktop publishers
                        bundled the text with their software.
                      </p>
                    </div>
                  </div>
                  <div className="bg-overlay bg-trans-dark-50" />
                </div>
              </OwlCarousel>
            </div>
            <div className="col-xl-6 pa-0">
              <div className="auth-form-wrap py-xl-0 py-50">
                <div className="auth-form w-xxl-65 w-xl-75 w-sm-90 w-xs-100">
                  <SignUpFormContainer servicesCategories={servicesCategories} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Main Content */}
    </div>
  );
}

import React from 'react'

export default function ProgressRequests() {
    return (
        <div>
          {/* HK Wrapper */}
      <div className="hk-wrapper hk-vertical-nav">
        {/* Top Navbar */}
        <nav className="navbar navbar-expand-xl navbar-light fixed-top hk-navbar">
          <a id="navbar_toggle_btn" className="navbar-toggle-btn nav-link-hover" href="javascript:void(0);"><span className="feather-icon"><i data-feather="menu" /></span></a>
          <a className="navbar-brand font-weight-700" href="./index.html">
            Nexus<span className="text-primary">Insurance</span>
          </a>
          <ul className="navbar-nav hk-navbar-content">
            <li className="nav-item">
              <a id="navbar_search_btn" className="nav-link nav-link-hover" href="javascript:void(0);"><span className="feather-icon"><i data-feather="search" /></span></a>
            </li>
            <li className="nav-item dropdown dropdown-notifications">
              <a className="nav-link dropdown-toggle no-caret" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="feather-icon"><i data-feather="bell" /></span><span className="badge-wrap"><span className="badge badge-primary badge-indicator badge-indicator-sm badge-pill pulse" /></span></a>
              <div className="dropdown-menu dropdown-menu-right" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                <h6 className="dropdown-header">Notifications <a href="javascript:void(0);" className>View all</a></h6>
                <div className="notifications-nicescroll-bar">
                  <a href="javascript:void(0);" className="dropdown-item">
                    <div className="media">
                      <div className="media-img-wrap">
                        <div className="avatar avatar-sm">
                          <img src="dist/img/avatar1.jpg" alt="user" className="avatar-img rounded-circle" />
                        </div>
                      </div>
                      <div className="media-body">
                        <div>
                          <div className="notifications-text"><span className="text-dark text-capitalize">Evie Ono</span> accepted your invitation to join the team</div>
                          <div className="notifications-time">12m</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="javascript:void(0);" className="dropdown-item">
                    <div className="media">
                      <div className="media-img-wrap">
                        <div className="avatar avatar-sm">
                          <img src="dist/img/avatar2.jpg" alt="user" className="avatar-img rounded-circle" />
                        </div>
                      </div>
                      <div className="media-body">
                        <div>
                          <div className="notifications-text">New message received from <span className="text-dark text-capitalize">Misuko Heid</span></div>
                          <div className="notifications-time">1h</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="javascript:void(0);" className="dropdown-item">
                    <div className="media">
                      <div className="media-img-wrap">
                        <div className="avatar avatar-sm">
                          <span className="avatar-text avatar-text-primary rounded-circle">
                            <span className="initial-wrap"><span><i className="zmdi zmdi-account font-18" /></span></span>
                          </span>
                        </div>
                      </div>
                      <div className="media-body">
                        <div>
                          <div className="notifications-text">You have a follow up with<span className="text-dark text-capitalize"> Marvin head</span> on <span className="text-dark text-capitalize">friday, dec 19</span> at <span className="text-dark">10.00 am</span></div>
                          <div className="notifications-time">2d</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="javascript:void(0);" className="dropdown-item">
                    <div className="media">
                      <div className="media-img-wrap">
                        <div className="avatar avatar-sm">
                          <span className="avatar-text avatar-text-success rounded-circle">
                            <span className="initial-wrap"><span>A</span></span>
                          </span>
                        </div>
                      </div>
                      <div className="media-body">
                        <div>
                          <div className="notifications-text">Application of <span className="text-dark text-capitalize">Sarah Williams</span> is waiting for your approval</div>
                          <div className="notifications-time">1w</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="javascript:void(0);" className="dropdown-item">
                    <div className="media">
                      <div className="media-img-wrap">
                        <div className="avatar avatar-sm">
                          <span className="avatar-text avatar-text-warning rounded-circle">
                            <span className="initial-wrap"><span><i className="zmdi zmdi-notifications font-18" /></span></span>
                          </span>
                        </div>
                      </div>
                      <div className="media-body">
                        <div>
                          <div className="notifications-text">Last 2 days left for the project</div>
                          <div className="notifications-time">15d</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown dropdown-authentication">
              <a className="nav-link dropdown-toggle no-caret" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="media">
                  <div className="media-img-wrap">
                    <div className="avatar avatar-sm">
                      <img src="dist/img/avatar12.jpg" alt="user" className="avatar-img rounded-circle" />
                    </div>
                    <span className="badge badge-success badge-indicator" />
                  </div>
                  <div className="media-body">
                    <span>Robert Njoroge<i className="zmdi zmdi-chevron-down" /></span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right" data-dropdown-in="flipInX" data-dropdown-out="flipOutX">
                <a className="dropdown-item" href="profile.html"><i className="dropdown-icon zmdi zmdi-account" /><span>Profile</span></a>
                <a className="dropdown-item" href="#"><i className="dropdown-icon zmdi zmdi-card" /><span>My balance</span></a>
                <a className="dropdown-item" href="inbox.html"><i className="dropdown-icon zmdi zmdi-email" /><span>Inbox</span></a>
                <a className="dropdown-item" href="#"><i className="dropdown-icon zmdi zmdi-settings" /><span>Settings</span></a>
                <div className="dropdown-divider" />
                <div className="sub-dropdown-menu show-on-hover">
                  <a href="#" className="dropdown-toggle dropdown-item no-caret"><i className="zmdi zmdi-check text-success" />Online</a>
                  <div className="dropdown-menu open-left-side">
                    <a className="dropdown-item" href="#"><i className="dropdown-icon zmdi zmdi-check text-success" /><span>Online</span></a>
                    <a className="dropdown-item" href="#"><i className="dropdown-icon zmdi zmdi-circle-o text-warning" /><span>Busy</span></a>
                    <a className="dropdown-item" href="#"><i className="dropdown-icon zmdi zmdi-minus-circle-outline text-danger" /><span>Offline</span></a>
                  </div>
                </div>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href="#"><i className="dropdown-icon zmdi zmdi-power" /><span>Log out</span></a>
              </div>
            </li>
          </ul>
        </nav>
        <form role="search" className="navbar-search">
          <div className="position-relative">
            <a href="javascript:void(0);" className="navbar-search-icon"><span className="feather-icon"><i data-feather="search" /></span></a>
            <input type="text" name="example-input1-group2" className="form-control" placeholder="Type here to Search" />
            <a id="navbar_search_close" className="navbar-search-close" href="#"><span className="feather-icon"><i data-feather="x" /></span></a>
          </div>
        </form>
        {/* /Top Navbar */}
        {/* Vertical Nav */}
        <nav className="hk-nav hk-nav-dark">
          <a href="javascript:void(0);" id="hk_nav_close" className="hk-nav-close"><span className="feather-icon"><i data-feather="x" /></span></a>
          <div className="nicescroll-bar">
            <div className="navbar-nav-wrap">
              <ul className="navbar-nav flex-column">
                <li className="nav-item">
                  <a className="nav-link" href="dashboard.html">
                    <span className="feather-icon"><i data-feather="grid" /></span>
                    <span className="nav-link-text">Dashboard</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " href="javascript:void(0);" data-toggle="collapse" data-target="#app_drp">
                    <span className="feather-icon"><i data-feather="layers" /></span>
                    <span className="nav-link-text">My Profile</span>
                  </a>
                  <ul id="app_drp" className="nav flex-column collapse collapse-level-1">
                    <li className="nav-item">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <a className="nav-link" href="#">Option</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#">Option</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#">Option</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#">Option</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="javascript:void(0);">
                    <span className="feather-icon"><i data-feather="settings" /></span>
                    <span className="nav-link-text">Account</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#users">
                    <span className="feather-icon"><i data-feather="users" /></span>
                    <span className="nav-link-text">Users</span>
                  </a>
                  <ul id="users" className="nav flex-column collapse collapse-level-1">
                    <li className="nav-item">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <a className="nav-link" href="javascript:void(0);">
                            Option
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="javascript:void(0);">
                            Option
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="javascript:void(0);">
                            Option
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item active">
                  <a className="nav-link link-with-badge" href="javascript:void(0);" data-toggle="collapse" data-target="#requests">
                    <span className="feather-icon"><i data-feather="globe" /></span>
                    <span className="nav-link-text">Requests</span>
                    <span className="badge badge-primary badge-pill">4</span>
                  </a>
                  <ul id="requests" className="nav flex-column collapse collapse-level-1">
                    <li className="nav-item">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <a className="nav-link link-with-badge" href="requests-pending.html">
                            Pending <span className="badge badge-primary badge-pill">4</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="requests-progress.html">
                            In Progress
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="kyc.html">
                    <span className="feather-icon"><i data-feather="shield" /></span>
                    <span className="nav-link-text">KYC</span>
                  </a>
                </li>
                <li className="nav-item terms">
                  <a className="nav-link" href="javascript:void(0);">
                    <span className="feather-icon"><i data-feather="file-text" /></span>
                    <span className="nav-link-text">Terms and Conditions</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div id="hk_nav_backdrop" className="hk-nav-backdrop" />
        {/* /Vertical Nav */}
        {/* Main Content */}
        <div className="hk-pg-wrapper">
          {/* Breadcrumb */}
          <nav className="hk-breadcrumb" aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-light bg-transparent">
              <li className="breadcrumb-item"><a href="#">Requests</a></li>
              <li className="breadcrumb-item active" aria-current="page">In Progress</li>
            </ol>
          </nav>
          {/* /Breadcrumb */}
          {/* Container */}
          <div className="container">
            {/* Title */}
            <div className="hk-pg-header mb-10">
              <div>
                <h4 className="hk-pg-title"><span className="pg-title-icon"><span className="feather-icon"><i data-feather="activity" /></span></span>In Progress</h4>
              </div>
              <div className="d-flex dropdown">
                <button className="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">latest Date</button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#">Action</a>
                  <a className="dropdown-item" href="#">Another action</a>
                  <a className="dropdown-item" href="#">Something else here</a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
            </div>
            {/* /Title */}
            {/* Row */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card card-lg">
                  <div className="card-header py-30">
                    <div className="float-left">
                      Lorem, ipsum.
                    </div>
                    <form role="search" className="chat-search float-right col-md-4">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search" />
                      </div>
                    </form>
                  </div>							
                  <div className="card-body">
                    <div className="user-activity">
                      <div className="media">
                        <div className="media-img-wrap">
                          <div className="avatar avatar-lg">
                            <img src="dist/img/avatar3.jpg" alt="user" className="avatar-img rounded-circle" />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="d-flex align-items-start">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Sanlam Insurance</span></span>
                              <span className="text-dark font-weight-800 mb-30 mr-5">Created Date: </span><span className>08/04/2020</span>
                            </div>
                            <a href="/requests/in-progress/view" type="button" className="btn btn-outline-primary btn-sm ml-auto">view</a>
                          </div>
                          <div className="d-flex flex-wrap mt-10">
                            <span className="text-dark font-weight-800">Description: </span><span className="d-block mb-10">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, alias voluptatem! Corrupti labore exercitationem libero commodi odit ipsa, autem molestias.</span>
                            <span className="text-dark font-weight-800 mb-30 mr-5">Reference: </span><span className>BXR54T</span>
                          </div>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-img-wrap">
                          <div className="avatar avatar-lg">
                            <img src="dist/img/avatar3.jpg" alt="user" className="avatar-img rounded-circle" />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="d-flex align-items-start">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Sanlam Insurance</span></span>
                              <span className="text-dark font-weight-800 mb-30 mr-5">Created Date: </span><span className>08/04/2020</span>
                            </div>
                            <a href="details.html" type="button" className="btn btn-outline-primary btn-sm ml-auto">view</a>
                          </div>
                          <div className="d-flex flex-wrap mt-10">
                            <span className="text-dark font-weight-800">Description: </span><span className="d-block mb-10">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, alias voluptatem! Corrupti labore exercitationem libero commodi odit ipsa, autem molestias.</span>
                            <span className="text-dark font-weight-800 mb-30 mr-5">Reference: </span><span className>BXR54T</span>
                          </div>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-img-wrap">
                          <div className="avatar avatar-lg">
                            <img src="dist/img/avatar3.jpg" alt="user" className="avatar-img rounded-circle" />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="d-flex align-items-start">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Sanlam Insurance</span></span>
                              <span className="text-dark font-weight-800 mb-30 mr-5">Created Date: </span><span className>08/04/2020</span>
                            </div>
                            <a href="details.html" type="button" className="btn btn-outline-primary btn-sm ml-auto">view</a>
                          </div>
                          <div className="d-flex flex-wrap mt-10">
                            <span className="text-dark font-weight-800">Description: </span><span className="d-block mb-10">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, alias voluptatem! Corrupti labore exercitationem libero commodi odit ipsa, autem molestias.</span>
                            <span className="text-dark font-weight-800 mb-30 mr-5">Reference: </span><span className>BXR54T</span>
                          </div>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-img-wrap">
                          <div className="avatar avatar-lg">
                            <img src="dist/img/avatar3.jpg" alt="user" className="avatar-img rounded-circle" />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="d-flex align-items-start">
                            <div>
                              <span className="d-block mb-5"><span className="font-weight-500 text-dark text-capitalize">Sanlam Insurance</span></span>
                              <span className="text-dark font-weight-800 mb-30 mr-5">Created Date: </span><span className>08/04/2020</span>
                            </div>
                            <a href="details.html" type="button" className="btn btn-outline-primary btn-sm ml-auto">view</a>
                          </div>
                          <div className="d-flex flex-wrap mt-10">
                            <span className="text-dark font-weight-800">Description: </span><span className="d-block mb-10">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, alias voluptatem! Corrupti labore exercitationem libero commodi odit ipsa, autem molestias.</span>
                            <span className="text-dark font-weight-800 mb-30 mr-5">Reference: </span><span className>BXR54T</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Row */}
          </div>
          {/* /Container */}
          {/* Footer */}
          <div className="hk-footer-wrap container">
            <footer className="footer">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <p>NexusInsurance © 2020</p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <p className="d-inline-block">Follow us</p>
                  <a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-facebook" /></span></a>
                  <a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-twitter" /></span></a>
                  <a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-google-plus" /></span></a>
                </div>
              </div>
            </footer>
          </div>
          {/* /Footer */}
        </div>
        {/* /Main Content */}
      </div>
        </div>
    )
}

import React, { useContext } from "react";
import * as Icon from "react-feather";
import { KycContext } from "../../context/KycContext";




export default function Individual({onChangeHandler}) {
  const {prev, next} = useContext(KycContext);


  return (

  <form onSubmit={()=>next()}>
      <p>Step 3</p>
      <h4 className="title-background">Upload the documents to proceed</h4>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas
        dolor expedita optio unde explicabo nemo? Eius, non.Lorem ipsum dolor
        sit, amet consectetur adipisicing elit. Aut voluptas dolor expedita
        optio unde explicabo nemo? Eius, non.
      </p>
      <div className="row">
        <div className="col-md-6 pb-3">
          <div className="form-group">
            <label className="font-weight-bold d-block">
              Identification Card
            </label>
          
           
            <input 
            required
            type="file" 
            name="id_card" 
            onChange={onChangeHandler} />
          </div>
        </div>

        <div className="col-md-6 pb-3">
          <div className="form-group">
            <div className="form-group">
              <label className="font-weight-bold d-block">KRA Pin</label>
              <input 
              required 
              type="file" 
              name="kra_pin"
               onChange={onChangeHandler} />
                   
            </div>
          </div>
        </div>
      </div>
      <button
        className=" float-right btn mb-45 mt-30 btn-primary"
        type="submit"
     
      >
        Next
        <Icon.ArrowRight size={22} className="pl-1" />
      </button>
      <button
        className=" float-right btn mb-45 mt-30 btn-secondary mr-3"
        onClick={prev}
      >
        <Icon.ArrowLeft size={22} className="pr-1" />
        Previous
      </button>
    </form>
   
 

  );
}

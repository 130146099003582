import React, { useContext, useRef, useState } from 'react';
// import Jubilee from "../../assets/dist/img/britam.png";
import Avatar from '../../assets/dist/img/avatar2.jpg';
import Document_pdf from '../../assets/dist/img/pdf.png';
import Photo from '../../assets/dist/img/jpg-icon.png';
import Folder from '../../assets/dist/img/file-icon.png';
import Doc from '../../assets/dist/img/doc-icon.png';
import Png from '../../assets/dist/img/png-icon.png';
import Navbar from '../../components/navbar/DashboardNavbarContainer';
import Sidebar from '../../components/sidebar/Sidebar';
import TextEditor from '../../components/text-editor/TextEditor';
import { ModalContext } from '../../context/ModalContext';
import { DashboardContext } from '../../context/DashboardContext';
import Message from '../../components/modals/SendMessage';
import Report from '../../components/modals/AddReport';
import Date from '../../components/modals/CompletionTime';
import Reject from '../../components/modals/Reject';
import { Button, Menu, Dropdown, Spin, message, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import * as Icon from 'react-feather';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DownOutlined, FileOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import Moment from 'react-moment';
import { api_srv } from '../../services';

export default function ViewWorkorder({
	state,
	showTemplate,
	setShowTemplate,
	accept,
	reject,
	sp_workOrder_id,
	get_workorder_details,
}) {
	const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;
	const { confirm } = Modal;
	const history = useHistory();
	const menu = (
		<Menu>
			<Menu.Item key="1" icon={<UploadOutlined />} onClick={() => setModalShow('report')}>
				Upload
			</Menu.Item>
			<Menu.Item key="2" icon={<FileOutlined />} onClick={() => setShowTemplate(true)}>
				Template
			</Menu.Item>
		</Menu>
	);

	function showIcon(extension) {
		switch (extension) {
			case 'pdf':
				return <img className="file-preview mr-10" src={Document_pdf} alt="fm-img" />;
			case 'jpeg':
				return <img className="file-preview mr-10" src={Photo} alt="fm-img" />;
			case 'png':
				return <img className="file-preview mr-10" src={Png} alt="fm-img" />;
			case 'docx':
				return <img className="file-preview mr-10" src={Doc} alt="fm-img" />;

			default:
				return <img className="file-preview mr-10" src={Folder} alt="fm-img" />;
		}
	}

	function showConfirm() {
		confirm({
			title: 'You have chosen to decline this workorder?',
			icon: <ExclamationCircleOutlined />,
			content: (
				<div className="form-group">
					<label htmlFor="remarks">give reason for rejecting the workorder</label>
					<textarea
						className="form-control"
						name="remarks"
						required
						// value={rejectionRemarks.data}
						rows={2}
						// onChange={handleChange}
					/>
				</div>
			),
			onOk() {
				reject();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const complete = async () => {
		try {
			let resp = await (await api_srv).completeWorkorder(workOrderDetails.data.sp_workOrder_id);
			console.log('Marked as completed', resp);
			history.push(routes.workorders);
			message.success(`${resp.status} Request sent`);
		} catch (err) {
			let error = await err;
			console.log('Could not mark workorder as completed', error);
		}
	};
	function confirmCompletion() {
		confirm({
			title: 'Are you sure you wish to mark this workorder as completed and notify the underwriter?',
			icon: <ExclamationCircleOutlined />,

			onOk() {
				complete();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	// MODAL CONTEXT

	const modalContext = useContext(ModalContext);
	const { setModalShow, modalShow, modalClose } = modalContext;
	const [show, setShow] = useState(false);
	const { workOrderDetails } = useContext(DashboardContext);

	return (
		<div className="hk-wrapper hk-vertical-nav">
			<Message
				show={modalShow === 'message' ? true : false}
				onHide={modalClose}
				sp_workOrder_id={sp_workOrder_id}
			/>
			<Report
				show={modalShow === 'report' ? true : false}
				onHide={modalClose}
				get_workorder_details={get_workorder_details}
			/>
			<Date show={modalShow === 'date' ? true : false} onHide={modalClose} accept={accept} />
			<Reject show={modalShow === 'reject' ? true : false} onHide={modalClose} reject={reject} />

			{/* Container */}
			<div className="container-fluid">
				{!state.data ? (
					<div className="d-flex justify-content-center align-items-center">
						<Spin className="text-center" indicator={antIcon} />
					</div>
				) : (
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="profile-cover-wrap overlay-wrap">
								<div
									className="profile-cover-img"
									// style={{backgroundImage: `url(${Jubilee})`, backgroundSize:"cover", backgroundPosition: "center" }}
								/>
								<div className="bg-overlay bg-trans-dark-60" />
								<div className="container profile-cover-content py-50">
									<div className="hk-row">
										<div className="col-lg-6">
											<div className="media align-items-center">
												<div className="media-img-wrap  d-flex">
													<div
														className="avatar rounded-circle"
														style={{
															// backgroundImage: `url(${Jubilee})`,
															backgroundSize: 'cover',
															backgroundPosition: 'center',
														}}
													>
														{/* <img src={Sanlam} alt="Logo" className="avatar-img rounded-circle" /> */}
													</div>
												</div>
												<div className="media-body">
													<div className="text-white text-capitalize display-6 mb-5 font-weight-400">
														{state.data.underwritter_name}
													</div>
													<div className="font-14 text-white">
														<span className="mr-5">
															<span className="font-weight-500 pr-5">Date created:</span>
															<span className="mr-5">
																<Moment format="DD/MM/YYYY">
																	{state.data.created_date}
																</Moment>
															</span>
														</span>
														<span>
															<span className="font-weight-500 pr-5">Time:</span>
															<span>
																<Moment format="hh:mm:ss">
																	{state.data.created_date}
																</Moment>
															</span>
														</span>
													</div>
													<div className="font-14 text-white">
														<span className="mr-5">
															<span className="font-weight-500 pr-5">Status:</span>
															<span className="mr-5">{state.data.work_order_status}</span>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-white shadow-bottom">
								<div className="container">
									<ul className="nav nav-light nav-tabs" role="tablist">
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link active"
												id="nav-general-tab"
												data-toggle="tab"
												href="#nav-general"
												role="tab"
												aria-controls="nav-general"
												aria-selected="true"
											>
												General Info
											</a>
										</li>
										{/* <li className="nav-item">
                      <a
                        className="d-flex h-60p align-items-center nav-link"
                        id="nav-documents-tab"
                        data-toggle="tab"
                        href="#nav-documents"
                        role="tab"
                        aria-controls="nav-documents"
                        aria-selected="false"
                      >
                        Documents
                      </a>
                    </li> */}
										{/* <li className="nav-item">
                      <a
                        className="d-flex h-60p align-items-center nav-link"
                        id="nav-documents-tab"
                        data-toggle="tab"
                        href="#nav-photos"
                        role="tab"
                        aria-controls="nav-photos"
                        aria-selected="false"
                      >
                        Photos
                      </a>
                    </li> */}
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link"
												id="nav-report-tab"
												data-toggle="tab"
												href="#nav-report"
												role="tab"
												aria-controls="nav-report"
												aria-selected="false"
											>
												Reports
											</a>
										</li>
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link"
												id="nav-messages-tab"
												data-toggle="tab"
												href="#nav-messages"
												role="tab"
												aria-controls="nav-messages"
												aria-selected="false"
											>
												Memos
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="tab-content mt-sm-60 mt-30">
								<div
									className="tab-pane fade show active"
									role="tabpanel"
									id="nav-general"
									aria-labelledby="nav-general-tab"
								>
									<div className="container">
										<div className="hk-row">
											<div className="col-lg-8">
												<div className="card card-profile-feed">
													<div className="card-header card-header-action">
														<div className="media align-items-center">
															<div className="media-img-wrap d-flex mr-10">
																<div className="avatar avatar-sm">
																	<img
																		src={Avatar}
																		alt="user"
																		className="avatar-img rounded-circle"
																	/>
																</div>
															</div>
															<div className="media-body">
																<div className="font-13">Job Reference</div>
																<div className="text-capitalize font-weight-500 text-dark">
																	{state.data.work_order_ref}
																</div>
															</div>
														</div>
													</div>
													<div className="card-body">
														<div className="text-capitalize font-weight-500 text-dark">
															Description
														</div>
														<p className="card-text mb-30">{state.data.request_remarks}</p>
													</div>
													<div className="card-footer justify-content-between">
														<div className="">
															{state.data.work_order_status === 'pending' ? (
																<>
																	<a
																		href
																		type="button"
																		onClick={() => showConfirm()}
																		className="text-danger"
																	>
																		<i className="ion ion-md-close-circle text-danger" />
																		Decline
																	</a>
																</>
															) : state.data.work_order_status ===
															  'active: pending approval' ? (
																<>
																	<a
																		href
																		type="button"
																		onClick={() => showConfirm()}
																		className="text-danger"
																	>
																		<i className="ion ion-md-close-circle text-danger" />
																		Decline
																	</a>
																</>
															) : null}
														</div>
														<div className="ml-auto">
															{state.data.work_order_status === 'pending' ? (
																<>
																	<a
																		href
																		type="button"
																		onClick={() => setModalShow('date')}
																		className="text-success"
																	>
																		<i className="ion ion-md-checkmark text-success" />
																		Accept
																	</a>
																</>
															) : state.data.work_order_status === 'active:approved' ? (
																<>
																	<a
																		href
																		type="button"
																		onClick={() => confirmCompletion()}
																		className="text-success"
																	>
																		<i className="ion ion-md-checkmark text-success" />
																		Mark as Complete
																	</a>
																</>
															) : null}
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="card card-profile-feed">
													<div className="card-header card-header-action">
														<div className="media align-items-center">
															<div className="media-body">
																<div className="text-capitalize font-weight-500 text-dark">
																	Additional Information
																</div>
															</div>
														</div>
													</div>
													<ul className="list-group list-group-flush">
														<li className="list-group-item">
															<span>
																<i className="ion ion-md-document font-18 text-light-20 mr-10" />
																<span>Claim Reference:</span>
															</span>
															<span className="ml-5 text-dark">
																{state.data.claim_ref}{' '}
															</span>
														</li>
														<li className="list-group-item">
															<span>
																<i className="ion ion-md-information-circle font-18 text-light-20 mr-10" />
																<span>Claim Type:</span>
															</span>
															<span className="ml-5 text-dark">
																{state.data.claim_type}
															</span>
														</li>

														{/* <li className="list-group-item">
                            <span>
                              <i className="ion ion-md-pin font-18 text-light-20 mr-10" />
                              <span>Location of Garage:</span>
                            </span>
                            <span className="ml-5 text-dark">Mombasa Road</span>
                          </li> */}
														<li className="list-group-item">
															<span>
																<i className="ion ion-md-call font-18 text-light-20 mr-10" />
																<span>Client Contact</span>
															</span>
															<span className="ml-5 text-dark">0720 123 456</span>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade show"
									role="tabpanel"
									id="nav-documents"
									aria-labelledby="nav-documents-tab"
								>
									<div className="container">
										<div className="fmapp-wrap">
											<div className="fm-box">
												<div className="fmapp-main fmapp-view-switch">
													<header>
														<div className="fm-options-wrap">
															<a
																href
																id="fmapp_sidebar_move"
																className="fmapp-sidebar-move"
															>
																<span className="feather-icon">
																	<i data-feather="menu" />
																</span>
															</a>
															<a href>
																<span className="feather-icon">
																	<i data-feather="search" />
																</span>
															</a>
														</div>
														<span className>Documents Uploaded</span>
														<div className="fm-options-wrap">
															<span className="btn-file text-primary">
																<input type="file" className="upload" />
																<span className="feather-icon">
																	<i data-feather="upload" />
																</span>
															</span>
															<a href id="fm_view_switcher" className="fm-view-switcher">
																<span className="feather-icon list-view">
																	<Icon.List />
																</span>
																<span className="feather-icon grid-view">
																	<Icon.Grid />
																</span>
															</a>
														</div>
													</header>
													<div className="fm-body mt-75">
														<div className="nicescroll-bar">
															<div className="fmapp-view-wrap">
																<div className="fmapp-grid-view">
																	<div className="row">
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_1.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_2.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_3.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_4.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_5.pdf
																			</span>
																		</div>
																	</div>
																</div>
																<div className="fmapp-table-view">
																	<table
																		id="fmapp_table_view"
																		className="table table-hover w-100"
																	>
																		<thead>
																			<tr>
																				<th>Name of document</th>
																				<th>Date Uploaded</th>
																				<th>size</th>
																				<th>type</th>
																				<th />
																			</tr>
																		</thead>
																		<tbody>No documents currently</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade show "
									role="tabpanel"
									id="nav-photos"
									aria-labelledby="nav-photos-tab"
								>
									<div className="container">
										<div>
											<h6 className="mt-30 mb-20">22 October</h6>
											<div className="row hk-gallery">No photos currently</div>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade show "
									role="tabpanel"
									id="nav-report"
									aria-labelledby="nav-report-tab"
								>
									<div className="container">
										{showTemplate ? (
											<>
												<Button
													className="float-right btn-primary"
													onClick={() => setShowTemplate(false)}
												>
													<Icon.CornerUpLeft size={16} /> Back to Reports
												</Button>
												<br />
												<br />

												<TextEditor />
											</>
										) : (
											<>
												<Dropdown overlay={menu}>
													<Button className="float-right">
														Add Report <DownOutlined />
													</Button>
												</Dropdown>
												{/* <Button className="float-right btn-primary"   onClick={() => setModalShow("report")}>Add Report</Button> */}
												<div className="nicescroll-bar">
													<div className="fmapp-view-wrap">
														<div className="fmapp-table-view">
															<table
																id="fmapp_table_view"
																className="table table-hover w-100"
															>
																<thead>
																	<tr>
																		<th>Name of document</th>
																		<th>Date Uploaded</th>
																		<th>Description</th>
																		<th>type</th>
																		<th />
																	</tr>
																</thead>
																<tbody>
																	{state.data.workorder_reports.length === 0
																		? 'No reports currently'
																		: state.data.workorder_reports.map((report) => {
																				function download() {
																					const url = window.URL.createObjectURL(
																						new Blob([
																							process.env
																								.REACT_APP_UAT_URL +
																								`${report.report_path}`,
																						])
																					);
																					const link = document.createElement(
																						'a'
																					);
																					link.href = url;
																					link.setAttribute(
																						'download',

																						`${report.report_name}`
																					);
																					document.body.appendChild(link);
																					link.click();
																				}
																				return (
																					<tr>
																						<td>
																							<span className="d-flex align-items-center">
																								{showIcon(
																									report.report_type
																								)}
																								<span className="file-name">
																									{report.report_name}
																								</span>
																							</span>
																						</td>
																						<td>
																							<span className="mr-10">
																								20/11/2015
																							</span>
																							<span className="file-time-modified inline-block">
																								10:11
																							</span>
																						</td>
																						<td>
																							{report.report_description}
																						</td>
																						<td>{`${report.report_type} file`}</td>
																						<td>
																							<span className="files-more-link">
																								<a
																									rel="noopener noreferrer"
																									target="_blank"
																									href={
																										process.env
																											.REACT_APP_UAT_URL +
																										`${report.report_path}`
																									}
																									// onClick={() => download()}
																									download
																								>
																									preview
																									{/* <i className="zmdi zmdi-download" /> */}
																								</a>
																							</span>
																						</td>
																					</tr>
																				);
																		  })}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
								</div>

								<div
									className="tab-pane fade show "
									role="tabpanel"
									id="nav-messages"
									aria-labelledby="nav-messages-tab"
								>
									<div className="container">
										{!show ? (
											<>
												<Button
													onClick={() => setModalShow('message')}
													className="float-right text-primary"
												>
													New Memo
												</Button>
												{state.data.Memo.length === 0
													? 'No memos currently'
													: state.data.Memo.map((memo, i) => (
															<div key={i} className="email-box clearfix pt-25 ">
																<div className="emailapp-emails-list">
																	<div className="nicescroll-bar">
																		<a
																			href
																			onClick={() => setShow(!show)}
																			className="media"
																		>
																			<div className="media-img-wrap">
																				<div className="avatar">
																					<img
																						src={Avatar}
																						alt="user"
																						className="avatar-img rounded-circle"
																					/>
																				</div>
																			</div>
																			<div className="media-body d-flex flex-wrap justify-content-between pl-3">
																				<div>
																					<div className="email-subject">
																						<b> {memo.subject}</b>
																					</div>
																					<div className="email-text">
																						<p>{memo.memo}</p>
																					</div>
																				</div>
																				<div>
																					<div className="last-email-details">
																						<span className="badge badge-primary badge-indicator" />{' '}
																						2:30 PM
																					</div>
																				</div>
																			</div>
																		</a>
																		<div className="email-hr-wrap">
																			<hr />
																		</div>
																	</div>
																</div>
															</div>
													  ))}
											</>
										) : (
											<>
												<div className="email-box">
													<div className="emailapp-right">
														<header>
															<a
																id="back_email_list"
																href="javascript:void(0)"
																className="back-email-list"
															>
																<span className="feather-icon">
																	<i data-feather="chevron-left" />
																</span>
															</a>
															<div
																onClick={() => setShow(!show)}
																className="email-options-wrap"
															>
																<a href="javascript:void(0)" className>
																	<span className="feather-icon"></span>
																	Back
																</a>
															</div>
														</header>
														<br />
														<br />
														<div className="email-body">
															<div className="nicescroll-bar">
																<div>
																	<div className="email-subject-head">
																		<h4>Memo Title</h4>
																	</div>
																	<hr className="mt-10 mb-20" />
																	<div className="email-head">
																		<div className="media">
																			<div className="media-img-wrap">
																				<div className="avatar">
																					<img
																						src="dist/img/avatar1.jpg"
																						alt="user"
																						className="avatar-img rounded-circle"
																					/>
																				</div>
																			</div>
																			<div className="media-body">
																				<span className="text-capitalize sender-name d-inline-block">
																					Evie ono
																				</span>
																				<span className="sender-email d-inline-block">
																					(evieono@Marvin.com)
																				</span>
																				<span className="d-block">
																					to
																					<span>me</span>
																				</span>
																			</div>
																		</div>
																		<div className="head-other-wrap">
																			<div className="d-inline-block mr-5">
																				<span className="d-inline-block">
																					10:06 AM
																				</span>
																				<span className="d-inline-block">
																					(2 hours ago)
																				</span>
																			</div>
																			<div className="d-inline-block dropdown">
																				<a
																					className="dropdown-toggle no-caret"
																					data-toggle="dropdown"
																					href="#"
																					aria-expanded="false"
																					role="button"
																				>
																					<i className="zmdi   zmdi-more-vert" />
																				</a>
																				<div
																					className="dropdown-menu bullet dropdown-menu-right"
																					role="menu"
																				>
																					<a
																						className="dropdown-item"
																						href="javascript:void(0)"
																						role="menuitem"
																					>
																						<i
																							className="icon wb-reply"
																							aria-hidden="true"
																						/>
																						Reply
																					</a>

																					<a
																						className="dropdown-item"
																						href="javascript:void(0)"
																						role="menuitem"
																					>
																						<i
																							className="icon wb-trash"
																							aria-hidden="true"
																						/>
																						Delete
																					</a>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="email-text-wrap mt-30">
																		<span className="d-block email-title text-capitalize mb-30">
																			dear sir,
																		</span>
																		<p className="mb-30">
																			Faucibus rutrum. Phasellus sodales vulputate
																			urna, vel accumsan augue egestas ac. Donec
																			vitae leo at sem lobortis porttitor eu
																			consequat risus. Mauris sed congue orci.
																			Donec ultrices faucibus rutrum. Phasellus
																			sodales vulputate urna, vel accumsan augue
																			egestas ac. Donec vitae leo at sem lobortis
																			porttitor eu consequat risus.
																		</p>
																		<p>
																			Donec vitae leo at sem lobortis porttitor eu
																			consequat risus. Mauris sed congue
																			orci.Mauris sed congue orci. Donec ultrices
																			faucibus rutrum. Phasellus sodales vulputate
																			urna, vel accumsan augue egestas ac
																		</p>
																		<p className="mt-30">
																			Thank you for your time.
																		</p>
																		<div className="email-end-detail mt-35">
																			<span className="text-capitalize d-block">
																				evie one
																			</span>
																			<span className="d-block">+12 234 443</span>
																			<span className="d-block">
																				evieone@Marvin.com
																			</span>
																		</div>
																	</div>
																	<hr className="hr-light" />
																	<div className="email-attachment-wrap">
																		<div className="email-attachment-block">
																			<a href="javascript:void(0)">
																				<div className="card card-sm w-200p">
																					<div className="card-body d-flex align-items-center">
																						<img
																							src="dist/img/jpgicon.png"
																							className="d-inline-block mr-10"
																							alt="attachicon"
																						/>
																						<span className="d-inline-block mnw-0">
																							<span className="d-block file-name text-truncate">
																								concept_design.jpg
																							</span>
																							<small className="d-block file-size text-truncate">
																								5.78 MB
																							</small>
																						</span>
																					</div>
																				</div>
																			</a>
																			<a href="javascript:void(0)">
																				<div className="card card-sm w-200p">
																					<div className="card-body d-flex align-items-center">
																						<img
																							src="dist/img/pdficon.png"
																							className="d-inline-block mr-10"
																							alt="attachicon"
																						/>
																						<span className="d-inline-block mnw-0">
																							<span className="d-block file-name text-truncate">
																								Design-titleccv.pdf
																							</span>
																							<small className="d-block file-size text-truncate">
																								2.1 MB
																							</small>
																						</span>
																					</div>
																				</div>
																			</a>
																		</div>
																		<div className="d-flex ml-auto">
																			<a href="javascript:void(0)">
																				<i className="zmdi zmdi-download" />
																			</a>
																			<a href="javascript:void(0)">
																				<i className="zmdi zmdi-more" />
																			</a>
																		</div>
																	</div>
																	<hr className="hr-light" />
																	<div className="email-reply-block">
																		Click here to{' '}
																		<a
																			href="javascript:void(0)"
																			className="text-capitalize"
																		>
																			reply
																		</a>{' '}
																		or{' '}
																		<a
																			href="javascript:void(0)"
																			className="text-capitalize"
																		>
																			forwrd
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{/* /Row */}
			</div>
			{/* /Container */}
		</div>
	);
}

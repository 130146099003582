import React, { useContext} from "react";
import {Redirect } from "react-router-dom";
import {AuthContext} from '../../context/AuthContext'
import routes from "../../routes";
import Spinner from '../../components/loader/PageLoader'

export default function VerifyToken({registrationId}) {



    console.log("TESTS HERE",registrationId )
  
    const styles = {
        height: "70px",
        width: "70px",
    };

  return (
    <div className="centerdiv">
      {(registrationId === "") ? 
   <>
      {console.log("NO REG ID PRESENT")}
          <Spinner style={styles} />
          </>
   
      : (registrationId !== "") ? 
      <>
      {console.log("REG ID IS PRESENT")}
      
           <Redirect to={routes.verify} />
           </>
    
      :  
      <>
        {console.log("REG ID IN DOUBT")}
      <Spinner style={styles} /> 
      </>
      }
    </div>
  );
}

import React, { useContext, useReducer } from "react";
import Partners from "./Partners";
import { KycContext } from "../../../context/KycContext";

const PartnersContainer = () => {
  const { partners, next } = useContext(KycContext);

  const initialState = {
    name: "",
    email: "",
    id_number: "",
    kra_pin: "",
    contact: "",
    address: "",
    postal_code: "",
    residency: "",
    passport: "",
    partner_type: "",
  };

  const reducer = (state, action) => {
    if (action.type === "reset") {
      return initialState;
    }
    const result = { ...state };
    result[action.type] = action.value;
    return result;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  const addNewPartner = () => {
    partners.push(state);
    dispatch({ type: "reset" });
    window.scrollTo(0, 0);
  };

  const goNext = () => {
    if (JSON.stringify(state) === JSON.stringify(initialState)) {
      next();
    } else {
      partners.push(state);
      next();
    }
  };
  return (
    <Partners
      onChange={onChange}
      state={state}
      addNewPartner={addNewPartner}
      goNext={goNext}
    />
  );
};

export default PartnersContainer;

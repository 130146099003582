import React,{useContext, useReducer} from 'react'
import JointOwners from './JointOwners'
import {KycContext} from '../../../context/KycContext'

const JointOwnersContainer = () => {
  const {jointOwners, next, provider}= useContext(KycContext)

  const initialState ={
    name: "",
    email: "",
    id_number: "",
    kra_pin: "",
    contact: "",
    address: "",
    postal_code: "",
    residency: "",
    passport: "",
  }


  const reducer =(state, action)=>{
    if(action.type==='reset'){
      return initialState
    }
    const result = {...state}
    result[action.type]=action.value;
    return result
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = e => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
};

const addNewOwner = () => {
  jointOwners.push(state)
  dispatch({ type: "reset" });
  window.scrollTo(0, 0)
};

const goNext=()=>{
  if(JSON.stringify(state) === JSON.stringify(initialState)){
    next()
  }else{
    jointOwners.push(state)
    next()
  }


}
    return (
      <JointOwners
      onChange={onChange}
      state={state}
      addNewOwner={addNewOwner}
      goNext={goNext}
      />
    )
}

export default JointOwnersContainer

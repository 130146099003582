import React,{useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import Company from '../../../assets/dist/img/icons/company2.png'
import Partners from '../../../assets/dist/img/icons/partners2.png'
import Person2 from '../../../assets/dist/img/icons/individual.png'
import * as Icon from "react-feather";
import { Tooltip } from 'antd';
import {KycContext} from '../../../context/KycContext'
import {AlertsContext} from '../../../context/AlertsContext'
import {AuthContext} from '../../../context/AuthContext'
import Alert from '../../alerts/WarningAlert'



export default function SelectProviderType({onChange, goNext}) {

    const {showAlert}= useContext(AlertsContext)
    const {alert}= useContext(AuthContext)
    const {message,variant}= alert

// const [selection, setSelection]= useState('')

  
    return (
        <div>
              <Tooltip title="Learn more about why we need your category">
                       <Link className=" d-block float-right"><small><Icon.HelpCircle/></small></Link>
                       </Tooltip>
           <p>Step 1</p>

            <h4 className="title-background">Which category of Service Provider do you fall under?</h4>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas dolor expedita optio unde explicabo nemo? Eius, non.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas dolor expedita optio unde explicabo nemo? Eius, non.</p>
            
            <div class="frb-group" > 
            {showAlert && <Alert message={message} variant={variant}/>} 
            <div className="row">
         
                <div className="col-md-4">  
                
                    <div className="frb frb-success">
                        <input type="radio" id="radio-button-1" name="radio-button" value='sole' onChange={onChange}/>
                        <label htmlFor="radio-button-1">
                        <img src={Person2} alt="" className="selection-icon" style={{height:'70px', width:'70px'}} />
                        <span className="frb-title">Sole Proprietorship</span>

                        </label>
                    </div>
                </div>
                <div className="col-md-4">        
                    <div className="frb frb-success">
                        <input type="radio" id="radio-button-2" name="radio-button" value='partnership' onChange={onChange}/>
                        <label htmlFor="radio-button-2">
                        <img src={Partners} alt="" className="selection-icon" />
                        <span className="frb-title">Limited Partnership</span>

                        </label>
                    </div>
                </div>
                <div className="col-md-4">        
                    <div className="frb frb-success">
                        <input type="radio" id="radio-button-3" name="radio-button" value='company' onChange={onChange}/>
                        <label htmlFor="radio-button-3">
                            <img src={Company} alt="" className="selection-icon" />
                        <span className="frb-title">Incorporated Company</span>

                        </label>
                    </div>
                </div>
			</div>
           
			</div>
   
        <button className=" float-right btn py-1 mb-35  btn-primary" onClick={goNext}>Next<Icon.ArrowRight size={22} className="pl-1"/></button>
    
            
            
           
        </div>
    )
}

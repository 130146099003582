import React, { useContext, useRef } from "react";
import * as Icon from "react-feather";
import { KycContext } from "../../../context/KycContext";
import { ModalContext } from "../../../context/ModalContext";
import LocationModal from "../../modals/Location";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Search from "../../map/Search";
export default function CompanyInfo({ handleCompanyKycChange, state }) {
  const { next, prev } = useContext(KycContext);



  // MODAL CONTEXT
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Email is required"),
        certificate_number: Yup.string().required(
          "Incorporation Certificate Number is required"
        ),
        kra_pin: Yup.string().required("KRA Number is required"),
        contact: Yup.string().required("Contact is required"),
        address: Yup.string().required("An address is required"),
        postal_code: Yup.string().required("Select a postal code"),
        // location: Yup.string().required("Location is required"),
        description: Yup.string().required(
          "Please write a brief description of yourself"
        ),
      })}
      onSubmit={() => next()}
    >
      {({ errors, touched }) => (
        <Form>
          <LocationModal
            show={modalShow}
            onHide={modalClose}
          />
          <p>Step 2</p>
          <h4 className="title-background">Tell us more about your company</h4>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut
            voluptas dolor expedita optio unde explicabo nemo? Eius, non.Lorem
            ipsum dolor sit, amet consectetur adipisicing elit. Aut voluptas
            dolor expedita optio unde explicabo nemo? Eius, non.
          </p>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Name of Company</label>
                <input
                  className={
                    "form-control" +
                    (errors.name && touched.name ? " is-invalid" : "")
                  }
                  placeholder="Enter your name"
                  type="text"
                  name="name"
                  value={state.name}
                  onChange={handleCompanyKycChange}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="contact">Phone </label>
                <input
                  className={
                    "form-control" +
                    (errors.contact && touched.contact ? " is-invalid" : "")
                  }
                  placeholder="020-123456/ 071234567"
                  type="text"
                  name="contact"
                  value={state.contact}
                  onChange={handleCompanyKycChange}
                />
                <ErrorMessage
                  name="contact"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Certificate of Incorporation</label>
                <input
                  className={
                    "form-control" +
                    (errors.certificate_number && touched.certificate_number
                      ? " is-invalid"
                      : "")
                  }
                  placeholder=""
                  type="text"
                  name="certificate_number"
                  value={state.certificate_number}
                  onChange={handleCompanyKycChange}
                />
                <ErrorMessage
                  name="certificate_number"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Postal Address</label>
                <input
                  className={
                    "form-control" +
                    (errors.address && touched.address ? " is-invalid" : "")
                  }
                  placeholder="1234"
                  type="text"
                  name="address"
                  value={state.address}
                  onChange={handleCompanyKycChange}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">KRA Pin</label>
                <input
                  className={
                    "form-control" +
                    (errors.kra_pin && touched.kra_pin ? " is-invalid" : "")
                  }
                  placeholder="P1234567T"
                  type="text"
                  name="kra_pin"
                  value={state.kra_pin}
                  onChange={handleCompanyKycChange}
                />
                <ErrorMessage
                  name="kra_pin"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                  placeholder="example@email.com"
                  type="text"
                  name="email"
                  value={state.email}
                  onChange={handleCompanyKycChange}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="postal_code">Postal Code</label>
                <select
                  className={
                    "form-control" +
                    (errors.postal_code && touched.postal_code
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Select Code"
                  name="postal_code"
                  value={state.code}
                  onChange={handleCompanyKycChange}
                >
                  <option value="">Select Code</option>
                  <option value="00100">00100</option>
                  <option value="80100">80100</option>
                  <option value="40100">40100</option>
                </select>
                <ErrorMessage
                  name="postal_code"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="location">Enter location</label>
                <Search />
                {/* <input
                  className={
                    "form-control" +
                    (errors.location && touched.location ? " is-invalid" : "")
                  }
                  placeholder="Nairobi CBD, Kenyatta Avenue"
                  type="text"
                  name="location"
                  value={state.location}
                  onChange={handleCompanyKycChange}
                /> */}
                <ErrorMessage
                  name="location"
                  component="div"
                  className="invalid-feedback"
                />
                <small className="float-right text-primary">
                  <a
                    type="button"
                    style={{ textDecoration: "underline" }}
                    href
                    onClick={() => setModalShow(true)}
                  >
                    Select from Map
                  </a>
                </small>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="name">Description</label>
                <textarea
                  className={
                    "form-control" +
                    (errors.description && touched.description
                      ? " is-invalid"
                      : "")
                  }
                  name="description"
                  rows="3"
                  value={state.description}
                  onChange={handleCompanyKycChange}
                ></textarea>
                <ErrorMessage
                  name="description"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
          </div>
          <button
            className=" float-right mb-35 mt-30 py-1 btn btn-primary"
            type="submit"
          >
            Next
            <Icon.ArrowRight size={22} className="pl-1" />
          </button>
          <button
            className=" float-right mb-35 mt-30 py-1 btn btn-secondary mr-3"
            onClick={prev}
          >
            <Icon.ArrowLeft size={22} className="pr-1" />
            Previous
          </button>
        </Form>
      )}
    </Formik>
  );
}

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import { ModalContext } from '../../context/ModalContext';
import AddDepartment from '../../components/modals/AddDepartment';
import { Empty } from 'antd';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

import * as Icon from 'react-feather';

export default function Departments({ departments, refetch }) {
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
	const history = useHistory();
	return (
		<div className="hk-wrapper hk-vertical-nav">
			<div className="hk-pg-wrpper">
				{/* Container */}
				<div className="container-fluid">
					{/* Row */}
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="profile-cover-wrap overlay-wrap">
								<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
								<div className="bg-overlay bg-trans-dark-60" />
								<div className="container profile-cover-content py-30">
									<div className="text-white text-capitalize display-6 mb-5 font-weight-400">
										<Icon.Layers className="mr-2" />
										Departments
									</div>
								</div>
							</div>

							<div className="tab-content mt-sm-60 mt-30">
								<div className="container">
									<div className="fmapp-wrap">
										<div className="fm-box">
											<div className="fmapp-main fmapp-view-switch">
												<header>
													<div className="fm-options-wrap">
														<a href id="fmapp_sidebar_move" className="fmapp-sidebar-move">
															<span className="feather-icon">
																<i data-feather="menu" />
															</span>
														</a>
														<a href>
															<span className="feather-icon">
																<Icon.Filter size={18} />
															</span>
														</a>
													</div>
													<span className> Available Departments</span>
													<div className="fm-options-wrap">
														<button
															onClick={() => setModalShow('department')}
															className="btn btn-primary btn-xs"
														>
															Add Department
														</button>
													</div>
												</header>
												<AddDepartment
													show={modalShow === 'department' ? true : false}
													onHide={modalClose}
													refetch={refetch}
												/>

												<div className="fm-body mt-75">
													<div className="nicescroll-bar">
														<div className="fmapp-view-wrap">
															<div className="fmapp-grid-view">
																<div className="row">
																	<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																		<span className="file-name mt-10"></span>
																	</div>
																</div>
															</div>
															{departments.length === 0 ? (
																<Empty />
															) : (
																<div className="fmapp-table-view">
																	<table
																		id="fmapp_table_view"
																		className="table table-hover w-100"
																	>
																		<thead>
																			<tr>
																				<th>Department code</th>
																				<th>Department Name</th>
																				<th>Date Created </th>
																				<th>Description</th>
																				<th>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{departments.map((department) => (
																				<tr key={department.department_id}>
																					<td>
																						{department.department_code}
																					</td>
																					<td>
																						{department.department_name}
																					</td>
																					<td>
																						<span className="mr-10">
																							<Moment format="DD-MMM-YYYY">
																								{
																									department.created_date
																								}
																							</Moment>
																						</span>
																						<span className="file-time-modified inline-block">
																							<Moment format="HH:MM:a">
																								{
																									department.created_date
																								}
																							</Moment>
																						</span>
																					</td>

																					<td>
																						{
																							department.department_description
																						}
																					</td>
																					<td>
																						<span className="">
																							<Link
																								onClick={() =>
																									history.push(
																										`/dashboard/user-management/departments/view/${department.department_id}`
																									)
																								}
																							>
																								view
																							</Link>
																						</span>
																					</td>
																				</tr>
																			))}
																		</tbody>
																	</table>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* /Row */}
				</div>
				{/* /Container */}
				{/* Footer */}
				{/* <div className="hk-footer-wrap container">
          <footer className="footer">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <p>
                  A product by
                  <a href="/" className="text-dark" target="_blank">
                    Nexus Insurance
                  </a>{" "}
                  © 2020
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <p className="d-inline-block">Follow us</p>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-facebook" />
                  </span>
                </a>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-twitter" />
                  </span>
                </a>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-google-plus" />
                  </span>
                </a>
              </div>
            </div>
          </footer>
        </div> */}
				{/* /Footer */}
			</div>
			{/* /Main Content */}
		</div>
	);
}

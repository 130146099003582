import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import CredentialFormContainer from "../components/forms/credentials/CredentialFormContainer"
import routes from '../routes'

export default function Credentials() {

    const options = {
        items: 1,
        nav: true,
        rewind: true,
        autoplay: true
    };
    return (
        <div className="hk-wrapper">
        <div className="hk-pg-wrapper hk-auth-wrapper">
          <header className="d-flex justify-content-between align-items-center">
            <a className="d-flex text-white auth-brand" href={routes.home}>
              Nexus<span className="text-primary">Insurance</span>
            </a>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6 pa-0">
                <div className="auth-cover-img overlay-wrap" style={{backgroundImage: 'url(dist/img/img1.jpg)'}}>
                  <div className="auth-cover-info py-xl-0 pt-100 pb-50">
                    <div className="auth-cover-content w-xxl-75 w-sm-90 w-100">
                      <h1 className="display-3 text-white mb-20">Be Life Confident</h1>
                      <p className="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit vitae tempore ea tenetur itaque aperiam reiciendis et nihil aut numquam.</p>
                    </div>
                  </div>
                  <div className="bg-overlay bg-trans-dark-50" />
                </div>
              </div>
              <div className="col-xl-6 pa-0">
                <div className="auth-form-wrap py-xl-0 py-50">
                  <div className="auth-form w-xxl-85 w-xl-75 w-sm-90 w-100">
                      <CredentialFormContainer/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    )
}

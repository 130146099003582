import React, {useState} from "react";
import { Modal } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {Button} from 'antd'
import MemoForm from '../forms/memo/MemoFormContainer'


const SendMemo = (props) => {

  return (
  
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="">Send A Memo</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <MemoForm  />
        {/* <div class="form-group">
    <label htmlFor="send">Send Memo to</label>
    <select class="form-control" id="send">
      <option>choose...</option>
      <option>Underwriter</option>
      <option>Client</option>
    </select>
  </div>
        <div className="form-group mt-0 ">
                <label className="">
                 Enter your memo
                </label>
                <textarea
                  name="note"
                  rows="4"
                  type="text"
                  onChange={handleChange}
                  className={
                    "form-control border" +
                    (errors.note && touched.note
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="note"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <Button
              className="btn-primary bg-success float-right  text-white  mt-5 mb-3  px-4 py-1 rounded"
              htmlType="submit"
            >
              Send Message
            </Button> */}
        </Modal.Body>
      </Modal>
    </div>
  
  );
};

export default SendMemo;

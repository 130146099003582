import React, { useEffect } from "react";
import routes from "../../routes";
import { useHistory } from "react-router-dom";

const KycLayout = (props) => {
  const history = useHistory();


  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      // User is not logged in. Redirect back to login
      history.push(routes.login);
      return;
    }
    // Fetch data for logged in user using token
  });
  return (
    
            <div >{props.children}</div>
     
  );
};

export default KycLayout;

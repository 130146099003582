import React, { useEffect, useContext } from "react";
import styles from "./Invoice.module.scss";
import Logo from "../../assets/dist/img/jubilee.png";
import { DashboardContext } from "../../context/DashboardContext";
import LineItems from "./LineItems";

// import uuidv4 from 'uuid/v4'

export default function Invoice({ id, spDetails }) {
  // static contextType = DashboardContext;
  const { workOrderDetails, invoiceSettings, setInvoiceSettings } = useContext(
    DashboardContext
  );

  // Locale
  let locale = "en-US";
  let selectedcCurrency = `${invoiceSettings.currency}`;

  // Get current Date
  let today = new Date();
  let currentDate =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  // Methods
  const handleInvoiceChange = (event) => {
    setInvoiceSettings({
      ...invoiceSettings,
      [event.target.name]: event.target.value,
    });
  };

  const handleLineItemChange = (elementIndex) => (event) => {
    let lineItems = invoiceSettings.lineItems.map((item, i) => {
      if (elementIndex !== i) return item;
      return { ...item, [event.target.name]: event.target.value };
    });
    setInvoiceSettings({ ...invoiceSettings, lineItems: lineItems });
  };

  const handleAddLineItem = (event, index) => {
    setInvoiceSettings({
      ...invoiceSettings,
      // use optimistic uuid for drag drop; in a production app this could be a database id
      lineItems: invoiceSettings.lineItems.concat([
        { id: index, name: "", description: "", quantity: 0, price: 0.0 },
      ]),
    });
  };

  const handleRemoveLineItem = (elementIndex) => (event) => {
    setInvoiceSettings({
      ...invoiceSettings,
      lineItems: invoiceSettings.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };

  const handleReorderLineItems = (newLineItems) => {
    setInvoiceSettings({ ...invoiceSettings, lineItems: newLineItems });
  };

  const handleFocusSelect = (event) => {
    event.target.select();
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: selectedcCurrency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const calcLineItemsTotal = () => {
    return invoiceSettings.lineItems.reduce(
      (prev, cur) => prev + cur.quantity * cur.price,
      0
    );
  };

  const calcTaxTotal = () => {
    return calcLineItemsTotal() * (invoiceSettings.taxRate / 100);
  };

  const calcGrandTotal = () => {
    let grandTotal = calcLineItemsTotal() + calcTaxTotal();

    return grandTotal;
  };

  useEffect(() => {
    const lineTotal = calcLineItemsTotal();
    const taxTotal = calcTaxTotal();
    const total = calcGrandTotal();

    setInvoiceSettings({
      ...invoiceSettings,
      total,
      lineTotal,
      taxTotal,
    });
  }, [invoiceSettings.lineItems, invoiceSettings.taxRate]);

  return (
    <div className={styles.invoice}>
      <div className={styles.brand}>
        {/* <img src={Logo} alt="Logo" className={styles.logo} /> */}
      </div>
      <div className={styles.addresses}>
        <div className={styles.from}>
          <strong>{spDetails.service_provider_name}</strong>
          <br />
          P.O Box {spDetails.address}
          <br />
          {spDetails.physical_address}
          <br />
          {spDetails.emails}
        </div>

        <div>
          <strong className="pl-3 pb-10">Bill to:</strong>
          <div className={`${styles.valueTable} ${styles.to}`}>
            <div className={styles.row}>
              <div className={styles.label}>Customer:</div>
              <div className={styles.value}>
                {workOrderDetails.data.underwritter_name}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Invoice No:</div>
              <div className={styles.value}>
                {invoiceSettings.invoiceNumber}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Date:</div>
              <div className={`${styles.value} ${styles.date}`}>
                {currentDate}
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 className="pb-10 pt-40">{`Invoice under Claim reference ${workOrderDetails.data.claim_ref}(Policy Number #qwsd1234)`}</h6>

      <LineItems
        items={invoiceSettings.lineItems}
        currencyFormatter={formatCurrency}
        addHandler={handleAddLineItem}
        changeHandler={handleLineItemChange}
        focusHandler={handleFocusSelect}
        deleteHandler={handleRemoveLineItem}
        reorderHandler={handleReorderLineItems}
      />

      <div className={styles.totalContainer}>
        <form>
          <div className={styles.valueTable}>
            <div className={styles.row}>
              <div className={styles.label}>Tax Rate (%)</div>
              <div className={styles.value}>
                <input
                  name="taxRate"
                  type="number"
                  step="0.01"
                  value={invoiceSettings.taxRate}
                  onChange={handleInvoiceChange}
                  onFocus={handleFocusSelect}
                />
              </div>
            </div>
          </div>
        </form>
        <form>
          <div className={styles.valueTable}>
            <div className={styles.row}>
              <div className={styles.label}>Subtotal</div>
              <div className={`${styles.value} ${styles.currency}`}>
                {formatCurrency(invoiceSettings.lineTotal)}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>
                Tax ({invoiceSettings.taxRate}%)
              </div>
              <div className={`${styles.value} ${styles.currency}`}>
                {formatCurrency(invoiceSettings.taxTotal)}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Total Due</div>
              <div className={`${styles.value} ${styles.currency}`}>
                {formatCurrency(invoiceSettings.total)}
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* <div className={styles.pay}>
          <button className={styles.payNow} onClick={handlePayButtonClick}>Pay Now</button>
        </div> */}

      <div className={styles.footer}>
        <div className={styles.comments}>
          <h6>Notes</h6>
          {/* <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Notes- any relevant information not already covered" rows="4"></textarea> */}
          {invoiceSettings.notes}
        </div>
        <div className={styles.closing}>
          <div>Thank-you for your business</div>
        </div>
      </div>
    </div>
  );
}

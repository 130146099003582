import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import { ModalContext } from '../../context/ModalContext';
import AddUserGroup from '../../components/modals/AddUserGroup';
import { Empty } from 'antd';
import { useHistory } from 'react-router-dom';
import * as Icon from 'react-feather';

export default function UserGroups({ userGroups, refetch }) {
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
	const history = useHistory();

	return (
		<div className="hk-wrapper hk-vertical-nav">
			<div className="hk-pg-wrpper">
				{/* Container */}
				<div className="container-fluid">
					{/* Row */}
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="profile-cover-wrap overlay-wrap">
								<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
								<div className="bg-overlay bg-trans-dark-60" />
								<div className="container profile-cover-content py-30">
									<div className="text-white text-capitalize display-6 mb-5 font-weight-400">
										<Icon.Layers className="mr-2" />
										Users
									</div>
								</div>
							</div>

							<div className="tab-content mt-sm-60 mt-30">
								<div className="container">
									<div className="fmapp-wrap">
										<div className="fm-box">
											<div className="fmapp-main fmapp-view-switch">
												<header>
													<div className="fm-options-wrap">
														<a href id="fmapp_sidebar_move" className="fmapp-sidebar-move">
															<span className="feather-icon">
																<i data-feather="menu" />
															</span>
														</a>
														<a href>
															<span className="feather-icon">
																<Icon.Filter size={18} />
															</span>
														</a>
													</div>
													<span className> User Groups</span>
													<div className="fm-options-wrap">
														<button
															onClick={() => setModalShow('userGroup')}
															className="btn btn-primary btn-xs"
														>
															Add User Group
														</button>
													</div>
												</header>
												<AddUserGroup
													show={modalShow === 'userGroup' ? true : false}
													onHide={modalClose}
													refetch={refetch}
												/>

												<div className="fm-body mt-75">
													<div className="nicescroll-bar">
														<div className="fmapp-view-wrap">
															<div className="fmapp-grid-view">
																<div className="row">
																	<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																		<span className="file-name mt-10"></span>
																	</div>
																</div>
															</div>
															{userGroups.length === 0 ? (
																<Empty />
															) : (
																<div className="fmapp-table-view">
																	<table
																		id="fmapp_table_view"
																		className="table table-hover w-100"
																	>
																		<thead>
																			<tr>
																				<th>Group Code </th>
																				<th>Group Name</th>
																				<th>Group Description</th>
																				<th>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{userGroups.map((group) => (
																				<tr key={group.group_code}>
																					<td>{group.group_code}</td>
																					<td>{group.group_name}</td>
																					<td>{group.description}</td>

																					<td>
																						<span className="">
																							<Link
																								onClick={() =>
																									history.push(
																										`/dashboard/user-management/user-groups/view/${group.group_id}`
																									)
																								}
																							>
																								view
																							</Link>
																						</span>
																					</td>
																				</tr>
																			))}
																		</tbody>
																	</table>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* /Row */}
				</div>
				{/* /Container */}
			</div>
			{/* /Main Content */}
		</div>
	);
}

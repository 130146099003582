import React from "react";
import Invoice from "./Invoices";
import { api_srv } from "../../services";
import { useQuery } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const InvoicesContainer = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

  const fetch_invoices = async () => {
    let resp = await (await api_srv).listInvoices();
    return resp.sp_invoices;
  };

  const { isLoading, isSuccess, data } = useQuery("invoices", fetch_invoices);

  return (
    <div>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spin className="text-center" indicator={antIcon} />
        </div>
      )}
      {isSuccess && <Invoice data={data} />}
    </div>
  );
};

export default InvoicesContainer;

import React, {useContext } from "react";
import Individual from "./Individual";
import { KycContext } from "../../context/KycContext";

const IndividualUploadsContainer = () => {
  const { individualUploads, setIndividualUploads } = useContext(KycContext);

  const onChangeHandler = (event) => {
    const name = event.target.name;
    setIndividualUploads({ ...individualUploads, [name]: event.target.files[0] });
  };

  return <Individual  onChangeHandler={onChangeHandler} />;
};

export default IndividualUploadsContainer;

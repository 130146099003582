import React, { useState, useContext } from "react";
import { Steps } from "antd";
import Selection from "../components/forms/selectProviderType/SelectProviderTypeContainer";
import IndividualInfo from "../components/forms/company details/IndividualInfoContainer";
import CompanyInfo from "../components/forms/company details/CompanyInfoContainer";
import Confirm from "../components/forms/confirm details/ConfirmKycContainer";
import CompanyUploads from "../components/uploads/CompanyUploadsContainer";
import PartnersUploads from "../components/uploads/PartnersUploadContainer";
import Uploads from "../components/uploads/IndividualUploadsContainer";
import Directors from "../components/forms/directors/DirectorsContainer";
import Partners from "../components/forms/directors/PartnersContainer";
import JointOwners from "../components/forms/directors/JointOwnersContainer";
import { KycContext } from "../context/KycContext";
import bg from "../assets/dist/img/kyc-bg2.jpg";

export default function Kyc() {
  const { current, setCurrent, provider } = useContext(KycContext);

  const { Step } = Steps;

  const onChange = (current) => {
    console.log("onChange:", current);
    setCurrent(current);
  };
  return (
    <div style={{ backgroundImage: `url(${bg})` }}>
      <h3 className="text-center py-25">Service Provider Kyc Details</h3>
      <i class="fa fa-users kyc-background-icon" aria-hidden="true"></i>
      <i class="fa fa-slideshare kyc-background-icon2" aria-hidden="true"></i>
      <div className="container ">
        <div className="card kyc_card">
          <div className="row">
            <div className="col-md-3 left-nav bg-light">
              <div className="card-body pt-md-85">
                <Steps
                  current={current}
                  onChange={onChange}
                  direction="vertical"
                >
                  <Step disabled title="Select a category" />
                  <Step disabled title="Information" />
                  <Step disabled title="Uploads" />
                  <Step
                    disabled
                    title={
                      provider === "sole"
                        ? "Joint Owners"
                        : provider === "partnership"
                        ? "Partners"
                        : provider === "company"
                        ? "Directors"
                        : "Directors/Partners"
                    }
                  />
                  <Step disabled title="Confirm" />
                </Steps>
              </div>
            </div>
            <div className="col-md-9 ">
              <div className="card-body">
                {current === 0 ? (
                  <Selection />
                ) : current === 1 &&
                  (provider === "partnership" || provider === "company") ? (
                  <CompanyInfo />
                ) : current === 1 && provider === "sole" ? (
                  <IndividualInfo />
                ) : current === 2 &&
                  (provider === "partnership") ? (
                  <PartnersUploads />
                ) : current === 2 && provider === "company" ? (
                  <CompanyUploads/>
                ) : current === 2 && provider === "sole" ? (
                  <Uploads />
                ) : current === 3 && provider === "partnership" ? (
                  <Partners />
                ) : current === 3 && provider === "sole" ? (
                  <JointOwners />
                ) : current === 3 && provider === "company" ? (
                  <Directors />
                ) : current === 4 ? (
                  <Confirm />
                ) : (
                  <Selection />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { AlertsContext } from '../../../context/AlertsContext';
import { AuthContext } from '../../../context/AuthContext';
import Alert from '../../alerts/WarningAlert';
import { testing } from '../../../shared/utils/formConfig';
import useForm from '../../../shared/customHooks/useForm';

const SignUpForm = ({ servicesCategories, _signup }) => {
	const { alert } = useContext(AuthContext);
	const { showAlert } = useContext(AlertsContext);
	const { message, variant } = alert;

	const { renderFormInputs, isFormValid, form } = useForm(testing(servicesCategories));

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				_signup.mutate(form);
			}}
		>
			<h1 className="display-4 mb-10">Sign Up for Free</h1>
			<p className="mb-30">Create your account</p>
			{showAlert && <Alert message={message} variant={variant} />}
			{renderFormInputs()}
			<button className="btn btn-primary btn-block" type="submit" disabled={!isFormValid() || _signup.isLoading}>
				{_signup.isLoading ? (
					<span>
						<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
						Please wait...
					</span>
				) : (
					'Submit'
				)}
			</button>
			<div className="option-sep">or</div>
			<p className="text-center">
				Already have an account? <Link to="/login">Sign In</Link>
			</p>
		</form>
	);
};

export default SignUpForm;

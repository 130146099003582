import React, { useContext } from 'react';
import SecurityContext from './SecurityContextForm';
import { create_context } from '../../../services/apiSrv';
import { useMutation } from 'react-query';
import { AuthContext } from '../../../context/AuthContext';
import { AlertsContext } from '../../../context/AlertsContext';

const SecurityContextFormContainer = ({ onClose, refetch }) => {
	const { alert, setAlert } = useContext(AuthContext);
	const { setShowAlert } = useContext(AlertsContext);
	const _create = useMutation(create_context, {
		onSuccess: () => {
			onClose();
			refetch();
		},
		onError: async (error) => {
			let err = await error;
			onClose();
			setAlert({ ...alert, message: err.message, variant: 'danger' });

			setShowAlert(true);
		},
	});
	return (
		<div>
			<SecurityContext _create={_create} />
		</div>
	);
};

export default SecurityContextFormContainer;

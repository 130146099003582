import React from 'react';

const Search = () => {
	return (
		<form role="search" className="chat-search">
			<div className="input-group">
				<div className="input-group-prepend">
					<span className="feather-icon">
						<i data-feather="search" />
					</span>
				</div>
				<input type="text" className="form-control" placeholder="Search" />
			</div>
		</form>
	);
};

export default Search;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Alert from "../../components/alerts/WarningAlert";
import { AuthContext } from "../../context/AuthContext";
import { AlertsContext } from "../../context/AlertsContext";

const RecoverPassword = ({ state, handleChange, send_new_password,togglePassword, toggleConfirmPassword }) => {
  const { showAlert } = useContext(AlertsContext);
  const { alert } = useContext(AuthContext);

  const { message, variant } = alert;

  return (
    <div className="hk-wrapper">
      {/* Main Content */}
      <div className="hk-pg-wrapper hk-auth-wrapper">
        <header className="d-flex justify-content-end align-items-center">
          <div className="btn-group btn-group-sm">
            <a href="#" className="btn btn-outline-secondary">
              Help
            </a>
            <a href="#" className="btn btn-outline-secondary">
              About Us
            </a>
          </div>
        </header>
        <div className="container-fluid">
       
          <div className="row">
            <div className="col-xl-12 pa-0">
              <div className="auth-form-wrap pt-xl-0 pt-70">
                <div className="auth-form w-xl-30 w-sm-50 w-100">
                  <Link className="auth-brand text-center d-block mb-20" to="/">
                    Nexus
                  </Link>
                  <form onSubmit={send_new_password}>
                    <h1 className="display-5 mb-10 text-center">
                      Enter your new Password
                    </h1>
 
                    {showAlert && <Alert message={message} variant={variant} />}
                    <div className="form-group position-relative">
                      <input
                        name="newPass"
                        value={state.newPass}
                        onChange={handleChange}
                        type={state.showPassword ? "text" : "password"}
                        required
                        className="form-control"
                        placeholder="Enter Password"
                       
                      />
                           <i
                          className={`fa ${
                            state.showPassword ? "fa-eye" : "fa-eye-slash"
                          } password-icon`}
                          onClick={togglePassword}
                        />
                    </div>
                    <div className="form-group position-relative">
                      <input
                        name="newPass2"
                        value={state.newPass2}
                        onChange={handleChange}
                        required
                        className="form-control"
                        placeholder="Confirm Password"
                        type={state.showPassword ? "text" : "password"}
                      />
                            <i
                          className={`fa ${
                            state.showConfirmPassword
                              ? "fa-eye"
                              : "fa-eye-slash"
                          } password-icon`}
                          onClick={toggleConfirmPassword}
                        />
                    </div>
                    <button
                      className="btn btn-primary btn-block mb-20"
                      type="submit"
                      disabled={state.loading}
                    >
                      {state.loading && (
                        <i
                          className="fa fa-circle-notch fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {state.loading && (
                        <span className="text-capitalize">Please wait</span>
                      )}
                      {!state.loading && (
                        <span className="text-capitalize"> Change Password</span>
                      )}
                    </button>
                    <p className="text-right">
                      <Link to="/login">Back to login</Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Main Content */}
    </div>
  );
};

export default RecoverPassword;

import React from 'react';
import UserGroup from './UserGroups';
import { list_user_groups } from '../../services/apiSrv';
import { useQuery } from 'react-query';
import Loader from '../../components/loader';

const UserGroupContainer = () => {
	const _listUserGroup = async () => {
		let resp = await list_user_groups();
		console.log('THE USER GROUP', resp);
		return resp.user_groups;
	};

	const { data, isLoading, isSuccess, isError, refetch } = useQuery('listUserGroup', _listUserGroup);

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <UserGroup userGroups={data} refetch={refetch} />}
		</div>
	);
};

export default UserGroupContainer;

import React from 'react';
import Document_pdf from '../../assets/dist/img/pdf.png';
import { Input, Empty } from 'antd';
import { Link } from 'react-router-dom';
import bg from '../../assets/dist/img/tickets-bg.jpg';
import Moment from 'react-moment';

import * as Icon from 'react-feather';

export default function Workorders({ state, view_workorder, create_invoice }) {
	const { Search } = Input;

	return (
		<div className="hk-wrapper hk-vertical-nav">
			<div className="hk-pg-wrpper">
				{/* Container */}
				<div className="container-fluid">
					{/* Row */}
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="profile-cover-wrap overlay-wrap">
								<div className="profile-cover-img" style={{ backgroundImage: `url(${bg})` }} />
								<div className="bg-overlay bg-trans-dark-60" />
								<div className="container profile-cover-content py-30">
									<div className="text-white text-capitalize display-6 mb-5 font-weight-400">
										<Icon.Layers className="mr-2" />
										My Work Orders
									</div>
								</div>
							</div>
							<div className="bg-white shadow-bottom">
								<div className="container">
									<ul className="nav nav-light nav-tabs" role="tablist">
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link active"
												id="nav-pending-tab"
												data-toggle="tab"
												href="#nav-pending"
												role="tab"
												aria-controls="nav-pending"
												aria-selected="true"
											>
												Pending Review{' '}
											</a>
										</li>
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link"
												id="nav-pending-approval-tab"
												data-toggle="tab"
												href="#nav-pending-approval"
												role="tab"
												aria-controls="nav-pending-approval"
												aria-selected="false"
											>
												Pending Approval{' '}
											</a>
										</li>
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link"
												id="nav-ongoing-tab"
												data-toggle="tab"
												href="#nav-ongoing"
												role="tab"
												aria-controls="nav-ongoing"
												aria-selected="false"
											>
												Approved/On-going{' '}
											</a>
										</li>
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link"
												id="nav-completed-tab"
												data-toggle="tab"
												href="#nav-completed"
												role="tab"
												aria-controls="nav-completed"
												aria-selected="false"
											>
												Completed{' '}
											</a>
										</li>
										<li className="nav-item">
											<a
												className="d-flex h-60p align-items-center nav-link"
												id="nav-declined-tab"
												data-toggle="tab"
												href="#nav-declined"
												role="tab"
												aria-controls="nav-declined"
												aria-selected="false"
											>
												Rejected{' '}
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="tab-content mt-sm-60 mt-30">
								<div
									className="tab-pane fade show active"
									role="tabpanel"
									id="nav-pending"
									aria-labelledby="nav-pending-tab"
								>
									<div className="container">
										{
											<div className="fmapp-wrap">
												<div className="fm-box">
													<div className="fmapp-main fmapp-view-switch">
														<header>
															<div className="fm-options-wrap">
																<a
																	href
																	id="fmapp_sidebar_move"
																	className="fmapp-sidebar-move"
																>
																	<span className="feather-icon">
																		<i data-feather="menu" />
																	</span>
																</a>
																<a href>
																	<span className="feather-icon">
																		<Icon.Filter size={18} />
																	</span>
																</a>
															</div>
															<span className> Work Orders Pending Review</span>
															<div className="fm-options-wrap">
																<a
																	href
																	id="fm_view_switcher"
																	className="fm-view-switcher"
																>
																	<Search
																		placeholder="Search a work order"
																		onSearch={(value) => console.log(value)}
																		style={{ maxWidth: 250 }}
																	/>
																	{/* <span className="feather-icon list-view"><Icon.List /></span>
                  <span className="feather-icon grid-view"><Icon.Grid /></span> */}
																</a>
															</div>
														</header>

														<div className="fm-body mt-75">
															<div className="nicescroll-bar">
																<div className="fmapp-view-wrap">
																	<div className="fmapp-grid-view">
																		<div className="row">
																			<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																				<span className="file-name mt-10"></span>
																			</div>
																		</div>
																	</div>
																	{state.filter((workorder) => {
																		return (
																			workorder.work_order_status === 'pending'
																		);
																	}).length === 0 ? (
																		<Empty description="Currently nothing requires your attention here" />
																	) : (
																		<div className="fmapp-table-view">
																			<table
																				id="fmapp_table_view"
																				className="table table-hover w-100"
																			>
																				<thead>
																					<tr>
																						<th>Underwriter</th>
																						<th>Date </th>
																						<th>Description</th>
																						<th>Action</th>
																					</tr>
																				</thead>
																				<tbody>
																					{state
																						.filter((workorder) => {
																							return (
																								workorder.work_order_status ===
																								'pending'
																							);
																						})
																						.map((workorder) => {
																							return (
																								<tr
																									key={
																										workorder.sp_workOrder_id
																									}
																								>
																									<td>
																										{workorder.name}
																									</td>
																									<td>
																										<span className="mr-10">
																											<Moment format="DD-MM-YYYY">
																												{
																													workorder.created_date
																												}
																											</Moment>
																										</span>
																										<span className="file-time-modified inline-block">
																											<Moment
																												format="hh:mm A"
																												date={
																													workorder.created_date
																												}
																											/>
																										</span>
																									</td>

																									<td>
																										{
																											workorder.request_remarks
																										}
																									</td>
																									<td>
																										<span className="">
																											<Link
																												onClick={() =>
																													view_workorder(
																														workorder.sp_workOrder_id
																													)
																												}
																											>
																												view
																											</Link>
																										</span>
																									</td>
																								</tr>
																							);
																						})}
																				</tbody>
																			</table>
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										}
									</div>
								</div>
								<div
									className="tab-pane fade show"
									role="tabpanel"
									id="nav-pending-approval"
									aria-labelledby="nav-pending-approval"
								>
									<div className="container">
										{
											<div className="fmapp-wrap">
												<div className="fm-box">
													<div className="fmapp-main fmapp-view-switch">
														<header>
															<div className="fm-options-wrap">
																<a
																	href
																	id="fmapp_sidebar_move"
																	className="fmapp-sidebar-move"
																>
																	<span className="feather-icon">
																		<i data-feather="menu" />
																	</span>
																</a>
																<a href>
																	<span className="feather-icon">
																		<Icon.Filter size={18} />
																	</span>
																</a>
															</div>
															<span className> Work Orders Pending Approval</span>
															<div className="fm-options-wrap">
																<span className="btn-file text-primary">
																	<input type="file" className="upload" />
																	<span className="feather-icon">
																		<i data-feather="upload" />
																	</span>
																</span>
																<a
																	href
																	id="fm_view_switcher"
																	className="fm-view-switcher"
																>
																	<Search
																		placeholder="Search a work order"
																		onSearch={(value) => console.log(value)}
																		style={{ maxWidth: 250 }}
																	/>
																	{/* <span className="feather-icon list-view"><Icon.List /></span>
                  <span className="feather-icon grid-view"><Icon.Grid /></span> */}
																</a>
															</div>
														</header>

														<div className="fm-body mt-75">
															<div className="nicescroll-bar">
																<div className="fmapp-view-wrap">
																	<div className="fmapp-grid-view">
																		<div className="row">
																			<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																				<span className="file-name mt-10"></span>
																			</div>
																		</div>
																	</div>
																	{state.filter((workorder) => {
																		return (
																			workorder.work_order_status ===
																			'active:pending approval'
																		);
																	}).length === 0 ? (
																		<Empty description="Currently nothing requires your attention here" />
																	) : (
																		<div className="fmapp-table-view">
																			<table
																				id="fmapp_table_view"
																				className="table table-hover w-100"
																			>
																				<thead>
																					<tr>
																						<th>Underwriter</th>
																						<th>Date </th>
																						<th>Description</th>
																						<th>Action</th>
																					</tr>
																				</thead>
																				<tbody>
																					{state
																						.filter((workorder) => {
																							return (
																								workorder.work_order_status ===
																								'active:pending approval'
																							);
																						})
																						.map((workorder) => {
																							return (
																								<tr
																									key={
																										workorder.sp_workOrder_id
																									}
																								>
																									<td>
																										{workorder.name}
																									</td>
																									<td>
																										<span className="mr-10">
																											<Moment format="DD-MM-YYYY">
																												{
																													workorder.created_date
																												}
																											</Moment>
																										</span>
																										<span className="file-time-modified inline-block">
																											<Moment
																												format="hh:mm A"
																												date={
																													workorder.created_date
																												}
																											/>
																										</span>
																									</td>

																									<td>
																										{
																											workorder.request_remarks
																										}
																									</td>
																									<td>
																										<span className="">
																											<Link
																												onClick={() =>
																													view_workorder(
																														workorder.sp_workOrder_id
																													)
																												}
																											>
																												view
																											</Link>
																										</span>
																									</td>
																								</tr>
																							);
																						})}
																				</tbody>
																			</table>
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										}
									</div>
								</div>

								<div
									className="tab-pane fade show"
									role="tabpanel"
									id="nav-ongoing"
									aria-labelledby="nav-ongoing-tab"
								>
									<div className="container">
										<div className="fmapp-wrap">
											<div className="fm-box">
												<div className="fmapp-main fmapp-view-switch">
													<header>
														<div className="fm-options-wrap">
															<a
																href
																id="fmapp_sidebar_move"
																className="fmapp-sidebar-move"
															>
																<span className="feather-icon">
																	<i data-feather="menu" />
																</span>
															</a>
															<a href>
																<span className="feather-icon">
																	<Icon.Filter size={18} />
																</span>
															</a>
														</div>
														<span className>Currently Running Workorders</span>
														<div className="fm-options-wrap">
															<span className="btn-file text-primary">
																<input type="file" className="upload" />
																<span className="feather-icon">
																	<i data-feather="upload" />
																</span>
															</span>
															<a href id="fm_view_switcher" className="fm-view-switcher">
																<Search
																	placeholder="Search a work order"
																	onSearch={(value) => console.log(value)}
																	style={{ maxWidth: 250 }}
																/>
																{/* <span className="feather-icon list-view"><Icon.List /></span>
                 <span className="feather-icon grid-view"><Icon.Grid /></span> */}
															</a>
														</div>
													</header>
													<div className="fm-body mt-75">
														<div className="nicescroll-bar">
															<div className="fmapp-view-wrap">
																<div className="fmapp-grid-view">
																	<div className="row">
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_1.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_2.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_3.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_4.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_5.pdf
																			</span>
																		</div>
																	</div>
																</div>
																{state.filter((workorder) => {
																	return (
																		workorder.work_order_status ===
																		'active:approved'
																	);
																}).length === 0 ? (
																	<Empty description="Currently nothing requires your attention here" />
																) : (
																	<div className="fmapp-table-view">
																		<table
																			id="fmapp_table_view"
																			className="table table-hover w-100"
																		>
																			<thead>
																				<tr>
																					<th>Underwriter</th>
																					<th>Start Date </th>
																					<th>Estimated End Date </th>
																					<th>Description</th>
																					<th>Action</th>
																					<th />
																				</tr>
																			</thead>
																			<tbody>
																				{state
																					.filter((workorder) => {
																						return (
																							workorder.work_order_status ===
																							'active:approved'
																						);
																					})
																					.map((workorder) => {
																						return (
																							<tr
																								key={
																									workorder.sp_workOrder_id
																								}
																							>
																								<td>
																									{workorder.name}
																								</td>
																								<td>
																									<span className="mr-10">
																										<Moment format="DD-MM-YYYY">
																											{workorder.work_order ===
																											null
																												? ''
																												: workorder
																														.work_order
																														.start_date}
																										</Moment>
																									</span>
																									<span className="file-time-modified inline-block">
																										<Moment
																											format="hh:mm A"
																											date={
																												workorder.work_order ===
																												null
																													? ''
																													: workorder
																															.work_order
																															.start_date
																											}
																										/>
																									</span>
																								</td>

																								<td>
																									<Moment format="DD-MM-YYYY">
																										{workorder.work_order ===
																										null
																											? ''
																											: workorder
																													.work_order
																													.estimated_end_date}
																									</Moment>
																								</td>
																								<td>
																									{
																										workorder.request_remarks
																									}
																								</td>
																								<td>
																									<span className="">
																										<Link
																											onClick={() =>
																												view_workorder(
																													workorder.sp_workOrder_id
																												)
																											}
																										>
																											view
																										</Link>
																									</span>
																								</td>
																							</tr>
																						);
																					})}
																			</tbody>
																		</table>
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade show"
									role="tabpanel"
									id="nav-completed"
									aria-labelledby="nav-completed-tab"
								>
									<div className="container">
										<div className="fmapp-wrap">
											<div className="fm-box">
												<div className="fmapp-main fmapp-view-switch">
													<header>
														<div className="fm-options-wrap">
															<a
																href
																id="fmapp_sidebar_move"
																className="fmapp-sidebar-move"
															>
																<span className="feather-icon">
																	<i data-feather="menu" />
																</span>
															</a>
															<a href>
																<span className="feather-icon">
																	<Icon.Filter size={18} />
																</span>
															</a>
														</div>
														<span className>Completed Workorders</span>
														<div className="fm-options-wrap">
															<span className="btn-file text-primary">
																<input type="file" className="upload" />
																<span className="feather-icon">
																	<i data-feather="upload" />
																</span>
															</span>
															<a href id="fm_view_switcher" className="fm-view-switcher">
																<Search
																	placeholder="Search a work order"
																	onSearch={(value) => console.log(value)}
																	style={{ maxWidth: 250 }}
																/>
																{/* <span className="feather-icon list-view"><Icon.List /></span>
                 <span className="feather-icon grid-view"><Icon.Grid /></span> */}
															</a>
														</div>
													</header>
													<div className="fm-body mt-75">
														<div className="nicescroll-bar">
															<div className="fmapp-view-wrap">
																<div className="fmapp-grid-view">
																	<div className="row">
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_1.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_2.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_3.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_4.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_5.pdf
																			</span>
																		</div>
																	</div>
																</div>
																{state.filter((workorder) => {
																	return workorder.work_order_status === 'completed';
																}).length === 0 ? (
																	<Empty description="Currently nothing requires your attention here" />
																) : (
																	<div className="fmapp-table-view">
																		<table
																			id="fmapp_table_view"
																			className="table table-hover w-100"
																		>
																			<thead>
																				<tr>
																					<th>Underwriter</th>
																					<th>Completion Date</th>
																					<th>Remarks</th>
																					<th>Status</th>
																					<th>Action</th>
																				</tr>
																			</thead>
																			<tbody>
																				{state
																					.filter((workorder) => {
																						return (
																							workorder.work_order_status ===
																							'completed'
																						);
																					})
																					.map((workorder) => {
																						return (
																							<tr
																								key={
																									workorder.sp_workOrder_id
																								}
																							>
																								<td>
																									{workorder.name}
																								</td>
																								<td>
																									<span className="mr-10">
																										<Moment format="DD-MM-YYYY">
																											{
																												workorder.created_date
																											}
																										</Moment>
																									</span>
																									<span className="file-time-modified inline-block">
																										<Moment
																											format="hh:mm A"
																											date={
																												workorder.created_date
																											}
																										/>
																									</span>
																								</td>

																								<td>
																									{
																										workorder.request_remarks
																									}
																								</td>
																								<td>Pending Payment</td>
																								<td>
																									<span className="">
																										<Link
																											onClick={() =>
																												create_invoice(
																													workorder.sp_workOrder_id
																												)
																											}
																										>
																											Create
																											invoice
																										</Link>
																									</span>
																								</td>
																							</tr>
																						);
																					})}
																			</tbody>
																		</table>
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade show "
									role="tabpanel"
									id="nav-declined"
									aria-labelledby="nav-declined-tab"
								>
									<div className="container">
										<div className="fmapp-wrap">
											<div className="fm-box">
												<div className="fmapp-main fmapp-view-switch">
													<header>
														<div className="fm-options-wrap">
															<a
																href
																id="fmapp_sidebar_move"
																className="fmapp-sidebar-move"
															>
																<span className="feather-icon">
																	<i data-feather="menu" />
																</span>
															</a>
															<a href>
																<span className="feather-icon">
																	<Icon.Filter size={18} />
																</span>
															</a>
														</div>
														<span className>Rejected Job Tickets</span>
														<div className="fm-options-wrap">
															<span className="btn-file text-primary">
																<input type="file" className="upload" />
																<span className="feather-icon">
																	<i data-feather="upload" />
																</span>
															</span>
															<a href id="fm_view_switcher" className="fm-view-switcher">
																<Search
																	placeholder="Search a work order"
																	onSearch={(value) => console.log(value)}
																	style={{ maxWidth: 250 }}
																/>
																{/* <span className="feather-icon list-view"><Icon.List /></span>
                 <span className="feather-icon grid-view"><Icon.Grid /></span> */}
															</a>
														</div>
													</header>
													<div className="fm-body mt-75">
														<div className="nicescroll-bar">
															<div className="fmapp-view-wrap">
																<div className="fmapp-grid-view">
																	<div className="row">
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_1.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_2.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_3.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_4.pdf
																			</span>
																		</div>
																		<div className="col-lg-1 col-md-2 col-sm-4 col-6 text-center mb-30">
																			<img
																				className="file-preview"
																				src={Document_pdf}
																				alt="fm-img"
																			/>
																			<span className="file-name mt-10">
																				Required Document_5.pdf
																			</span>
																		</div>
																	</div>
																</div>
																{state.filter((workorder) => {
																	return workorder.work_order_status === 'rejected';
																}).length === 0 ? (
																	<Empty description="Currently nothing requires your attention here" />
																) : (
																	<div className="fmapp-table-view">
																		<table
																			id="fmapp_table_view"
																			className="table table-hover w-100"
																		>
																			<thead>
																				<tr>
																					{/* <th>Name</th> */}
																					<th>Underwriter</th>
																					<th>Date </th>
																					<th>Description</th>
																					<th>Action</th>
																				</tr>
																			</thead>
																			<tbody>
																				{state
																					.filter((workorder) => {
																						return (
																							workorder.work_order_status ===
																							'rejected'
																						);
																					})
																					.map((workorder) => {
																						return (
																							<tr
																								key={
																									workorder.sp_workOrder_id
																								}
																							>
																								<td>
																									{workorder.name}
																								</td>
																								<td>
																									<span className="mr-10">
																										<Moment format="DD-MM-YYYY">
																											{
																												workorder.created_date
																											}
																										</Moment>
																									</span>
																									<span className="file-time-modified inline-block">
																										<Moment
																											format="hh:mm A"
																											date={
																												workorder.created_date
																											}
																										/>
																									</span>
																								</td>

																								<td>
																									{
																										workorder.request_remarks
																									}
																								</td>
																								<td>
																									<span className="">
																										<Link
																											onClick={() =>
																												view_workorder(
																													workorder.sp_workOrder_id
																												)
																											}
																										>
																											View
																										</Link>
																									</span>
																								</td>
																							</tr>
																						);
																					})}
																			</tbody>
																		</table>
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* /Row */}
				</div>
				{/* /Container */}
				{/* Footer */}
				{/* <div className="hk-footer-wrap container">
          <footer className="footer">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <p>
                  A product by
                  <a href="/" className="text-dark" target="_blank">
                    Nexus Insurance
                  </a>{" "}
                  © 2020
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <p className="d-inline-block">Follow us</p>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-facebook" />
                  </span>
                </a>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-twitter" />
                  </span>
                </a>
                <a
                  href
                  className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"
                >
                  <span className="btn-icon-wrap">
                    <i className="fa fa-google-plus" />
                  </span>
                </a>
              </div>
            </div>
          </footer>
        </div> */}
				{/* /Footer */}
			</div>
			{/* /Main Content */}
		</div>
	);
}
